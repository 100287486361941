import {
  LOAD_DISCOUNTS_SUCCESS,
  LOAD_DISCOUNT_SUCCESS,
  LOAD_DISCOUNT_LOADING,
  LOAD_DISCOUNT_FAILED,
} from "./discount.action.type";

import axios from "../../plugins/api";

export const getDiscounts = () => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .get(`/discounts`)
      .then(({ data: { data } }) => {
        dispatch(getDataSuccess(data));
      })
      .catch(() => {
        dispatch(getDataFailed());
      });
  };
};

export const getOneDiscount = (id) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .get(`/discounts/${id}`)
      .then(({ data: { data } }) => {
        dispatch(getOneDataSuccess(data));
      })
      .catch(() => {
        dispatch(getDataFailed());
      });
  };
};

const getDataSuccess = (payload) => ({
  type: LOAD_DISCOUNTS_SUCCESS,
  payload,
});

const getOneDataSuccess = (payload) => ({
  type: LOAD_DISCOUNT_SUCCESS,
  payload,
});

const getDataLoading = () => ({
  type: LOAD_DISCOUNT_LOADING,
});

const getDataFailed = () => ({
  type: LOAD_DISCOUNT_FAILED,
});
