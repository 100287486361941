import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Headers from "../components/Headers";
import Footer from "../components/Footer";
import MobileFooter from "../components/MobileFooter";

import AdminSignIn from "./AdminSignIn";
import AdminDashboard from "./AdminDashboard";
import AdminCourse from "./AdminDashboard/Course/Course";
import AdminRegistration from "./AdminDashboard/Registration/Registration";
import AdminCorporateNeeds from "./AdminDashboard/CorporateNeed/CorporateNeed";

import Home from "./Home";
import SignIn from "./SignIn";
import Bootcamp from "./Bootcamp";
import CorporateNeeds from "./CoorporateNeeds";
import Registration from "./Registration";
import AdmissionInfo from "./AdmissionInfo";
import VerifyCertificate from "./VerifyCertificate";
import Search from "./Search";
import NotFoundPage from "./404";
import IncomeShareAgreement from "./IncomeShareAgreement";
import FinancialPartner from "./FinancialPartner";
import SuccessPayment from "./SuccessPayment";

const Components = () => {
  const { pathname } = useLocation();

  const adminDasboardPathname = pathname.split("/")[1];

  const isSignUp = pathname === "/signup";
  const isSignIn = pathname === "/signin";
  const isAdminDashboard = adminDasboardPathname === "admin-dashboard";

  return (
    <>
      {!isSignUp && !isSignIn && !isAdminDashboard && <Headers />}
      <Routes>
        <Route path="/admin-dashboard/signin" element={<AdminSignIn />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/admin-dashboard/courses" element={<AdminCourse />} />
        <Route
          path="/admin-dashboard/registrations"
          element={<AdminRegistration />}
        />
        <Route
          path="/admin-dashboard/corporate-needs"
          element={<AdminCorporateNeeds />}
        />
        <Route path="/" element={<Home />} />
        <Route path="/corporate-needs" element={<CorporateNeeds />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/bootcamps/:slug" element={<Bootcamp />} />
        <Route path="/admission-info" element={<AdmissionInfo />} />
        <Route path="/verify-certificate" element={<VerifyCertificate />} />
        <Route path="/financial-partner" element={<FinancialPartner />} />
        <Route
          path="/income-share-agreement"
          element={<IncomeShareAgreement />}
        />
        <Route path="/success-payment" element={<SuccessPayment />} />
        <Route path="/search" element={<Search />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      {!isSignUp && !isSignIn && !isAdminDashboard && <Footer />}
      {!isSignUp && !isSignIn && !isAdminDashboard && <MobileFooter />}
    </>
  );
};

export default Components;
