import {
  LOAD_DISCOUNTS_SUCCESS,
  LOAD_DISCOUNT_SUCCESS,
  LOAD_DISCOUNT_LOADING,
  LOAD_DISCOUNT_FAILED,
} from "./discount.action.type";

const initialState = {
  discounts: [],
  discount: {},
  loading: false,
  error: {
    status: false,
    message: "Oops! Something Went Wrong.",
  },
};

function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case LOAD_DISCOUNTS_SUCCESS:
      return {
        ...state,
        discounts: action.payload,
        loading: false,
        error: {
          status: false,
        },
      };
    case LOAD_DISCOUNT_SUCCESS:
      return {
        ...state,
        discount: action.payload,
        loading: false,
        error: {
          status: false,
        },
      };
    case LOAD_DISCOUNT_LOADING:
      return {
        ...state,
        loading: true,
        error: {
          status: false,
        },
      };
    case LOAD_DISCOUNT_FAILED:
      return {
        ...state,
        error: {
          status: true,
          message: state.error.message,
        },
        loading: false,
      };
    default:
      return state;
  }
}

export default reducer;
