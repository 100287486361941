import {
  LOAD_CORPORATE_NEEDS_SUCCESS,
  LOAD_CORPORATE_NEED_SUCCESS,
  LOAD_CORPORATE_NEED_LOADING,
  LOAD_CORPORATE_NEED_FAILED,
} from "./corporate.need.action.type";

import axios from "../../plugins/api";

export const getCorporateNeeds = (query) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .get(`/corporate-needs?${query}`)
      .then(({ data: { data } }) => {
        dispatch(getDataSuccess(data));
      })
      .catch(() => {
        dispatch(getDataFailed());
      });
  };
};

export const getOneCorporateNeed = (id) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .get(`/corporate-needs/${id}`)
      .then(({ data: { data } }) => {
        dispatch(getOneDataSuccess(data));
      })
      .catch(() => {
        dispatch(getDataFailed());
      });
  };
};

const getDataSuccess = (payload) => ({
  type: LOAD_CORPORATE_NEEDS_SUCCESS,
  payload,
});

const getOneDataSuccess = (payload) => ({
  type: LOAD_CORPORATE_NEED_SUCCESS,
  payload,
});

const getDataLoading = () => ({
  type: LOAD_CORPORATE_NEED_LOADING,
});

const getDataFailed = () => ({
  type: LOAD_CORPORATE_NEED_FAILED,
});
