import React from "react";

const Alert = ({ text, type, onClose }) => {
  const types = {
    success: "green",
    fail: "red",
    warning: "yellow",
  };

  return (
    <div
      className={`bg-${types[type]}-100 border border-${types[type]}-400 text-${types[type]}-700 px-4 py-2 rounded relative mb-3`}
      role="alert"
    >
      <strong className="font-bold">{text}</strong>
      <span
        className="absolute top-0 bottom-0 right-0 px-4 py-3"
        onClick={() => onClose(false)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 cursor-pointer"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    </div>
  );
};

export default Alert;
