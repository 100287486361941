import React from "react";
import classnames from "classnames";

import LeftButtonIcon from "../../components/LeftButtonIcon";

import icon from "../../constants/icon";

const icons = {
  1: {
    icon: <img src={icon.profession1} height={32} />,
  },
  2: {
    icon: <img src={icon.profession2} height={32} />,
  },
  3: {
    icon: <img src={icon.profession3} height={32} />,
  },
  4: {
    icon: <img src={icon.profession4} height={32} />,
  },
  5: {
    icon: <img src={icon.profession5} height={32} />,
  },
};

const FifthComponent = ({ course }) => {
  const course_details = course?.course_details;

  const [advantages, setAdvantages] = React.useState([]);

  React.useEffect(() => {
    setAdvantages(course_details?.filter((el) => el.type === "ADVANTAGE"));
  }, [course_details]);

  return (
    <div className="flex flex-col bg-white items-center gap-y-10 pb-60px pt-60px">
      <div className="w-5/6 sm:w-4/5 2xl:w-3/5 flex flex-col sm:flex-row justify-between items-center gap-y-5">
        <p className="font-black text-custom-blue text-3xl sm:w-2/6">
          Profesi yang bisa kamu dapatkan setelah lulus dari bootcamp
        </p>
        <div className="hidden sm:flex flex-wrap justify-center w-4/6">
          {advantages?.map((el, i) => (
            <div
              key={i}
              className={classnames(
                i === 3 || i === 4 ? "w-1/2" : "w-1/3",
                "p-1"
              )}
            >
              <div className="bg-custom-blue w-full flex items-center gap-x-2 justify-center p-3 rounded-full">
                <div>{icons[Number(el.description)].icon}</div>
                <p className="text-white font-bold">{el.title}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="sm:hidden flex flex-col gap-y-3 w-full">
          {advantages?.map((el, i) => (
            <div key={i}>
              <LeftButtonIcon
                title={el.title}
                icon={icons[Number(el.description)].icon}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FifthComponent;
