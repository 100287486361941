import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { signOut } from "../store/user/user.action";

const Sidebar = () => {
  const dispatch = useDispatch();

  const onSignOut = () => {
    dispatch(signOut());
  };

  return (
    <>
      <div className="sidebar bg-custom-blue text-blue-100 w-64 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out">
        <NavLink to="/" className="text-white flex items-center space-x-2 px-4">
          <svg
            className="w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
            />
          </svg>
          <span className="text-2xl font-extrabold">Jakartalabs</span>
        </NavLink>

        <nav>
          <NavLink
            to="/admin-dashboard"
            className="block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white"
          >
            Home
          </NavLink>
          <NavLink
            to="/admin-dashboard/courses"
            className="block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white"
          >
            Course
          </NavLink>
          <NavLink
            to="/admin-dashboard/registrations"
            className="block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white"
          >
            Registration
          </NavLink>
          <NavLink
            to="/admin-dashboard/corporate-needs"
            className="block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white"
          >
            Corporate Needs
          </NavLink>
          <div
            onClick={onSignOut}
            className="text-red-400 cursor-pointer block py-2.5 px-4 rounded transition duration-200 hover:bg-red-500 hover:text-white"
          >
            Sign Out
          </div>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
