import React from "react";
import { ClockIcon, CalendarIcon } from "@heroicons/react/outline";
import { NavLink } from "react-router-dom";
import { s3_url } from "../plugins/storage-public-url";

import imgDefaultInstructor from "../assets/review/review-default.jpeg";
import format from "../helpers/format.currency";

const EventCard = ({ value }) => {
  const course = value?.course;
  const discount = course?.discount?.percentage / 100 || 0 / 100;
  const discountAmount = course?.price * discount;
  const discountPrice = Number(course?.price) - discountAmount;

  return (
    <NavLink to={`/registration?id=${value?.course?.id}`}>
      <div>
        <div
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(60, 60, 60, 0.4), rgba(60, 60, 60, 0.4)), url(${
              s3_url + value?.course?.thumbnail
            })`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundColor: "grey",
            backgroundPosition: "center",
            height: 200,
          }}
          className="flex items-end rounded-xl shadow-md"
        />
        <div className="shadow-md border rounded-xl mt-1">
          <p style={{ fontSize: 13 }} className="pl-5 pt-5 text-custom-blue">
            {`${value?.mode} ${value?.course?.type}`}
          </p>
          <div className="px-5 py-2">
            <p style={{ fontSize: 16 }} className="font-bold">
              {`${value?.course?.title} [BATCH ${value?.batch}]`}
            </p>
            <div className="flex py-2 items-center justify-center gap-x-2">
              <div className="w-1/2 flex justify-end items-center gap-x-1">
                <CalendarIcon width={16} />
                <p style={{ fontSize: 13 }} className="leading-none">
                  {`${value.days}`}
                </p>
              </div>
              <hr
                className="border border-custom-blue font-bold leading-none"
                style={{ height: 2 }}
              />
              <div className="w-1/2 flex justify-start items-center gap-x-1">
                <ClockIcon width={16} />
                <p style={{ fontSize: 13 }} className="leading-none">
                  {`${value.start_time} - ${value.end_time}`}
                </p>
              </div>
            </div>
            <div className="py-2 flex items-center gap-x-3">
              <div
                style={{
                  backgroundImage: `linear-gradient(0deg, rgba(60, 60, 60, 0.4), rgba(60, 60, 60, 0.4)), url(${imgDefaultInstructor})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: 46,
                  width: 46,
                }}
                className="flex w-1/6 items-end justify-start rounded-full shadow-md border"
              />
              <div className="flex flex-col gap-y-1">
                <p
                  style={{ fontSize: 14 }}
                  className="leading-none font-bold text-custom-blue"
                >
                  {value?.instructor?.name}
                </p>
                <p
                  style={{ fontSize: 12 }}
                  className="leading-none text-gray-600"
                >
                  {value?.instructor?.current_job}
                </p>
              </div>
            </div>
          </div>
          <div className="px-5 py-4 border-t flex justify-center items-center gap-x-3">
            {discountPrice === 0 ? (
              <p
                style={{ fontSize: 14 }}
                className="font-bold text-custom-blue"
              >
                FREE
              </p>
            ) : (
              <p
                style={{ fontSize: 14 }}
                className="font-bold text-custom-blue"
              >
                {`Rp ${format(discountPrice)}`}
              </p>
            )}
            {course?.discount && (
              <p style={{ fontSize: 14 }} className="text-gray-400">
                <s>{`Rp ${format(course?.price)}`}</s>
              </p>
            )}
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default EventCard;
