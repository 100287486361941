import React from "react";

import Program from "../../components/Program";
import HiringPartner from "../../components/HiringPartner";
import Event from "../../components/Event";
import Review from "../../components/Review";
import SubscribeNewsletter from "../../components/SubscribeNewsletter";

import FirstComponent from "./FirstComponent";
import FirstComponentMobile from "./FirstComponentMobile";
import SecondComponent from "./SecondComponent";

const Home = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <FirstComponent />
      <FirstComponentMobile />
      <SecondComponent />
      <Program />
      <HiringPartner />
      <Review />
      <Event />
      <SubscribeNewsletter />
    </div>
  );
};

export default Home;
