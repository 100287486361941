import React from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { TrashIcon } from "@heroicons/react/outline";

import { s3_url } from "../../../plugins/storage-public-url";
import axios from "../../../plugins/api";

import format from "../../../helpers/format.currency";

import { getCourses } from "../../../store/courses/course.action";
import { getDiscounts } from "../../../store/discount/discount.action";

const types = [
  {
    label: "Bootcamp",
    value: "BOOTCAMP",
  },
  {
    label: "Event",
    value: "EVENT",
  },
  {
    label: "Workshop",
    value: "WORKSHOP",
  },
];

const CourseForm = ({ setOpen, course, setCourse }) => {
  const dispatch = useDispatch();
  const thumbnailRef = React.useRef();
  const headerImageRef = React.useRef();
  const documentRef = React.useRef();

  const user = useSelector((state) => state.user);
  const { discounts } = useSelector((state) => state.discount);

  const [categories, setCategories] = React.useState([]);

  const [code, setCode] = React.useState("");
  const [slug, setSlug] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [type, setType] = React.useState(types[0].value);
  const [category, setCategory] = React.useState("");
  const [shortDescription, setShortDescription] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [headerImage, setHeaderImage] = React.useState(null);
  const [thumbnail, setThumbnail] = React.useState(null);
  const [introductionVideo, setIntroductionVideo] = React.useState(null);
  const [price, setPrice] = React.useState("");
  const [discount, setDiscount] = React.useState("");
  const [document, setDocument] = React.useState(null);

  const [loading, setLoading] = React.useState(false);

  const onFileChange = (setValue, values) => {
    setValue(values[0]);
  };
  const onRemoveFile = (setValue, ref) => {
    setValue(null);
    ref.current.value = null;
  };

  const onSave = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    formData.append("title", title);
    formData.append("code", code);
    formData.append("slug", slug);
    formData.append("type", type);
    formData.append("category_id", category);
    formData.append("description", description);
    formData.append("short_description", shortDescription);
    if (headerImage) {
      formData.append("header_image", headerImage);
    }
    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }
    if (introductionVideo) {
      formData.append("introduction_video", introductionVideo);
    }
    formData.append("price", Number(price));
    if (discount) {
      formData.append("discount_id", discount);
    }
    if (document) {
      formData.append("document", document);
    }

    if (course?.id) {
      axios
        .put(`/courses/${course.id}/update`, formData, {
          headers: {
            authorization: user.user_token,
          },
        })
        .then(() => {
          dispatch(getCourses("limit=10&page=1"));
          setOpen(false);
          setCourse(null);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      axios
        .post("/courses/create", formData, {
          headers: {
            authorization: user.user_token,
          },
        })
        .then(() => {
          dispatch(getCourses("limit=10&page=1"));
          setOpen(false);
          setCourse(null);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const getCategories = async (course) => {
    const {
      data: { data },
    } = await axios.get("/categories");

    setCategories(data);
    if (!course) {
      setCategory(data[0]?.id);
    }
  };

  const onEdit = async (course) => {
    setCode(course.code);
    setSlug(course.slug);
    setIntroductionVideo(course.introduction_video);
    setTitle(course.title);
    setType(course.type);
    setCategory(course.category_id);
    setShortDescription(course.short_description);
    setDescription(course.description);
    setPrice(course.price);
    setDiscount(course.discount_id);
  };

  const onReset = () => {
    setTitle("");
    setType(types[0].value);
    setCategory("");
    setDescription("");
    setPrice("");
    setDiscount("");
  };

  React.useEffect(() => {
    getCategories(course);
    dispatch(getDiscounts());
    if (course) {
      onEdit(course);
    } else {
      onReset();
    }
  }, [course, dispatch]);

  const discountPrice =
    Number(price) *
    (discounts.find((el) => el?.id === discount)?.percentage / 100);
  const priceAfterDiscount = Number(price) - discountPrice;

  return (
    <div>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <form>
          <div className="sm:rounded-md sm:overflow-hidden">
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div>
                <label
                  htmlFor="code"
                  className="block text-sm font-medium text-gray-700"
                >
                  Kode
                </label>
                <div className="mt-1">
                  <input
                    name="code"
                    onChange={(e) => setCode(e.target.value)}
                    value={code}
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="slug"
                  className="block text-sm font-medium text-gray-700"
                >
                  Slug
                </label>
                <div className="mt-1">
                  <input
                    name="slug"
                    onChange={(e) => setSlug(e.target.value)}
                    value={slug}
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Title
                </label>
                <div className="mt-1">
                  <input
                    name="title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-700"
                >
                  Type
                </label>
                <select
                  id="type"
                  name="type"
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                  className="mt-1 block w-full p-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-custom-blue focus:border-custom-blue sm:text-sm"
                >
                  {types?.map((el, i) => (
                    <option key={i} value={el?.value}>
                      {el?.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-700"
                >
                  Category
                </label>
                <select
                  id="category"
                  name="category"
                  className="mt-1 block w-full p-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-custom-blue focus:border-custom-blue sm:text-sm"
                  onChange={(e) => setCategory(e.target.value)}
                  value={category}
                >
                  {categories?.map((el, i) => (
                    <option key={i} value={el?.id}>
                      {el?.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label
                  htmlFor="shortDescription"
                  className="block text-sm font-medium text-gray-700"
                >
                  {`Short Description (${130 - shortDescription.length})`}
                </label>
                <div className="mt-1">
                  <textarea
                    name="shortDescription"
                    onChange={(e) => setShortDescription(e.target.value)}
                    value={shortDescription}
                    rows={2}
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Description
                </label>
                <div className="mt-1">
                  <textarea
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    rows={3}
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Header Image
                </label>
                {course?.header_image && (
                  <img src={s3_url + course?.header_image} alt="" />
                )}
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <div className="flex text-sm text-gray-600 items-center gap-x-2">
                      <label
                        htmlFor="headerImage"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-custom-blue focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-custom-blue"
                      >
                        <span>Upload a file (res: 1920 x 600 px)</span>
                        <input
                          ref={headerImageRef}
                          id="headerImage"
                          name="headerImage"
                          type="file"
                          className="sr-only"
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) =>
                            onFileChange(setHeaderImage, e.target.files)
                          }
                        />
                      </label>
                      <p className="text-xs text-gray-500">
                        PNG, JPG, JPEG up to 10MB
                      </p>
                    </div>
                    {headerImage?.name && (
                      <div className="flex align-items justify-center gap-x-1">
                        <p className="text-xs text-gray-500">
                          {headerImage?.name}
                        </p>
                        <TrashIcon
                          onClick={() =>
                            onRemoveFile(setHeaderImage, headerImageRef)
                          }
                          width={16}
                          className="text-red-500 cursor-pointer"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Thumbnail
                </label>
                {course?.thumbnail && (
                  <img src={s3_url + course?.thumbnail} alt="" />
                )}
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <div className="flex text-sm text-gray-600 items-center gap-x-2">
                      <label
                        htmlFor="thumbnail"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-custom-blue focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-custom-blue"
                      >
                        <span>Upload a file (res: 1920 x 1080 px)</span>
                        <input
                          ref={thumbnailRef}
                          id="thumbnail"
                          name="thumbnail"
                          type="file"
                          className="sr-only"
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) =>
                            onFileChange(setThumbnail, e.target.files)
                          }
                        />
                      </label>
                      <p className="text-xs text-gray-500">
                        PNG, JPG, JPEG up to 10MB
                      </p>
                    </div>
                    {thumbnail?.name && (
                      <div className="flex align-items justify-center gap-x-1">
                        <p className="text-xs text-gray-500">
                          {thumbnail?.name}
                        </p>
                        <TrashIcon
                          onClick={() =>
                            onRemoveFile(setThumbnail, thumbnailRef)
                          }
                          width={16}
                          className="text-red-500 cursor-pointer"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <label
                  htmlFor="introductionVideo"
                  className="block text-sm font-medium text-gray-700"
                >
                  Introduction Video
                </label>
                {course?.introduction_video && (
                  <ReactPlayer
                    url={course?.introduction_video}
                    controls
                    width="100%"
                  />
                )}

                <div>
                  <div className="mt-1">
                    <input
                      name="introductionVideo"
                      placeholder="Place youtube link here"
                      onChange={(e) => setIntroductionVideo(e.target.value)}
                      value={introductionVideo}
                      className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Document
                </label>
                {course?.document && (
                  <div className="my-3">
                    <a
                      href={s3_url + course?.document}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="border rounded-md p-2 text-sm text-gray-500"
                    >
                      View document
                    </a>
                  </div>
                )}
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <div className="flex text-sm text-gray-600 items-center gap-x-2">
                      <label
                        htmlFor="document"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-custom-blue focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-custom-blue"
                      >
                        <span>Upload a file</span>
                        <input
                          ref={documentRef}
                          id="document"
                          name="document"
                          type="file"
                          className="sr-only"
                          accept=".pdf"
                          onChange={(e) =>
                            onFileChange(setDocument, e.target.files)
                          }
                        />
                      </label>
                      <p className="text-xs text-gray-500">
                        PDF File Type up to 50MB
                      </p>
                    </div>
                    {document?.name && (
                      <div className="flex align-items justify-center gap-x-1">
                        <p className="text-xs text-gray-500">
                          {document?.name}
                        </p>
                        <TrashIcon
                          onClick={() => onRemoveFile(setDocument, documentRef)}
                          width={16}
                          className="text-red-500 cursor-pointer"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <label
                  htmlFor="price"
                  className="block text-sm font-medium text-gray-700"
                >
                  Price
                </label>
                <div className="mt-1">
                  <input
                    name="price"
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                    onChange={(e) => setPrice(e.target.value)}
                    value={price}
                  />
                </div>
                <div>
                  {discount
                    ? `Price after discount: Rp ${format(priceAfterDiscount)}`
                    : `Price: Rp ${format(price)}`}
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="discount"
                  className="block text-sm font-medium text-gray-700"
                >
                  Discount
                </label>
                <select
                  id="discount"
                  name="discount"
                  className="mt-1 block w-full p-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-custom-blue focus:border-custom-blue sm:text-sm"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                >
                  <option value="">No Discount</option>
                  {discounts?.map((el, i) => (
                    <option key={i} value={el?.id}>
                      {`${el?.percentage}%`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="px-4 py-3 text-right sm:px-6">
              <button
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-blue"
                onClick={onSave}
                disabled={loading}
              >
                {loading ? "Please wait..." : "Save"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CourseForm;
