import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import React from "react";
import { NavLink } from "react-router-dom";

import jakartalabs from "../assets/logo/jakartalabs.png";

const Footer = () => {
  return (
    <>
      <div
        style={{ paddingTop: 60, paddingBottom: 60 }}
        className="hidden sm:flex flex-col items-center justify-center gap-y-10 bg-white"
      >
        <div className="w-4/6 flex-col sm:flex-row sm:w-4/5 2xl:w-3/5 flex gap-x-10">
          <div className="flex flex-col gap-y-10 sm:w-2/5">
            <NavLink to="/">
              <img className="h-4 w-auto sm:h-5" src={jakartalabs} alt="" />
            </NavLink>
            <div>
              <p className="font-bold pb-2">Learners Today, Leaders Tomorrow</p>
              <p className="text-gray-500 sm:w-360px text-14px">
                Your home for all-in-one digital & high-tech education and
                solution in Indonesia.
              </p>
            </div>
            <div className="flex flex-col gap-y-5">
              <div className="flex gap-x-2 items-center">
                <MailIcon className="w-30px text-custom-blue" />
                <p className="leading-0 text-sm">admin@jakartalabs.id</p>
              </div>
              <div className="flex gap-x-2 items-center">
                <PhoneIcon className="w-30px text-custom-blue" />
                <p className="leading-0 text-sm">+62 889 0889 2612</p>
              </div>
            </div>
          </div>

          <div className="flex sm:w-3/5 flex-col sm:flex-row justify-between gap-y-5">
            <div className="flex flex-col gap-y-5">
              <p className="font-bold text-custom-blue">Sitemap</p>
              <div className="flex flex-col gap-y-3 text-sm">
                <NavLink to="/">Programs</NavLink>
                <NavLink to="/">Blogs</NavLink>
                <NavLink to="/">About Us</NavLink>
                <NavLink to="/">Verify Certificate</NavLink>
              </div>
            </div>

            <div className="flex flex-col gap-y-5">
              <p className="font-bold text-custom-blue">Our Campus</p>
              <div className="flex flex-col gap-y-3 text-sm">
                <NavLink to="/">Centennial Tower</NavLink>
                <NavLink to="/">Jl. Jenderal Gatot Subroto</NavLink>
                <NavLink to="/">Kel. Karet Semanggi</NavLink>
                <NavLink to="/">Kec. Setiabudi, Kota Jakarta Selatan</NavLink>
                <NavLink to="/">DKI Jakarta 12930</NavLink>
              </div>
            </div>

            <div className="flex flex-col gap-y-5">
              <p className="font-bold text-custom-blue">Operational Hours</p>
              <div className="flex flex-col gap-y-3 text-sm">
                <p>9 AM - 6 PM</p>
              </div>
            </div>

            <div className="flex flex-col gap-y-5">
              <p className="font-bold text-custom-blue">Follow Us</p>
              <div className="flex flex-col gap-y-3 text-sm">
                <a
                  href="https://www.facebook.com/jakartalabs.id"
                  rel="noopener noreferre"
                  target="_blank"
                >
                  Facebook
                </a>
                <a
                  href="https://www.instagram.com/jakartalabs"
                  rel="noopener noreferre"
                  target="_blank"
                >
                  Instagram
                </a>
                <a
                  href="https://www.youtube.com/c/jakartalabs"
                  rel="noopener noreferre"
                  target="_blank"
                >
                  Youtube
                </a>
                <a
                  href="https://jakartalabs.medium.com"
                  rel="noopener noreferre"
                  target="_blank"
                >
                  Medium
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <p className="leading-0 text-gray-600 text-sm">
            {`PT. BELAJAR BERKARYA BERBAGI © ${new Date().getFullYear()} All Rights Reserved`}
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
