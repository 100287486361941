import {
  LOAD_REGISTRATIONS_SUCCESS,
  LOAD_REGISTRATION_SUCCESS,
  LOAD_REGISTRATION_LOADING,
  LOAD_REGISTRATION_FAILED,
} from "./registration.action.type";

import axios from "../../plugins/api";

export const getRegistrations = (query) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .get(`/registration?${query}`)
      .then(({ data: { data } }) => {
        console.log(data);
        dispatch(getDataSuccess(data));
      })
      .catch(() => {
        dispatch(getDataFailed());
      });
  };
};

export const getOneRegistration = (id) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .get(`/registration/${id}`)
      .then(({ data: { data } }) => {
        dispatch(getOneDataSuccess(data));
      })
      .catch(() => {
        dispatch(getDataFailed());
      });
  };
};

const getDataSuccess = (payload) => ({
  type: LOAD_REGISTRATIONS_SUCCESS,
  payload,
});

const getOneDataSuccess = (payload) => ({
  type: LOAD_REGISTRATION_SUCCESS,
  payload,
});

const getDataLoading = () => ({
  type: LOAD_REGISTRATION_LOADING,
});

const getDataFailed = () => ({
  type: LOAD_REGISTRATION_FAILED,
});
