import React from "react";

import image from "../../constants/image";

const FirstComponentMobile = () => {
  return (
    <div className="sm:hidden">
      <div className="flex items-center justify-center gap-x-20 mx-auto h-540px w-4/6">
        <div className="-mt-60px -mb-60px flex flex-row justify-center items-center gap-x-16">
          <div className="relative w-full">
            <div
              style={{
                backgroundImage: `url(${image.admissionInfo})`,
              }}
              className="absolute flex bg-cover bg-no-repeat bg-color-gray-500 h-420px w-220px shadow-md rounded-2xl"
            />
            <div className="-mt-30px ml-30px h-420px w-220px border-2 border-custom-blue rounded-2xl" />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center mx-auto w-4/5 md:w-3/5 pb-10">
        <div className="flex flex-col gap-y-5">
          <h1 className="font-bold w-full text-5xl text-custom-blue">
            Admissions
          </h1>
          <p className="w-full text-lg">
            Job Connector Full Stack Development adalah program pendidikan
            coding insentif untuk menjadi seorang Web and Mobile Developer
            dengan jaringan pekerjaan di perusahaan hiring partner Jakartalabs
            ketika lulus.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FirstComponentMobile;
