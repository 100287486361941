import React from "react";

import whatsapp from "../assets/icons/whatsapp.png";

const FloatingWhatsappButton = () => {
  const waLink = "https://wa.me/6288908892612?text=I%20want%20to%20talk%20to%20advisor%20of%20Jakartalabs"; // prettier-ignore

  return (
    <div className="bg-white rounded-full cursor-pointer z-50 fixed w-42px h-42px bottom-10px right-10px sm:w-56px sm:h-56px sm:bottom-20px sm:right-20px">
      <a href={waLink} rel="noopener noreferrer" target="_blank">
        <img src={whatsapp} width="100%" />
      </a>
    </div>
  );
};

export default FloatingWhatsappButton;
