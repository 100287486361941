import React from "react";
import moment from "moment";

import format from "../../../helpers/format.currency";

export default function CourseTable({ registrations, type, setType, types }) {
  const data = registrations?.rows;

  return (
    <div className="flex flex-col">
      <div className="mb-5 flex justify-end">
        <div className="w-40">
          <label
            htmlFor="type"
            className="block text-sm font-medium text-gray-700"
          >
            Type
          </label>
          <select
            id="type"
            name="type"
            onChange={(e) => setType(e.target.value)}
            value={type}
            className="mt-1 block w-full p-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-custom-blue focus:border-custom-blue sm:text-sm"
          >
            {types?.map((el, i) => (
              <option key={i} value={el?.value}>
                {el?.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="-my-2 overflow-x-auto">
        <div className="py-2 align-middle inline-blockw-full">
          <div className="shadow overflow-hidden border border-gray-200 sm:rounded-lg">
            <table className="divide-y divide-gray-200 w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                  >
                    No
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Transaction Id
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Course Schedule
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Email & Phone
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Created & Price
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Motivation
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data?.map((registration, i) => (
                  <tr key={registration.id}>
                    <td className="px-6 py-4 text-sm text-gray-800">{i + 1}</td>
                    <td className="px-6 py-4 text-sm text-gray-800">
                      {registration?.transaction_id}
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-900">
                        {registration?.course_schedule?.course?.title}
                      </div>
                      <div className="text-sm text-gray-900">
                        Start date:{" "}
                        {moment(
                          registration?.course_schedule?.start_date
                        ).format("DD MMMM YYYY")}
                      </div>
                      <div className="text-sm text-gray-500">
                        {`Batch: ${registration?.course_schedule?.batch}`}
                      </div>
                      <div className="text-sm text-gray-500">
                        {`${registration?.course_schedule?.start_time} - ${registration?.course_schedule?.end_time}`}
                      </div>
                      <div className="text-sm text-gray-600">
                        {registration?.address}
                      </div>
                      <div className="text-sm text-gray-500">
                        {registration?.city}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-800">
                      <div className="text-sm text-gray-900">
                        {`${registration?.first_name} ${registration?.last_name}`}
                      </div>
                      <div className="text-sm text-gray-900">
                        {registration?.gender === "L"
                          ? "Laki-laki"
                          : "Perempuan"}
                      </div>
                      <div className="text-sm text-gray-900">
                        {moment(registration?.birth_date).format(
                          "DD MMMM YYYY"
                        )}
                      </div>
                      <div className="text-sm text-gray-500">
                        {registration?.email}
                      </div>
                      <div className="text-sm text-gray-500">
                        {registration?.phone_number}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <div className="text-sm text-gray-900">
                        {moment(registration?.created_at).format(
                          "DD MMMM YYYY"
                        )}
                      </div>
                      <div className="text-sm text-gray-500">
                        {Number(registration?.xendit_transaction?.price) === 0
                          ? "FREE"
                          : `Rp${format(
                              registration?.xendit_transaction?.price
                            )}`}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm font-medium">
                      {registration?.motivation}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
