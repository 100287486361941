import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";

const Home = () => {
  const user = useSelector((state) => state.user);

  if (!user.user_token) {
    return <Navigate to="/" />;
  }
  return (
    <div className="relative min-h-screen md:flex">
      <Sidebar />
      <div className="flex-1 p-10 text-2xl font-bold">
        Welcome to Jakartalabs Admin Dashboard
      </div>
      ;
    </div>
  );
};

export default Home;
