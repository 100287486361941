import React from "react";
import image from "../../constants/image";

const ForthComponent = () => {
  const data = [
    {
      title: "Siap Berkomitmen",
    },
    {
      title: "Memiliki Kemauan Belajar",
    },
    {
      title: "Memiliki Kemampuan Dasar Bahasa Inggris",
    },
    {
      title: "Memiliki Motivasi Tinggi",
    },
    {
      title: "Memiliki Laptop",
    },
  ];

  const waLink = "https://wa.me/6288908892612?text=I%20want%20to%20talk%20to%20advisor%20of%20Jakartalabs"; // prettier-ignore

  return (
    <div style={{ backgroundColor: "#F8F8F8" }}>
      <div className="flex flex-col items-center mx-auto w-4/5 md:w-3/5 pb-10">
        <div className="w-full bg-white sm:w-560px border border-gray-100 shadow-md p-5 sm:p-8 rounded-xl">
          <h1 className="text-custom-blue text-3xl sm:text-4xl font-bold text-center">
            Konsultasi dengan Representatif JakartaLabs
          </h1>
          <p className="mt-8 text-center">
            Kami yang membantumu memilih, kamu ygn menentukan.
          </p>
          <div className="mt-8 flex justify-center items-center">
            <div className="w-280px">
              <a
                href={waLink}
                rel="noopener noreferrer"
                target="_blank"
                className="w-full flex items-center justify-center px-4 py-2 border-2 rounded-md shadow-sm text-base font-medium text-custom-blue border-custom-blue hover:border-custom-blue"
              >
                Hubungi Kami
              </a>
            </div>
          </div>

          <div className="mt-10 w-full mx-auto">
            <img
              className="rounded-2xl"
              src={image.consultant}
              alt="student-qualification"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForthComponent;
