import classNames from "classnames";
import React, { Fragment } from "react";

import Button from "../../components/Button";

const ThirdComponent = ({ waLink }) => {
  const contents = [
    ["Pembayaran di Awal", "Ya", "Melunasi fase preparation"],
    [
      "Angsuran",
      "Pembayaran dibagi menjadi 2 tahap",
      "Pelunasan fase prep bayar setelah lulus & bekerja",
    ],
    ["Penyesuaian Pembayaran", "-", "Ya"],
    ["Penempatan Kerja", "Ya", "Ya"],
  ];

  return (
    <div
      style={{ backgroundColor: "#F8F8F8" }}
      className="flex flex-col items-center gap-y-10 py-30px sm:py-80px"
    >
      <div className="w-5/6 sm:w-3/5 2xl:w-3/5 flex flex-col sm:flex-row justify-center items-center sm:gap-x-16 gap-y-20">
        <div className="bg-white rounded-xl shadow-lg">
          <div className="w-full border grid grid-cols-2 sm:grid-cols-3 rounded-t-xl">
            {/* header */}
            <div className="hidden sm:flex border-r border-b" />
            <div className="border-r border-b text-center p-5">
              <p className="text-custom-blue text-2xl font-bold">Regular</p>
            </div>
            <div className="text-center p-5 border-b">
              <p className="text-custom-blue text-2xl font-bold">ISA</p>
            </div>
            {/* content */}
            {contents.map((content, i) => (
              <Fragment key={i}>
                <div
                  className={classNames(
                    i !== contents.length - 1 ? "border-b" : "",
                    "border-r hidden sm:flex items-center px-8 py-3"
                  )}
                >
                  <p className="text-gray-600 text-base">{content[0]}</p>
                </div>
                <div
                  className={classNames(
                    i !== contents.length - 1 ? "border-b" : "",
                    "border-r text-center px-8 py-3"
                  )}
                >
                  <p className="text-gray-900 text-base sm:hidden">
                    {content[0]}
                  </p>
                  <p className="text-gray-600 text-base">{content[1]}</p>
                </div>
                <div
                  className={classNames(
                    i !== contents.length - 1 ? "border-b" : "",
                    "text-center px-8 py-3"
                  )}
                >
                  <p className="text-gray-900 text-base sm:hidden">
                    {content[0]}
                  </p>
                  <p className="text-gray-600 text-base">{content[2]}</p>
                </div>
              </Fragment>
            ))}
          </div>
          <div className="w-220px my-5 mx-auto">
            <a href={waLink} rel="noopener noreferrer" target="_blank">
              <Button
                className="rounded-full font-bold"
                title="RESERVASI SEKARANG"
                textSize="text-12px"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdComponent;
