import React from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";

import format from "../../helpers/format.currency";
import { s3_url } from "../../plugins/storage-public-url";
import image from "../../constants/image";

const SixthComponent = ({ course }) => {
  const data = [
    { name: "Kurikulum Terbaru & Terbaik" },
    { name: "Instruktur Professional & Berpengalaman" },
    { name: "Portfolio & Certificate" },
    { name: "Komitmen Ikut Kelas Paruh Waktu" },
  ];

  const discount = course?.discount?.percentage / 100 || 0 / 100;
  const discountAmount = course?.price * discount;
  const discountPrice = Number(course?.price) - discountAmount;

  return (
    <div className="flex flex-col bg-white items-center gap-y-10 pb-60px pt-30px">
      <div className="w-5/6 sm:w-4/5 2xl:w-3/5 flex flex-col sm:flex-row justify-center items-center sm:gap-x-16 gap-y-20">
        <div className="relative sm:w-auto w-full">
          <div
            style={{
              backgroundImage: `url(${
                s3_url + course?.thumbnail || image.defaultThumbnail
              })`,
            }}
            className="absolute flex bg-cover bg-no-repeat bg-color-gray-500 h-320px sm:w-420px w-full shadow-md rounded-md"
          />
          <div className="-mt-20px ml-20px sm:-mt-30px sm:ml-30px h-320px sm:w-420px w-full border border-custom-blue rounded-md" />
        </div>
        <div>
          <div className="flex flex-col gap-y-3 pb-10">
            {course?.discount && (
              <p className="line-through decoration-custom-blue text-2xl font-bold text-gray-300">
                {`Rp ${format(course?.price)}`}
              </p>
            )}
            <p className="text-4xl font-bold">{`Rp ${format(
              discountPrice
            )}`}</p>
          </div>
          <div className="flex flex-col gap-y-3">
            {data?.map((el, i) => (
              <div key={i} className="flex items-center gap-x-3">
                <div>
                  <CheckCircleIcon
                    width={26}
                    className="text-gray-100 bg-custom-blue rounded-full border-nonde"
                  />
                </div>
                <div>{el.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SixthComponent;
