import {
  PROCESS_COURSE_SUCCESS,
  PROCESS_COURSE_FAILED,
  LOAD_COURSES_SUCCESS,
  LOAD_COURSES_BY_CATEGORY_TYPE_SUCCESS,
  LOAD_COURSES_BY_TYPE_SUCCESS,
  LOAD_COURSE_SUCCESS,
  LOAD_COURSE_LOADING,
  LOAD_COURSE_FAILED,
} from "./course.action.type";

import axios from "../../plugins/api";

export const createCourse = (payload, user_token) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .post("/courses/create", payload, {
        headers: {
          authorization: user_token,
        },
      })
      .then(() => {
        dispatch(processCourseSuccess());
      })
      .catch(() => {
        dispatch(processCourseFailed());
      });
  };
};

export const updateCourseFile = (id, payload, user_token) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .put(`/courses/${id}/update-file`, payload, {
        headers: {
          authorization: user_token,
        },
      })
      .then(() => {
        dispatch(processCourseSuccess());
      })
      .catch(() => {
        dispatch(processCourseFailed());
      });
  };
};

export const updateCourse = (id, payload, user_token) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .put(`/courses/${id}/update`, payload, {
        headers: {
          authorization: user_token,
        },
      })
      .then(() => {
        dispatch(processCourseSuccess());
      })
      .catch(() => {
        dispatch(processCourseFailed());
      });
  };
};

export const deleteCourse = (id, user_token) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .delete(`/courses/${id}/delete`, {
        headers: {
          authorization: user_token,
        },
      })
      .then(() => {
        dispatch(processCourseSuccess());
      })
      .catch(() => {
        dispatch(processCourseFailed());
      });
  };
};

export const getCourses = (query) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .get(`/courses?${query}`)
      .then(({ data: { data } }) => {
        dispatch(getDataSuccess(data));
      })
      .catch(() => {
        dispatch(getDataFailed());
      });
  };
};

export const getCoursesByCategoryType = (query) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    const queryParams = query ? `?${query}` : "";
    axios
      .get(`/courses/by-category-type${queryParams}`)
      .then(({ data: { data } }) => {
        dispatch(getDataByCategoryTypeSuccess(data));
      })
      .catch(() => {
        dispatch(getDataFailed());
      });
  };
};

export const getCoursesByType = (query) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .get(`/courses/by-type?${query}`)
      .then(({ data: { data } }) => {
        dispatch(getDataByTypeSuccess(data));
      })
      .catch(() => {
        dispatch(getDataFailed());
      });
  };
};

export const getOneCourse = (slug) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .get(`/courses/read-one?slug=${slug}`)
      .then(({ data: { data } }) => {
        dispatch(getOneDataSuccess(data));
      })
      .catch(() => {
        dispatch(getDataFailed());
      });
  };
};

const processCourseSuccess = () => ({
  type: PROCESS_COURSE_SUCCESS,
});

const processCourseFailed = () => ({
  type: PROCESS_COURSE_FAILED,
});

const getDataSuccess = (payload) => ({
  type: LOAD_COURSES_SUCCESS,
  payload,
});

const getDataByCategoryTypeSuccess = (payload) => ({
  type: LOAD_COURSES_BY_CATEGORY_TYPE_SUCCESS,
  payload,
});

const getDataByTypeSuccess = (payload) => ({
  type: LOAD_COURSES_BY_TYPE_SUCCESS,
  payload,
});

const getOneDataSuccess = (payload) => ({
  type: LOAD_COURSE_SUCCESS,
  payload,
});

const getDataLoading = () => ({
  type: LOAD_COURSE_LOADING,
});

const getDataFailed = () => ({
  type: LOAD_COURSE_FAILED,
});
