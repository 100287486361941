import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import SelectMenu from "./SelectMenu";
import ButtonArrow from "./ButtonArrow";

import { getLocations } from "../store/location/location.action";
import { getCategories } from "../store/category/category.action";
import { getCoursesByType } from "../store/courses/course.action";

const Search = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const programId = search.get("program");
  const courseId = search.get("course");
  const locationId = search.get("location");

  const location = useSelector((state) => state.location);
  const category = useSelector((state) => state.category);
  const course = useSelector((state) => state.course);

  const allProgram = {
    id: "",
    name: "All programs",
  };
  const allCourse = {
    id: "",
    name: "All courses",
  };
  const allLocation = {
    id: "",
    name: "All locations",
  };

  const [currentProgram, setCurrentProgram] = React.useState(allProgram);
  const [currentCourse, setCurrentCourse] = React.useState(allCourse);
  const [currentLocation, setCurrentLocation] = React.useState(allLocation);

  const programs = category?.categories?.map((el) => ({
    id: el.id,
    name: el.name,
  }));
  const courses = course?.coursesByType?.rows
    ?.filter(
      (el) =>
        el.category_id === currentProgram.id || el.category_id === programId
    )
    ?.map((el) => ({
      id: el.id,
      name: el.title,
    })) || [];
  const locations = location?.locations?.map((el) => ({
    id: el.id,
    name: el.name,
  }));

  const searchProgram = programs?.find((el) => el.id === programId);
  const searchCourse = courses?.find((el) => el.id === courseId);
  const searchLocation = locations?.find((el) => el.id === locationId);

  React.useEffect(() => {
    if (searchProgram) {
      setCurrentProgram(searchProgram);
    }

    if (searchCourse) {
      setCurrentCourse(searchCourse);
    }

    if (searchLocation) {
      setCurrentLocation(searchLocation);
    }
  }, []);

  React.useEffect(() => {
    dispatch(getLocations());
    dispatch(getCategories());
    dispatch(getCoursesByType("limit=100&page=1&type=BOOTCAMP"));
  }, [dispatch]);

  const setQuery = () => {
    const searchKey = ["program", "course", "location"];
    const searchResult = [currentProgram, currentCourse, currentLocation];
    let query = "?";

    searchResult.forEach((el, i) => {
      if (el.id) {
        query += `${searchKey[i]}=${el.id}`;
      }

      if (searchResult[i + 1]?.id) {
        query += "&";
      }
    });

    return query;
  };

  const onSearch = () => {
    const query = setQuery();

    if (query === "?") {
      navigate("/search", { replace: true });
    } else {
      navigate(`/search${query}`);
    }
  };

  const onSelectChange = (selected, setSelected, key) => {
    if (key === "program") {
      setCurrentCourse(allCourse);
    }
    setSelected(selected);
  };

  return (
    <div className="flex flex-col md:flex-row py-5 px-8 md:px-12 gap-y-4 md:gap-x-10 md:items-end">
      <div className="md:w-1/4">
        <SelectMenu
          title="Programs"
          data={[allProgram, ...programs]}
          selected={currentProgram}
          setSelected={(value) =>
            onSelectChange(value, setCurrentProgram, "program")
          }
        />
      </div>
      <div className="md:w-1/4">
        <SelectMenu
          title="Courses"
          data={[allCourse, ...courses]}
          selected={currentCourse}
          setSelected={(value) =>
            onSelectChange(value, setCurrentCourse, "course")
          }
        />
      </div>
      <div className="md:w-1/4">
        <SelectMenu
          title="Locations"
          data={[allLocation, ...locations]}
          selected={currentLocation}
          setSelected={(value) =>
            onSelectChange(value, setCurrentLocation, "location")
          }
        />
      </div>
      <div className="md:w-1/4" onClick={onSearch}>
        <ButtonArrow title="Search now" />
      </div>
    </div>
  );
};

export default Search;
