import React from "react";

import Review from "../../components/Review";

import FirstComponent from "./FirstComponent";
import FirstComponentMobile from "./FirstComponentMobile";
import ForthComponent from "./ForthComponent";
import SecondComponent from "./SecondComponent";
import ThirdComponent from "./ThirdComponent";

const FinancialPartner = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <FirstComponent />
      <FirstComponentMobile />
      <SecondComponent />
      <ThirdComponent />
      <div style={{ backgroundColor: "#F8F8F8" }}>
        <Review />
      </div>
      <ForthComponent />
    </div>
  );
};

export default FinancialPartner;
