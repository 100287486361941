import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { EyeIcon } from "@heroicons/react/outline";

import format from "../../../helpers/format.currency";

import { deleteCourse } from "../../../store/courses/course.action";
import classNames from "classnames";

// TITLE | CATEGORY & TIME | PRICE | DISCOUNT | CREATED ON | PUBLISHER

export default function CourseTable({
  courses,
  setOpen,
  setOpenDetail,
  setOpenSchedule,
  setOpenViewDetail,
  setOpenViewSchedule,
  setCourse,
  type,
}) {
  const { rows, count } = courses;

  const [deletedIds, setDeletedIds] = React.useState([]);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const onDelete = (id) => {
    dispatch(deleteCourse(id, user.user_token));
    setDeletedIds([...deletedIds, id]);
  };

  const onEdit = (course) => {
    setCourse(course);
    setOpen(true);
  };

  const onAddDetail = (course) => {
    setCourse(course);
    setOpenDetail(true);
  };

  const onAddSchedule = (course) => {
    setCourse(course);
    setOpenSchedule(true);
  };

  const onViewDetail = (course) => {
    setCourse(course);
    setOpenViewDetail(true);
  };

  const onViewSchedule = (course) => {
    setCourse(course);
    setOpenViewSchedule(true);
  };

  const data = rows?.filter((row) => !deletedIds.includes(row.id));

  const getDiscountPrice = (course) => {
    const discount = course?.discount?.percentage / 100 || 0 / 100;
    const discountAmount = course?.price * discount;
    const discountPrice = Number(course?.price) - discountAmount;

    return discountPrice;
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto">
        <div className="py-2 align-middle inline-block w-full">
          <div className="shadow overflow-hidden border border-gray-200 sm:rounded-lg">
            <table className="divide-y divide-gray-200 w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Category & Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Created
                  </th>
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Action</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data?.map((course) => (
                  <tr
                    key={course?.id}
                    className={classNames(
                      getDiscountPrice(course) !== 0 && type === "FREE"
                        ? "hidden"
                        : "",
                      getDiscountPrice(course) === 0 && type === "PAID"
                        ? "hidden"
                        : ""
                    )}
                  >
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-900">
                        {course?.title}
                      </div>
                      <div className="text-sm text-gray-500">
                        {course?.code}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {course?.category?.name}
                      </div>
                      <div className="text-sm text-gray-500">
                        {course?.type}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {`Rp ${format(getDiscountPrice(course))}`}
                      </div>

                      {course?.discount && (
                        <>
                          <div className="text-sm text-gray-500 line-through decoration-custom-blue">
                            {`Rp ${format(course?.price)}`}
                          </div>
                          <div className="text-sm text-gray-500">
                            {`Discount ${course?.discount.percentage}%`}
                          </div>
                        </>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {moment(course?.created_at).format("DD MMMM YYYY")}
                      </div>
                      <div className="text-sm text-gray-500">
                        {course?.user?.name}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <div className="flex justify-end">
                        <div
                          onClick={() => onEdit(course)}
                          className="text-custom-blue hover:text-indigo-900 pr-2 cursor-pointer"
                        >
                          Edit
                        </div>
                        <div
                          onClick={() => onDelete(course.id)}
                          className="text-red-600 hover:text-red-900 cursor-pointer"
                        >
                          Delete
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <div
                          onClick={() => onAddDetail(course)}
                          className="text-custom-blue hover:text-indigo-900 pr-2 cursor-pointer"
                        >
                          +detail
                        </div>
                        <div
                          onClick={() => onAddSchedule(course)}
                          className="text-custom-blue hover:text-indigo-900 cursor-pointer"
                        >
                          +schedule
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <div
                          onClick={() => onViewDetail(course)}
                          className="flex gap-x-1 text-custom-blue hover:text-indigo-900 cursor-pointer pr-2"
                        >
                          <EyeIcon width={14} />
                          <div>detail</div>
                        </div>
                        <div
                          onClick={() => onViewSchedule(course)}
                          className="flex gap-x-1 text-custom-blue hover:text-indigo-900 cursor-pointer"
                        >
                          <EyeIcon width={14} />
                          <div>schedule</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
