import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { saveState, loadState } from "../helpers/localstorage";

import userReducer from "./user/user.reducer";
import studentReducer from "./student/student.reducer";
import courseReducer from "./courses/course.reducer";
import courseScheduleReducer from "./course.schedule/course.schedule.reducer";
import categoryReducer from "./category/category.reducer";
import locationReducer from "./location/location.reducer";
import discountReducer from "./discount/discount.reducer";
import registrationReducer from "./registration/registration.reducer";
import corporateNeedReducer from "./corporate.need/corporate.need.reducer";

const rootReducer = combineReducers({
  user: userReducer,
  student: studentReducer,
  course: courseReducer,
  schedule: courseScheduleReducer,
  category: categoryReducer,
  location: locationReducer,
  discount: discountReducer,
  registration: registrationReducer,
  corporate: corporateNeedReducer,
});

const persistedState = loadState();

const store = createStore(
  rootReducer,
  persistedState,
  composeWithDevTools(applyMiddleware(thunk))
);

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
