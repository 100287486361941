import {
  SET_STUDENT,
  SIGN_OUT_STUDENT,
  GET_STUDENT,
} from "./student.action.type";

const initialState = {
  id: "",
  token: "",
  full_name: "",
  image_profile: "",
};

function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case SET_STUDENT:
      return {
        ...state,
        id: action.id,
        token: action.token,
        full_name: action.full_name,
        image_profile: action.image_profile,
      };
    case SIGN_OUT_STUDENT:
      return {
        ...state,
        id: null,
        token: null,
        full_name: null,
        image_profile: null,
      };
    case GET_STUDENT:
      return {
        ...state,
        id: action.id,
        token: action.token,
        full_name: action.full_name,
        image_profile: action.image_profile,
      };
    default:
      return state;
  }
}

export default reducer;
