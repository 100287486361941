import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCourses } from "../../store/courses/course.action";

import Program from "../../components/Program";
import HiringPartner from "../../components/HiringPartner";
import Event from "../../components/Event";
import Review from "../../components/Review";
import SubscribeNewsletter from "../../components/SubscribeNewsletter";

import FirstComponent from "./FirstComponent";
import FirstComponentMobile from "./FirstComponentMobile";
import SecondComponent from "./SecondComponent";

const CorporateNeeds = () => {
  const dispatch = useDispatch();

  const { courses } = useSelector((state) => state.course);

  const programs = courses?.rows;

  React.useEffect(() => {
    dispatch(getCourses("limit=100&page=1"));
  }, [dispatch]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <FirstComponent programs={programs} />
      <FirstComponentMobile programs={programs} />
      <HiringPartner />
      <SecondComponent />
      <Program />
      <HiringPartner />
      <Review />
      <Event />
      <SubscribeNewsletter />
    </div>
  );
};

export default CorporateNeeds;
