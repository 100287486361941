import React from "react";
import ReactPlayer from "react-player";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getOneCourse } from "../../store/courses/course.action";

import ResponsiveModal from "../../components/ResponsiveModal";
import FloatingWhatsappButton from "../../components/FloatingWhatsappButton";
import HiringPartner from "../../components/HiringPartner";
import Review from "../../components/Review";
import SubscribeNewsletter from "../../components/SubscribeNewsletter";

import FirstComponent from "./FirstComponent";
import FirstComponentMobile from "./FirstComponentMobile";
import SecondComponent from "./SecondComponent";
import ForthComponent from "./ForthComponent";
import FifthComponent from "./FifthComponent";
import SixthComponent from "./SixthComponent";
import SeventhComponent from "./SeventhComponent";
import EighthComponent from "./EighthComponent";
import TenthComponent from "./TenthComponent";

const Bootcamp = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();

  const { course } = useSelector((state) => state.course);

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    dispatch(getOneCourse(slug));
  }, [slug, dispatch]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ResponsiveModal
        open={open}
        setOpen={setOpen}
        component={
          <div className="w-full h-full flex justify-center items-center">
            {course?.introduction_video ? (
              <ReactPlayer
                url={course?.introduction_video}
                controls
                width="100%"
              />
            ) : (
              <div>Introduction video not found</div>
            )}
          </div>
        }
      />
      <FloatingWhatsappButton />
      <FirstComponent course={course} setOpen={setOpen} />
      <FirstComponentMobile course={course} setOpen={setOpen} />
      <SecondComponent course={course} />
      <HiringPartner />
      <ForthComponent course={course} />
      <FifthComponent course={course} />
      <SixthComponent course={course} />
      <SeventhComponent course={course} />
      <EighthComponent />
      <Review />
      <TenthComponent />
      <SubscribeNewsletter />
    </div>
  );
};

export default Bootcamp;
