import React from "react";
import { PlayIcon } from "@heroicons/react/solid";

import Button from "../../components/Button";

import image from "../../constants/image";

const FirstComponentMobile = ({ waLink }) => {
  return (
    <div className="sm:hidden">
      <div>
        <div
          className="h-280px bg-cover bg-no-repeat bg-color-gray-500"
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${image.isaBg1})`,
          }}
          alt=""
        />
      </div>
      <div className="flex flex-col items-center mx-auto w-4/5 md:w-3/5 py-10">
        <div className="flex flex-col gap-y-5">
          <h1 className="font-bold w-full text-5xl text-custom-blue">
            Belajar Dulu, Bayar Setelah Bekerja
          </h1>
          <p className="w-full text-custom-blue text-lg">
            Ikut program Full Stack JavaScript Fase Immersive / Data Science
            hanya dengan membayar fase persiapan.
          </p>
          <div className="w-220px">
            <a href={waLink} rel="noopener noreferrer" target="_blank">
              <Button title="Daftar Sekarang" className="rounded-md" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstComponentMobile;
