const image = {
  defaultHeaderImage: require("../assets/images/default_header_image.png").default, // prettier-ignore
  defaultThumbnail: require("../assets/images/default_thumbnail.jpg").default,
  background: require("../assets/images/background.jpg").default,
  backgroundMobile: require("../assets/images/background-mobile.png").default,
  corporateNeedsBg: require("../assets/images/corporate-needs-bg.png").default,
  admissionInfo: require("../assets/images/admission-info.jpg").default,
  studentQualification: require("../assets/images/student-qualification.jpg")
    .default,
  consultant: require("../assets/images/consultant.jpg").default,
  verifyCertificate: require("../assets/images/verify-certificate.jpg").default,
  isaBg1: require("../assets/images/isa-bg1.jpg").default,
  isaBg2: require("../assets/images/isa-bg2.jpg").default,
  isaBg3: require("../assets/images/isa-bg3.jpg").default,
};

export default image;
