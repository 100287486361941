import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import Modal from "../../../components/Modal";
import Sidebar from "../../../components/Sidebar";
import Button from "../../../components/Button";

import { getCourses } from "../../../store/courses/course.action";

import CourseForm from "./CourseForm";
import CourseTable from "./CourseTable";
import CourseDetailForm from "./CourseDetailForm";
import CourseDetail from "./CourseDetail";
import CourseSchedule from "./CourseSchedule";
import CourseScheduleForm from "./CourseScheduleForm";

const types = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Paid",
    value: "PAID",
  },
  {
    label: "Free",
    value: "FREE",
  },
];

const Course = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { courses } = useSelector((state) => state.course);
  const [limit, setLimit] = React.useState(5);
  const count = courses?.count;

  const [open, setOpen] = React.useState(false);
  const [openDetail, setOpenDetail] = React.useState(false);
  const [openSchedule, setOpenSchedule] = React.useState(false);
  const [openViewDetail, setOpenViewDetail] = React.useState(false);
  const [openViewSchedule, setOpenViewSchedule] = React.useState(false);
  const [course, setCourse] = React.useState(null);
  const [type, setType] = React.useState("ALL");

  const onAdd = () => {
    setOpen(true);
    setCourse(null);
  };

  React.useEffect(() => {
    dispatch(getCourses(`limit=${limit}&page=1`));
  }, [dispatch]);

  const loadMore = () => {
    dispatch(getCourses(`limit=${limit + 5}&page=1`));
    setLimit(limit + 5);
  };

  if (!user.user_token) {
    return <Navigate to="/" />;
  }
  return (
    <div className="relative min-h-screen md:flex">
      <Sidebar />
      <Modal
        open={open}
        setOpen={setOpen}
        component={
          <CourseForm setOpen={setOpen} course={course} setCourse={setCourse} />
        }
      />
      <Modal
        open={openDetail}
        setOpen={setOpenDetail}
        component={
          <CourseDetailForm
            setOpen={setOpenDetail}
            course={course}
            setCourse={setCourse}
          />
        }
      />
      <Modal
        open={openSchedule}
        setOpen={setOpenSchedule}
        component={
          <CourseScheduleForm
            setOpen={setOpenSchedule}
            course={course}
            setCourse={setCourse}
          />
        }
      />
      <Modal
        open={openViewDetail}
        setOpen={setOpenViewDetail}
        component={<CourseDetail course={course} token={user.user_token} />}
      />
      <Modal
        open={openViewSchedule}
        setOpen={setOpenViewSchedule}
        component={
          <CourseSchedule
            course={course}
            token={user.user_token}
            setOpenViewSchedule={setOpenViewSchedule}
          />
        }
      />
      <div className="flex-1 p-10 text-2xl font-bold">
        <div className="flex justify-end pb-10 gap-x-2">
          <div className="w-40">
            <select
              id="type"
              name="type"
              onChange={(e) => setType(e.target.value)}
              value={type}
              className="block w-full p-2.5 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-custom-blue focus:border-custom-blue sm:text-sm"
            >
              {types?.map((el, i) => (
                <option key={i} value={el?.value}>
                  {el?.label}
                </option>
              ))}
            </select>
          </div>

          <div className="w-120px">
            <Button onClick={onAdd} title="Add Course" className="rounded-md" />
          </div>
        </div>
        <CourseTable
          courses={courses}
          setOpen={setOpen}
          setOpenDetail={setOpenDetail}
          setOpenSchedule={setOpenSchedule}
          setOpenViewDetail={setOpenViewDetail}
          setOpenViewSchedule={setOpenViewSchedule}
          setCourse={setCourse}
          type={type}
        />
        {count > limit && (
          <div className="flex justify-center pt-5">
            <div className="w-120px">
              <Button
                onClick={loadMore}
                title="Load more"
                className="rounded-md"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Course;
