import React from "react";
import Slider from "react-slick";
import classnames from "classnames";

import icon from "../../constants/icon";

const SecondComponent = ({ course }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const course_details = course?.course_details;

  const [descriptions, setDescriptions] = React.useState([]);

  React.useEffect(() => {
    setDescriptions(course_details?.filter((el) => el.type === "DESCRIPTION"));
  }, [course_details]);

  const iconByIndex = (index) => {
    switch (index) {
      case 0:
        return icon.bootcamp1;
      case 1:
        return icon.bootcamp2;
      case 2:
        return icon.bootcamp3;
      case 3:
        return icon.bootcamp4;
      default:
        return icon.bootcamp3;
    }
  };

  const settings = {
    infinite: false,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    beforeChange: (_, next) => setCurrentSlide(next),
    customPaging: function (i) {
      return (
        <div
          className={classnames(
            currentSlide === i ? "bg-white" : "bg-gray-500",
            "w-3 h-full"
          )}
        />
      );
    },
  };

  return (
    <div className="h-280px -top-57px sm:-top-65px sm:mb-100px sm:h-120px relative flex justify-center">
      <div className="absolute w-4/5 2xl:w-3/5 shadow-lg rounded-3xl bg-custom-blue">
        <div className="flex items-center justify-center sm:border-none border-b-2 border-gray-300 py-2 sm:py-5 px-8 sm:px-12">
          <h1 className="font-bold text-sm sm:text-2xl text-white">
            {`Program Bootcamp ${course?.category?.name} JakartaLabs`}
          </h1>
        </div>
        <div className="hidden sm:flex flex-col sm:flex-row py-5 px-8 sm:px-12 gap-y-4 sm:gap-x-10 sm:items-start">
          {descriptions?.map((el, i) => (
            <div key={i} className="md:w-1/4">
              <div className="flex flex-col gap-y-3">
                <div>
                  <img src={iconByIndex(i)} className="h-30px w-auto" alt="" />
                </div>
                <div>
                  <h1 className="text-white text-lg">{el.title}</h1>
                  <p className="text-white text-12px">{el.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="w-full sm:hidden h-220px">
          <Slider {...settings}>
            {descriptions?.map((el, i) => (
              <div key={i}>
                <div className="py-8 px-10">
                  <div className="flex flex-col gap-y-3">
                    <div>
                      <img
                        src={iconByIndex(i)}
                        className="h-30px w-auto"
                        alt=""
                      />
                    </div>
                    <div>
                      <h1 className="text-white text-lg">{el.title}</h1>
                      <p className="text-white text-12px">{el.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default SecondComponent;
