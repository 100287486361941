import { SET_USER, SIGN_OUT_USER, GET_USER } from "./user.action.type";

export const signData = ({ token, name, role }) => {
  return {
    type: SET_USER,
    token,
    name,
    role,
  };
};

export const signOut = () => ({
  type: SIGN_OUT_USER,
});

export const getUser = () => ({
  type: GET_USER,
});
