import React from "react";

import FirstComponent from "./FirstComponent";
import FirstComponentMobile from "./FirstComponentMobile";
import SecondComponent from "./SecondComponent";
import ThirdComponent from "./ThirdComponent";

const AdmissionInfo = () => {
  return (
    <div>
      <FirstComponent />
      <FirstComponentMobile />
      <SecondComponent />
      <ThirdComponent />
    </div>
  );
};

export default AdmissionInfo;
