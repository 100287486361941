import {
  LOAD_COURSE_SCHEDULES_SUCCESS,
  LOAD_SEARCH_COURSE_SCHEDULES_SUCCESS,
  LOAD_ACTIVE_COURSE_SCHEDULES_SUCCESS,
  LOAD_ACTIVE_EVENTS_WORKSHOPS_SUCCESS,
  LOAD_COURSE_SCHEDULE_SUCCESS,
  LOAD_COURSE_SCHEDULE_LOADING,
  LOAD_COURSE_SCHEDULE_FAILED,
} from "./course.schedule.action.type";

const initialState = {
  courseSchedules: [],
  searchResult: [],
  activeCourseSchedules: [],
  activeEventsAndWorkshops: [],
  courseSchedule: {},
  loading: false,
  error: {
    status: false,
    message: "Oops! Something Went Wrong.",
  },
};

function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case LOAD_COURSE_SCHEDULES_SUCCESS:
      return {
        ...state,
        courseSchedules: action.payload,
        loading: false,
        error: {
          status: false,
        },
      };
    case LOAD_ACTIVE_COURSE_SCHEDULES_SUCCESS:
      return {
        ...state,
        activeCourseSchedules: action.payload,
        loading: false,
        error: {
          status: false,
        },
      };
    case LOAD_SEARCH_COURSE_SCHEDULES_SUCCESS:
      return {
        ...state,
        searchResult: action.payload,
        loading: false,
        error: {
          status: false,
        },
      };
    case LOAD_ACTIVE_EVENTS_WORKSHOPS_SUCCESS:
      return {
        ...state,
        activeEventsAndWorkshops: action.payload,
        loading: false,
        error: {
          status: false,
        },
      };
    case LOAD_COURSE_SCHEDULE_SUCCESS:
      return {
        ...state,
        courseSchedule: action.payload,
        loading: false,
        error: {
          status: false,
        },
      };
    case LOAD_COURSE_SCHEDULE_LOADING:
      return {
        ...state,
        loading: true,
        error: {
          status: false,
        },
      };
    case LOAD_COURSE_SCHEDULE_FAILED:
      return {
        ...state,
        error: {
          status: true,
          message: state.error.message,
        },
        loading: false,
      };
    default:
      return state;
  }
}

export default reducer;
