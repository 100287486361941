import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import Sidebar from "../../../components/Sidebar";
import Button from "../../../components/Button";

import { getRegistrations } from "../../../store/registration/registration.action";

import RegistrationTable from "./RegistrationTable";

const types = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Paid",
    value: "PAID",
  },
  {
    label: "Free",
    value: "FREE",
  },
];

const Registration = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { registrations } = useSelector((state) => state.registration);
  const [limit, setLimit] = React.useState(5);
  const count = registrations?.count;

  const [type, setType] = React.useState("ALL");

  React.useEffect(() => {
    dispatch(getRegistrations(`limit=${limit}&page=1&type=${type}`));
  }, [dispatch, type]);

  const loadMore = () => {
    dispatch(getRegistrations(`limit=${limit + 5}&page=1&type=${type}`));
    setLimit(limit + 5);
  };

  if (!user.user_token) {
    return <Navigate to="/" />;
  }
  return (
    <div className="relative min-h-screen md:flex">
      <Sidebar />
      <div className="flex-1 p-10 text-2xl font-bold">
        <RegistrationTable
          registrations={registrations}
          types={types}
          type={type}
          setType={setType}
        />
        {count > limit && (
          <div className="flex justify-center pt-5">
            <div className="w-120px">
              <Button
                onClick={loadMore}
                title="Load more"
                className="rounded-md"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Registration;
