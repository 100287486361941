import React from "react";
import { TrashIcon } from "@heroicons/react/outline";

import axios from "../../../plugins/api";

import icon from "../../../constants/icon";

const icons = {
  1: {
    icon: <img src={icon.profession1} height={32} />,
  },
  2: {
    icon: <img src={icon.profession2} height={32} />,
  },
  3: {
    icon: <img src={icon.profession3} height={32} />,
  },
  4: {
    icon: <img src={icon.profession4} height={32} />,
  },
  5: {
    icon: <img src={icon.profession5} height={32} />,
  },
};

const CourseDetail = ({ course, token }) => {
  const { course_details } = course;

  const [descriptions, setDescriptions] = React.useState([]);
  const [syllabus, setSyllabus] = React.useState([]);
  const [advantages, setAdvantages] = React.useState([]);

  React.useEffect(() => {
    setDescriptions(course_details?.filter((el) => el?.type === "DESCRIPTION"));
    setSyllabus(course_details?.filter((el) => el?.type === "SYLLABUS"));
    setAdvantages(course_details?.filter((el) => el?.type === "ADVANTAGE"));
  }, [course_details]);

  const onDeleteCourseDetail = (id) => {
    axios
      .delete(`/course-details/${id}/delete`, {
        headers: {
          authorization: token,
        },
      })
      .then(() => {
        setDescriptions(descriptions?.filter((el) => el?.id !== id));
        setSyllabus(syllabus?.filter((el) => el?.id !== id));
        setAdvantages(advantages?.filter((el) => el?.id !== id));
        alert("Berhasil menghapus detail");
      })
      .catch(() => {
        alert("Terjadi kesalahan");
      });
  };

  return (
    <div>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <div className="sm:rounded-md sm:overflow-hidden mt-10">
          <h1 className="text-lg font-bold text-custom-blue">
            Course Descriptions
          </h1>
          <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
            {descriptions?.map((el) => (
              <div
                key={el?.id}
                className="relative p-4 bg-white rounded-lg shadow-md border border-gray-100"
              >
                <div
                  style={{ top: -16, left: -16 }}
                  className="absolute rounded-full bg-white p-1 shadow-md border border-gray-100 w-9 h-9 flex justify-center items-center leading-0 font-bold text-custom-blue"
                >
                  {el?.order}
                </div>
                <div
                  onClick={() => onDeleteCourseDetail(el?.id)}
                  style={{ top: -16, right: -16 }}
                  className="absolute rounded-full bg-white p-1 shadow-md border border-gray-100 w-9 h-9 flex justify-center items-center leading-0 font-bold text-custom-blue cursor-pointer"
                >
                  <TrashIcon width={18} className="text-red-500" />
                </div>
                <label className="block text-sm font-medium text-gray-700">
                  {el?.title}
                </label>
                <div className="mt-1">{el?.description}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="sm:rounded-md sm:overflow-hidden mt-10">
          <h1 className="text-lg font-bold text-custom-blue">
            Course Syllabus
          </h1>
          <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
            {syllabus?.map((el) => (
              <div
                key={el?.id}
                className="relative p-4 bg-white rounded-lg shadow-md border border-gray-100"
              >
                <div
                  style={{ top: -16, left: -16 }}
                  className="absolute rounded-full bg-white p-1 shadow-md border border-gray-100 w-9 h-9 flex justify-center items-center leading-0 font-bold text-custom-blue"
                >
                  {el?.order}
                </div>
                <div
                  onClick={() => onDeleteCourseDetail(el?.id)}
                  style={{ top: -16, right: -16 }}
                  className="absolute rounded-full bg-white p-1 shadow-md border border-gray-100 w-9 h-9 flex justify-center items-center leading-0 font-bold text-custom-blue cursor-pointer"
                >
                  <TrashIcon width={18} className="text-red-500" />
                </div>
                <label className="block text-sm font-medium text-gray-700">
                  {el?.title}
                </label>
                <div className="mt-1">{el?.description}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="sm:rounded-md sm:overflow-hidden mt-10">
          <h1 className="text-lg font-bold text-custom-blue">
            Course Advantages
          </h1>
          <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
            {advantages?.map((el) => (
              <div
                key={el?.id}
                className="relative p-4 bg-white rounded-lg shadow-md border border-gray-100"
              >
                <div
                  style={{ top: -16, left: -16 }}
                  className="absolute rounded-full bg-white p-1 shadow-md border border-gray-100 w-9 h-9 flex justify-center items-center leading-0 font-bold text-custom-blue"
                >
                  {el?.order}
                </div>
                <div
                  onClick={() => onDeleteCourseDetail(el?.id)}
                  style={{ top: -16, right: -16 }}
                  className="absolute rounded-full bg-white p-1 shadow-md border border-gray-100 w-9 h-9 flex justify-center items-center leading-0 font-bold text-custom-blue cursor-pointer"
                >
                  <TrashIcon width={18} className="text-red-500" />
                </div>
                <div className="flex gap-x-2 items-center">
                  <div className="bg-custom-blue p-1 rounded-md">
                    {icons[Number(el?.description)]?.icon}
                  </div>
                  <div className="text-sm font-medium text-gray-700">
                    {el?.title}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDetail;
