import {
  LOAD_COURSE_SCHEDULES_SUCCESS,
  LOAD_SEARCH_COURSE_SCHEDULES_SUCCESS,
  LOAD_ACTIVE_COURSE_SCHEDULES_SUCCESS,
  LOAD_ACTIVE_EVENTS_WORKSHOPS_SUCCESS,
  LOAD_COURSE_SCHEDULE_SUCCESS,
  LOAD_COURSE_SCHEDULE_LOADING,
  LOAD_COURSE_SCHEDULE_FAILED,
} from "./course.schedule.action.type";

import axios from "../../plugins/api";

export const getCourseSchedules = (query) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .get(`/course-schedules?${query}`)
      .then(({ data: { data } }) => {
        dispatch(getDataSuccess(data));
      })
      .catch(() => {
        dispatch(getDataFailed());
      });
  };
};

export const getActiveCourseSchedules = (query) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .get(`/course-schedules/active?${query}`)
      .then(({ data: { data } }) => {
        dispatch(getActiveDataSuccess(data));
      })
      .catch(() => {
        dispatch(getDataFailed());
      });
  };
};

export const getSearchCourseSchedules = (query) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .get(`/course-schedules/search?${query}`)
      .then(({ data: { data } }) => {
        dispatch(getSearchDataSuccess(data));
      })
      .catch(() => {
        dispatch(getDataFailed());
      });
  };
};

export const getActiveEventsAndSchedules = (query) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .get(`/course-schedules/active?${query}`)
      .then(({ data: { data } }) => {
        dispatch(getActiveOtherDataSuccess(data));
      })
      .catch(() => {
        dispatch(getDataFailed());
      });
  };
};

export const getOneLocation = (id) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .get(`/course-schedules/${id}`)
      .then(({ data: { data } }) => {
        dispatch(getOneDataSuccess(data));
      })
      .catch(() => {
        dispatch(getDataFailed());
      });
  };
};

const getDataSuccess = (payload) => ({
  type: LOAD_COURSE_SCHEDULES_SUCCESS,
  payload,
});

const getActiveDataSuccess = (payload) => ({
  type: LOAD_ACTIVE_COURSE_SCHEDULES_SUCCESS,
  payload,
});

const getSearchDataSuccess = (payload) => ({
  type: LOAD_SEARCH_COURSE_SCHEDULES_SUCCESS,
  payload,
});

const getActiveOtherDataSuccess = (payload) => ({
  type: LOAD_ACTIVE_EVENTS_WORKSHOPS_SUCCESS,
  payload,
});

const getOneDataSuccess = (payload) => ({
  type: LOAD_COURSE_SCHEDULE_SUCCESS,
  payload,
});

const getDataLoading = () => ({
  type: LOAD_COURSE_SCHEDULE_LOADING,
});

const getDataFailed = () => ({
  type: LOAD_COURSE_SCHEDULE_FAILED,
});
