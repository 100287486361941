import React from "react";
import { BadgeCheckIcon, XCircleIcon } from "@heroicons/react/outline";

import icon from "../../constants/icon";

const FirstComponentMobile = ({ id, isValid }) => {
  return (
    <div className="sm:hidden">
      <div className="flex flex-col items-center mx-auto w-4/5 md:w-3/5 py-10">
        <div>
          <img src={icon.successPayment} alt="" />
        </div>
        <div className="flex flex-col mt-10">
          <p className="text-sm mb-1 font-bold">Payment ID:</p>
          <div className="flex flex-wrap gap-x-1">
            <p className="text-sm mb-1 font-bold uppercase">{` #${id}`}</p>
            {isValid ? (
              <div className="-mt-0.5">
                <BadgeCheckIcon className="h-5 w-5 text-custom-blue" />
              </div>
            ) : (
              <div className="-mt-0.5">
                <XCircleIcon className="h-5 w-5 text-red-600" />
              </div>
            )}
          </div>
          <h1 className="font-bold w-full text-3xl sm:text-3xl text-custom-blue">
            {isValid
              ? "Yippie! You have registered to the event 😆"
              : "Oops! Your transaction invalid 😓"}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default FirstComponentMobile;
