import React from "react";
import notification from "../helpers/notification";

import axios from "../plugins/api";

import Button from "./Button";

const SubscribeNewsletter = () => {
  const [email, setEmail] = React.useState("");

  const onSubscribe = (e) => {
    e.preventDefault();
    axios
      .post("/newsletters/create", { email })
      .then(() => {
        setEmail("");
        notification({
          type: "success",
          title: "Subscribe to our newsletter success",
        });
      })
      .catch((error) => {
        notification({ type: "error" });
      });
  };

  return (
    <div
      style={{
        paddingTop: 160,
        paddingBottom: 80,
      }}
      className="w-full flex flex-col items-center gap-y-10 bg-gray-100"
    >
      <div className="w-full flex pt-10 items-center justify-center">
        <div className="w-5/6 sm:w-4/5 2xl:w-3/5 flex flex-col gap-y-2">
          <div>
            <h1 className="font-bold text-3xl">Join our Newsletter</h1>
            <p className="text-10px text-gray-600">
              Subscribe our weekly newsletters for more updates about us.
            </p>
          </div>
          <form>
            <div className="flex">
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full sm:w-1/4 px-3 rounded-l-md text-sm"
                placeholder="user@jakartalabs.com"
              />
              <span>
                <Button
                  onClick={onSubscribe}
                  title="Subscribe"
                  className="rounded-r-md"
                />
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SubscribeNewsletter;
