import React from "react";

import icon from "../../constants/icon";
import image from "../../constants/image";

const FifthComponent = () => {
  const terms = [
    {
      title: "Bekerja",
      description: "Mulai bayar saat kamu telah bekerja.",
    },
    {
      title: "20%",
      description: "Bayar 20% dari pendapatan bulananmu selama 36 bulan.",
    },
    {
      title: "Rp0",
      description: "Tidak ada denda dan tidak ada bunga.",
    },
    {
      title: "5 Tahun",
      description: "Program bagi hasil otomatis terhenti setelah 5 tahun.",
    },
    {
      title: "Cap Pembayaran",
      description:
        "Berapapun pendapatanmu, cukup bayar maksimal cap pembayaran sesuai program.",
    },
    {
      title: "100%",
      description:
        "Menyesuaikan dengan tingkat kesuksesan karir dan income lulusan.",
    },
  ];

  return (
    <div
      style={{ backgroundColor: "rgba(20, 93, 160, 0.12)" }}
      className="flex flex-col bg-white items-center gap-y-10 py-30px sm:py-80px"
    >
      <div className="w-5/6 sm:w-3/5 2xl:w-3/5 flex flex-col sm:flex-row justify-center items-center sm:gap-x-16 gap-y-5">
        <div className="w-full sm:w-1/2">
          <div className="sm:hidden mb-10 flex justify-center">
            <img className="rounded-2xl w-3/5" src={image.isaBg3} alt="isa-2" />
          </div>
          <h1 className="font-bold w-full text-5xl text-custom-blue">
            Income Share Agreement Terms
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-10 mt-10">
            {terms.map((el, i) => (
              <div
                key={i}
                className="bg-white shadow-lg p-5 rounded-lg sm:text-right sm:h-180px"
              >
                <div className="flex sm:justify-end">
                  <img src={icon.check} alt="" width={24} />
                </div>
                <p className="font-bold text-lg text-custom-blue my-2">
                  {el.title}
                </p>
                <p className="text-11px text-gray-500">{el.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="sm:flex hidden">
          <img className="rounded-2xl w-320px" src={image.isaBg3} alt="isa-2" />
        </div>
      </div>
    </div>
  );
};

export default FifthComponent;
