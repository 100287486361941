import { SET_USER, SIGN_OUT_USER, GET_USER } from "./user.action.type";

const initialState = {
  id: "",
  user_token: "",
  name: "",
  role: "",
};

function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        id: action.id,
        user_token: action.token,
        name: action.name,
        role: action.role,
      };
    case SIGN_OUT_USER:
      return {
        ...state,
        id: null,
        user_token: null,
        name: null,
        role: null,
      };
    case GET_USER:
      return {
        ...state,
        id: action.id,
        user_token: action.token,
        name: action.name,
        role: action.role,
      };
    default:
      return state;
  }
}

export default reducer;
