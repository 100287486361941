import React from "react";
import { LockClosedIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";

import axios from "../../plugins/api";

import jakartalabs from "../../assets/logo/jakartalabs.png";
import { signData } from "../../store/student/student.action";
import Alert from "../../components/Alert";
import { Navigate } from "react-router-dom";
import { LockOpenIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SignIn = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const student = useSelector((state) => state.student);

  const onLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      email,
      password,
    };

    axios
      .post("/students/login", payload)
      .then(({ data: { data } }) => {
        const payload = {
          full_name: data.student.full_name,
          token: data.token,
          image_profile: data.student.image_profile,
        };

        dispatch(signData(payload));
        setShowMessage(false);
        setLoading(false);
      })
      .catch(() => {
        setShowMessage(true);
        setLoading(false);
      });
  };

  if (student.token) {
    return <Navigate to="/" />;
  }

  return (
    <div className="h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-4 lg:border-2 lg:p-12 lg:rounded">
        <div>
          <img
            className="mx-auto h-8 w-auto"
            src={jakartalabs}
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <form className="mt-8 space-y-6">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            {showMessage && (
              <Alert
                text="Email atau password salah!"
                type="fail"
                onClose={setShowMessage}
              />
            )}
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-custom-blue focus:border-custom-blue focus:z-10 sm:text-sm"
                placeholder="Email address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-custom-blue focus:border-custom-blue focus:z-10 sm:text-sm"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 text-custom-blue focus:ring-custom-blue border-gray-300 rounded"
              />
              <label
                htmlFor="remember-me"
                className="ml-2 block text-sm text-gray-900"
              >
                Remember me
              </label>
            </div>

            <div className="text-sm">
              <a
                href="#"
                className="font-medium text-custom-blue hover:text-custom-blue"
              >
                Forgot your password?
              </a>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-custom-blue hover:bg-custom-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-blue"
              onClick={onLogin}
              disabled={loading}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                {loading ? (
                  <LockOpenIcon
                    className={classNames(
                      loading ? "animate-ping" : "",
                      "h-5 w-5 text-custom-blue group-hover:text-custom-blue"
                    )}
                    aria-hidden="true"
                  />
                ) : (
                  <LockClosedIcon
                    className="h-5 w-5 text-custom-blue group-hover:text-custom-blue"
                    aria-hidden="true"
                  />
                )}
              </span>
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
