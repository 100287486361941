import React from "react";

import image from "../../constants/image";

const FirstComponent = () => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(60, 60, 60, 0.6), rgba(60, 60, 60, 0.6)), url(${image.background})`,
      }}
      className="sm:flex hidden bg-center bg-cover bg-no-repeat bg-color-gray-500"
    >
      <div
        style={{ paddingTop: 64 }}
        className="flex flex-col md:flex-row self-center justify-center mx-auto h-420px w-4/6 sm:w-4/5 2xl:w-3/5"
      >
        <div className="w-820px flex flex-col gap-y-1 sm:gap-y-5 items-center">
          <h1 className="font-bold w-full text-3xl sm:text-6xl text-center text-white">
            Grow your career with
            <br />
            our top-rated programs.
          </h1>
          <p className="w-full sm:w-1/2 text-white text-center text-sm">
            Learn digital & tech skills with our comprehensive curriculum and
            our expert trainers.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FirstComponent;
