import React from "react";
import Slider from "react-slick";
import classnames from "classnames";

import ArrowButton from "../../components/ArrowButton";
import Button from "../../components/Button";
import { s3_url } from "../../plugins/storage-public-url";

const ForthComponent = ({ course }) => {
  const course_details = course?.course_details;

  const data = course_details?.filter((el) => el.type === "SYLLABUS");
  const length = data?.length;

  const customeSlider = React.useRef();
  const [currentSlide, setCurrentSlide] = React.useState(1);

  const gotoNext = () => {
    customeSlider.current.slickNext();
  };

  const gotoPrev = () => {
    customeSlider.current.slickPrev();
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
    beforeChange: (_, current) => setCurrentSlide((current += 1)),
  };

  return (
    <div className="flex flex-col bg-custom-blue items-center gap-y-10 pb-60px pt-60px">
      <h1 className="text-white font-bold text-2xl sm:text-4xl text-center w-4/6 sm:w-4/5">
        {`BOOTCAMP: ${course?.category?.name}`}
      </h1>
      <div className="hidden w-full sm:flex items-center justify-center flex-col gap-y-10">
        <div
          className={classnames(
            data?.length === 3 ? "grid-cols-3" : "grid-cols-4",
            "w-4/6 sm:w-4/5 2xl:w-3/5 grid gap-6"
          )}
        >
          {data?.map((el) => (
            <div
              key={el.id}
              className="relative p-4 bg-white rounded-lg shadow-md"
            >
              <div
                style={{ top: -16, left: -16 }}
                className="absolute rounded-full bg-white p-1 shadow-md w-9 h-9 flex justify-center items-center leading-0 font-bold text-custom-blue"
              >
                {el.order}
              </div>
              <p className="font-bold text-custom-blue h-72px">{el.title}</p>
              <p className="text-12px">{el.description}</p>
            </div>
          ))}
        </div>
        <div className="w-4/6 sm:w-4/5 2xl:w-3/5 flex gap-x-5 items-center">
          <p className="text-white">
            {`Info lebih lengkap, silahkan download syllabus ${course?.category?.name}`}
          </p>
          <div className="w-220px">
            <a
              href={s3_url + course?.document}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Button
                title="Download Syllabus"
                className="rounded-md font-bold"
                color="bg-white text-custom-blue"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="w-full sm:hidden flex items-center justify-center">
        {currentSlide > 1 && (
          <div className="flex sm:hidden w-1/10 justify-end pr-5">
            <ArrowButton
              onClick={gotoPrev}
              type="prev"
              color="bg-white text-custom-blue"
            />
          </div>
        )}
        <div className="w-4/6 sm:w-4/5 2xl:w-3/5">
          <Slider ref={customeSlider} {...settings}>
            {data?.map((el, i) => (
              <div key={i}>
                <div className="ml-4 mr-8 py-5">
                  <div
                    key={i}
                    className="relative p-4 bg-white rounded-lg shadow-md"
                  >
                    <div
                      style={{ top: -16, left: -16 }}
                      className="absolute rounded-full bg-white p-1 shadow-md w-9 h-9 flex justify-center items-center leading-0 font-bold text-custom-blue"
                    >
                      {i + 1}
                    </div>
                    <p className="font-bold text-custom-blue h-72px">
                      {el.title}
                    </p>
                    <p className="text-12px">{el.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        {length !== currentSlide && (
          <div className="w-1/10 justify-start pl-5">
            <ArrowButton
              onClick={gotoNext}
              type="next"
              color="bg-white text-custom-blue"
            />
          </div>
        )}
      </div>
      <div className="sm:hidden w-4/6 sm:w-4/5 2xl:w-3/5 relative flex flex-col items-center">
        <p className="text-white text-12px">
          {`Info lebih lengkap, silahkan download syllabus ${course?.category?.name}`}
        </p>
        <div style={{ bottom: -80 }} className="absolute mx-auto">
          <a
              href={s3_url + course?.document}
              rel="noopener noreferrer"
              target="_blank"
          >
            <Button
              title="Download Syllabus"
              className="rounded-md font-bold shadow-lg"
              color="bg-white text-custom-blue"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ForthComponent;
