import React from "react";
import moment from "moment";
import { NavLink } from "react-router-dom";

import calender from "../../assets/icons/calender.svg";

import format from "../../helpers/format.currency";

import ButtonSmall from "../../components/ButtonSmall";

const SeventhComponent = ({ course }) => {
  const course_schedules = course?.course_schedules;

  return (
    <div className="flex flex-col bg-white items-center gap-y-10 pb-60px sm:pt-30px">
      <div className="w-5/6 sm:w-4/5 2xl:w-3/5 flex flex-col justify-between items-center gap-y-5 bg-gray-100 py-10 rounded-lg shadow-lg">
        <h1 className="text-custom-blue font-bold text-2xl sm:text-4xl text-center">
          Jadwal Bootcamp
        </h1>
        {course_schedules?.length === 0 && (
          <div className="w-full px-10 text-center">
            <p className="font-bold">Jadwal Belum Tersedia</p>
            <p className="text-sm">Hubungi kami untuk info lebih lanjut</p>
          </div>
        )}

        <div className="hidden sm:flex flex-col w-full px-10">
          {course_schedules?.map((el, i) => (
            <div key={i}>
              {i > 0 && <hr className="border-b-1 border-gray-400" />}
              <div className="flex">
                <div style={{ width: "50%" }} className="border-gray-500 p-3">
                  <p className="text-custom-blue">
                    {course?.title}{" "}
                    <span className="font-bold">{el?.mode}</span>
                  </p>
                  <p className="text-12px pt-4">
                    Start:{" "}
                    <span className="font-bold">
                      {moment(el?.start_date).format("DD MMMM YYYY")}
                    </span>
                  </p>
                </div>
                <div
                  style={{ width: "15%" }}
                  className="flex align-items justify-center border-gray-500 p-3"
                >
                  <img src={calender} width={72} />
                </div>
                <hr className="border-r border-gray-400 h-30px self-center" />
                <div
                  style={{ width: "15%" }}
                  className="text-center border-gray-500 p-3"
                >
                  <p className="text-custom-blue">
                    Batch: <span className="font-bold">{el?.batch}</span>
                  </p>
                  <div className="pt-2">
                    <p className="text-12px">{`${el?.start_time} - ${el.end_time}`}</p>
                    <p className="text-12px">{`${format(
                      el.participant_amount
                    )} Students`}</p>
                  </div>
                </div>
                <hr className="border-r border-gray-400 h-30px self-center" />
                <div
                  style={{ width: "30%" }}
                  className="flex items-center justify-between p-3"
                >
                  <div>
                    <p className="text-12px font-bold">{`${el?.total_session} sessions`}</p>
                    <p className="text-12px font-bold">{`${el?.total_hour_per_session} hours/session`}</p>
                  </div>
                  <div className="w-120px">
                    <NavLink to={`/registration?id=${el?.course_id}`}>
                      <ButtonSmall title="Book now" rounded="full" />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="sm:hidden w-full px-5">
          {course_schedules?.map((el, i) => (
            <div key={i}>
              {i > 0 && <hr className="border-b-1 border-gray-400" />}
              <div className="flex flex-col">
                <div className="border-gray-500 p-3">
                  <p className="text-custom-blue">
                    {course?.title}{" "}
                    <span className="font-bold">{el?.mode}</span>
                  </p>
                  <p className="font-bold">{el?.type}</p>
                  <p className="text-12px pt-2">
                    Start:{" "}
                    <span className="font-bold">
                      {moment(el?.start_date).format("DD MMMM YYYY")}
                    </span>
                  </p>
                </div>
                <div className="flex justify-between">
                  <div className="border-gray-500 p-3">
                    <p className="text-custom-blue">
                      Batch: <span className="font-bold">{el?.batch}</span>
                    </p>
                    <div className="pt-2">
                      <p className="text-12px">{`${el?.start_time} - ${el.end_time}`}</p>
                      <p className="text-12px">{`${format(
                        el.participant_amount
                      )} Students`}</p>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-between p-3">
                    <div>
                      <p className="text-12px font-bold">{`${el?.total_session} sessions`}</p>
                      <p className="text-12px font-bold">{`${el?.total_hour_per_session} hours/session`}</p>
                    </div>
                    <div className="w-120px">
                      <NavLink to={`/registration?id=${el?.course_id}`}>
                        <ButtonSmall title="Book now" rounded="full" />
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SeventhComponent;
