const review = {
  reviewDefault: require("../assets/review/review-default.jpeg").default,
  review1: require("../assets/review/review-1.jpeg").default,
  review2: require("../assets/review/review-2.jpeg").default,
  review3: require("../assets/review/review-3.jpeg").default,
  review4: require("../assets/review/review-4.jpeg").default,
  review7: require("../assets/review/review-7.jpeg").default,
};

export default review;
