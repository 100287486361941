import React from "react";

import Search from "../../components/Search";

const FirstComponent = () => {
  return (
    <div className="flex justify-center pt-60px">
      <div className="w-4/5 2xl:w-3/5 bg-white shadow-lg rounded border border-gray-100">
        <div className="flex items-center border-b-2 border-gray-300 py-2 sm:py-5 px-8 sm:px-12">
          <h1 className="font-bold text-sm sm:text-base text-custom-blue">
            Explore our programs to equipped your journey in tech
          </h1>
        </div>
        <Search />
      </div>
    </div>
  );
};

export default FirstComponent;
