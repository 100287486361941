import React from "react";
import { PlayIcon } from "@heroicons/react/solid";

import { s3_url } from "../../plugins/storage-public-url";

import ButtonArrow from "../../components/ButtonArrow";

import image from "../../constants/image";

const FirstComponent = ({ course, setOpen }) => {
  const waCourseTitle = course?.title?.split(" ").join("%20");
  const waLink = `https://wa.me/6288908892612?text=I%20want%20to%20know%20more%20about%20${waCourseTitle}`; // prettier-ignore

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0.5)), url(${
          s3_url + course?.header_image || image.defaultHeaderImage
        })`,
      }}
      className="sm:flex h-full hidden bg-cover bg-no-repeat bg-color-gray-500"
    >
      <div className="flex flex-col md:flex-row items-center justify-center sm:justify-start mx-auto h-600px w-4/6 sm:w-4/5 2xl:w-3/5">
        <div className="flex flex-col gap-y-1 sm:gap-y-5">
          <h1 className="w-full sm:w-720px font-bold text-3xl sm:text-7xl text-white">
            {course?.title}
          </h1>
          <p className="w-full sm:w-1/2 text-white text-sm">
            {course?.description}
          </p>
          <div className="flex gap-x-10">
            <div className="flex gap-x-3 items-center">
              <div
                className="border rounded-full p-px cursor-pointer"
                onClick={() => setOpen(true)}
              >
                <PlayIcon className="w-16px sm:w-36px text-white" />
              </div>
              <p className="leading-0 text-white text-12px">
                {`Saya ingin belajar ${course?.category?.name}`}
              </p>
            </div>
            <div className="w-180px">
              <a href={waLink} rel="noopener noreferrer" target="_blank">
                <ButtonArrow title="Hubungi Kami" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstComponent;
