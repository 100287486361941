import React from "react";
import { BadgeCheckIcon, XCircleIcon } from "@heroicons/react/outline";

import icon from "../../constants/icon";

const SuccessPayment = ({ id, isValid }) => {
  return (
    <div
      style={{ backgroundColor: "#F8F8F8" }}
      className="h-full sm:flex hidden bg-cover bg-no-repeat bg-color-gray-500 mb-60px"
    >
      <div className="gap-x-5 flex flex-col md:flex-row items-center justify-between mx-auto min-h-screen w-4/6 sm:w-4/5 2xl:w-3/5">
        <div className="w-1/2 flex flex-col">
          <div className="flex gap-x-1">
            <p className="text-sm mb-1 font-bold">
              Payment ID<span className="uppercase">{` #${id}`}</span>
            </p>
            {isValid ? (
              <div className="-mt-0.5">
                <BadgeCheckIcon className="h-5 w-5 text-custom-blue" />
              </div>
            ) : (
              <div className="-mt-0.5">
                <XCircleIcon className="h-5 w-5 text-red-600" />
              </div>
            )}
          </div>
          <h1 className="font-bold w-full text-3xl sm:text-3xl text-custom-blue">
            {isValid
              ? "Yippie! You have registered to the event 😆"
              : "Oops! Your transaction invalid 😓"}
          </h1>
        </div>
        <div>
          <img src={icon.successPayment} width={420} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SuccessPayment;
