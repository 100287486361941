import React from "react";
import classnames from "classnames";

const ButtonSmall = ({ title, rounded }) => {
  return (
    <button
      style={{ fontSize: 12 }}
      className={classnames(
        rounded ? `rounded-${rounded}` : "rounded-md",
        "flex w-full items-center justify-center px-2 py-1 border border-transparent shadow-sm text-white bg-custom-blue hover:bg-custom-blue"
      )}
    >
      {title}
    </button>
  );
};

export default ButtonSmall;
