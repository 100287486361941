import React from "react";

import image from "../../constants/image";

const FirstComponent = () => {
  return (
    <div className="h-full sm:flex hidden">
      <div className="flex items-center justify-center gap-x-20 mx-auto h-640px w-4/6 sm:w-4/5 2xl:w-3/5">
        <div>
          <h1 className="text-custom-blue font-bold text-5xl mb-5">
            Kejar Karir 100% dengan Cicilan 0%
          </h1>
          <p className="leading-7">
            Belajar di program Bootcamp dan Professional Development JakartaLabs
            bisa dicicil setelah masuk kelas.
          </p>
        </div>
        <div className="-mt-60px -mb-60px flex flex-col sm:flex-row justify-center items-center sm:gap-x-16 gap-y-20">
          <div className="relative sm:w-auto w-full">
            <div
              style={{
                backgroundImage: `url(${image.studentQualification})`,
              }}
              className="absolute flex bg-cover bg-no-repeat bg-color-gray-500 h-320px sm:w-520px shadow-md rounded-2xl"
            />
            <div className="-mt-20px ml-20px sm:-mt-40px sm:ml-40px h-320px sm:w-520px border-2 border-custom-blue rounded-2xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstComponent;
