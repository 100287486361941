import { BrowserRouter as Router } from "react-router-dom";
import { NotificationContainer } from "react-notifications";

import Pages from "./pages";

import "moment/locale/id";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-notifications/lib/notifications.css";

function App() {
  return (
    <Router>
      <Pages />
      <NotificationContainer />
    </Router>
  );
}

export default App;
