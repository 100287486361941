import React from "react";

import image from "../../constants/image";

import FirstComponentForm from "./FirstComponentForm";

const FirstComponent = ({ programs }) => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(20, 93, 160, 0.7), rgba(20, 93, 160, 0.7)), url(${image.corporateNeedsBg})`,
      }}
      className="h-full sm:flex hidden bg-cover bg-no-repeat bg-color-gray-500 mb-60px"
    >
      <div className="gap-x-5 flex flex-col md:flex-row items-center justify-between mx-auto min-h-screen w-4/6 sm:w-4/5 2xl:w-3/5">
        <div className="w-1/2 flex flex-col">
          <h1 className="font-bold w-full text-3xl sm:text-4xl text-white">
            Tranformasi dan
            <br />
            Akselerasi Perusahaan Anda
          </h1>
          <p className="text-white text-sm mt-2">
            dengan materi bootcamp high-tech dari JakartaLabs
            <br />
            dan jadwal pelatihan fleksibel.
          </p>
        </div>
        <FirstComponentForm programs={programs} />
      </div>
    </div>
  );
};

export default FirstComponent;
