import React from "react";

import RegistrationForm from "./RegistrationForm";
import RegistrationFormMobile from "./RegistrationFormMobile";

const Registration = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center bg-gray-100 py-100px">
      <h1 className="text-3xl sm:text-4xl text-custom-blue font-bold">
        Formulir Pendaftaran
      </h1>
      <div className="hidden sm:flex">
        <RegistrationForm />
      </div>
      <div className="sm:hidden flex justify-center">
        <RegistrationFormMobile />
      </div>
    </div>
  );
};

export default Registration;
