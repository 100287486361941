import { NotificationManager } from "react-notifications";

const notification = ({ type, title, message, command }) => {
  switch (type) {
    case "info":
      NotificationManager.info(message);
      return;
    case "success":
      NotificationManager.success(message, title);
      return;
    case "warning":
      NotificationManager.warning(message, title, 3000);
      return;
    case "error":
      NotificationManager.error(
        message || "Oops! Something went wrong",
        command || "Refresh to try again"
      );
      return;
  }
};

export default notification;
