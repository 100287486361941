import React from "react";
import moment from "moment";

import format from "../../../helpers/format.currency";

export default function CourseTable({ corporateNeeds }) {
  const data = corporateNeeds?.rows;

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto">
        <div className="py-2 align-middle inline-blockw-full">
          <div className="shadow overflow-hidden border border-gray-200 sm:rounded-lg">
            <table className="divide-y divide-gray-200 w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Corporate Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Course
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Email & Whatsapp PIC
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Created
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Notes
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data?.map((corporate) => (
                  <tr key={corporate.id}>
                    <td className="px-6 py-4 w-180px text-sm text-gray-800">
                      {corporate?.company_name}
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-900">
                        {corporate?.course?.title}
                      </div>
                      <div className="text-sm text-gray-500">
                        {corporate?.course?.type}
                      </div>
                      <div className="text-sm text-gray-500">
                        {`Participant: ${corporate?.participant_amount}`}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-800">
                      <div className="text-sm text-gray-900">
                        {corporate?.pic_name}
                      </div>
                      <div className="text-sm text-gray-500">
                        {corporate?.pic_email}
                      </div>
                      <div className="text-sm text-gray-500">
                        {corporate?.pic_whatsapp}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">
                      {moment(corporate.created_at).format("DD MMMM YYYY")}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium">
                      {corporate?.notes}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
