import classNames from "classnames";
import React, { Fragment } from "react";
import { CheckIcon } from "@heroicons/react/solid";

import logo from "../../constants/logo";

import Button from "../../components/Button";

const ThirdComponent = () => {
  const contents = [
    ["Total Plafond", "Rp5,000,000", "Rp8,000,000-Rp12,000,000"],
    ["Tenor Pinjaman", "Hingga 12 bulan", "Hingga 12 bulan"],
    ["Suku Bunga per Tahun", "Mulai dari 0%", "Cicilan 0%"],
    ["DP", "Rp500,000-Rp5,000,000", "Rp750,000"],
    ["Cicilan Mulai Dari", "Rp1,500,000-an/Bulan", "Rp910,000-an/Bulan"],
  ];

  return (
    <div className="bg-custom-blue flex flex-col items-center gap-y-10 py-30px sm:py-80px divide-y">
      <div className="w-5/6 sm:w-3/5 2xl:w-3/5 flex flex-col sm:flex-row justify-center items-center sm:gap-x-16 gap-y-20">
        <div className="w-full">
          <div className="bg-white shadow-lg w-full grid grid-cols-2 sm:grid-cols-3 rounded-xl">
            {/* header */}
            <div className="hidden bg-white border-gray-200 rounded-tl-xl border-r border-b p-5 sm:flex items-center justify-center">
              <img src={logo.jakartalabs} className="w-180px" alt="" />
            </div>
            <div className="bg-table-green border-gray-200 border-r border-b text-center p-5 flex flex-col items-center justify-center">
              <p className="text-gray-700 text-xl font-bold">Bootcamp</p>
              <p className="text-gray-700 ">4 Bulan</p>
            </div>
            <div className="bg-table-violet border-gray-200 rounded-tr-xl text-center p-5 border-b flex flex-col items-center justify-center">
              <p className="text-gray-700 text-xl font-bold">
                Professional Development
              </p>
              <p className="text-gray-700">3 Bulan</p>
            </div>
            {/* content */}
            {contents.map((content, i) => (
              <Fragment key={i}>
                <div
                  className={classNames(
                    i !== contents.length - 1 ? "border-b border-gray-200" : "",
                    "hidden border-r border-gray-200 sm:flex items-center px-5 py-3 bg-white"
                  )}
                >
                  <p className="text-gray-600 text-10px">{content[0]}</p>
                </div>
                <div
                  className={classNames(
                    i !== contents.length - 1 ? "border-b border-gray-200" : "",
                    "flex flex-col justify-center border-r border-gray-200 text-center px-5 py-3 bg-row-green"
                  )}
                >
                  <p className="sm:hidden text-gray-600 text-10px">
                    {content[0]}
                  </p>
                  <p className="text-gray-600 font-bold text-10px">
                    {content[1]}
                  </p>
                </div>
                <div
                  className={classNames(
                    i !== contents.length - 1 ? "border-b border-gray-200" : "",
                    "flex flex-col justify-center text-center border-gray-200 px-5 py-3 bg-row-violet"
                  )}
                >
                  <p className="sm:hidden text-gray-600 text-10px">
                    {content[0]}
                  </p>
                  <p className="text-gray-600 font-bold text-10px">
                    {content[2]}
                  </p>
                </div>
              </Fragment>
            ))}

            <div className="flex flex-col gap-y-5 text-center col-span-2 sm:col-span-3 py-3">
              <p className="text-xs text-gray-600">
                *Untuk informasi selanjutnya bisa dikonsultasikan dengan tim
                students success JakartaLabs
              </p>
              <p className="text-xs text-gray-600 font-bold">
                **Syarat dan Ketentuan Berlaku
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5 w-4/5 sm:w-3/5">
        <p className="text-white w-4/5 sm:w-full mx-auto text-center font-bold text-xl">
          Download Detail Skema dan Simulasi Cicilan
        </p>
        <div className="w-220px my-5 mx-auto">
          <Button
            className="rounded-full font-bold"
            title="Download"
            textSize="text-12px"
            color="text-custom-blue bg-white"
          />
        </div>
      </div>
    </div>
  );
};

export default ThirdComponent;
