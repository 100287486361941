import {
  PROCESS_COURSE_SUCCESS,
  PROCESS_COURSE_FAILED,
  LOAD_COURSES_SUCCESS,
  LOAD_COURSES_BY_CATEGORY_TYPE_SUCCESS,
  LOAD_COURSES_BY_TYPE_SUCCESS,
  LOAD_COURSE_SUCCESS,
  LOAD_COURSE_LOADING,
  LOAD_COURSE_FAILED,
} from "./course.action.type";

const initialState = {
  courses: [],
  coursesByCategoryType: [],
  coursesByType: [],
  course: {},
  loading: false,
  error: {
    status: false,
    message: "Oops! Something Went Wrong.",
  },
};

function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case PROCESS_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {
          status: false,
        },
      };
    case PROCESS_COURSE_FAILED:
      return {
        ...state,
        loading: false,
        error: {
          status: true,
        },
      };
    case LOAD_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.payload,
        loading: false,
        error: {
          status: false,
        },
      };
    case LOAD_COURSES_BY_CATEGORY_TYPE_SUCCESS:
      return {
        ...state,
        coursesByCategoryType: action.payload,
        loading: false,
        error: {
          status: false,
        },
      };
    case LOAD_COURSES_BY_TYPE_SUCCESS:
      return {
        ...state,
        coursesByType: action.payload,
        loading: false,
        error: {
          status: false,
        },
      };
    case LOAD_COURSE_SUCCESS:
      return {
        ...state,
        course: action.payload,
        loading: false,
        error: {
          status: false,
        },
      };
    case LOAD_COURSE_LOADING:
      return {
        ...state,
        loading: true,
        error: {
          status: false,
        },
      };
    case LOAD_COURSE_FAILED:
      return {
        ...state,
        error: {
          status: true,
          message: state.error.message,
        },
        loading: false,
      };
    default:
      return state;
  }
}

export default reducer;
