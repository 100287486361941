import React from "react";

import image from "../../constants/image";

import FirstComponentMobileForm from "./FirstComponentMobileForm";

const FirstComponentMobile = ({ programs }) => {
  return (
    <div className="sm:hidden">
      <div className="flex flex-col items-center mx-auto w-4/5 md:w-3/5 py-10">
        <div className="flex flex-col gap-y-5">
          <h1 className="font-bold w-full text-5xl text-custom-blue">
            Tranformasi dan Akselerasi Perusahaan Anda
          </h1>
          <p className="w-full text-custom-blue text-lg">
            dengan materi bootcamp high-tech dari JakartaLabs dan jadwal
            pelatihan fleksibel.
          </p>
          <p className="w-full text-custom-blue text-lg">
            Isi formulir yang tersedia, agar kami dapat menganalisa dan
            memberikan rekomendasi pelatihan terbaik untuk corporate Anda.
          </p>
        </div>
      </div>
      <div>
        <div
          className="h-280px bg-cover bg-no-repeat bg-color-gray-500"
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(20, 93, 160, 0.7), rgba(20, 93, 160, 0.7)), url(${image.corporateNeedsBg})`,
          }}
          alt=""
        />
      </div>
      <FirstComponentMobileForm programs={programs} />
    </div>
  );
};

export default FirstComponentMobile;
