import React from "react";

import FirstComponent from "./FirstComponent";

const VerifyCertificate = () => {
  return (
    <div>
      <FirstComponent />
    </div>
  );
};

export default VerifyCertificate;
