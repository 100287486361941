import React from "react";
import { PlayIcon } from "@heroicons/react/solid";

import { s3_url } from "../../plugins/storage-public-url";

import ButtonArrow from "../../components/ButtonArrow";

import image from "../../constants/image";

const FirstComponentMobile = ({ course, setOpen }) => {
  return (
    <div className="sm:hidden">
      <div className="flex flex-col items-center mx-auto w-4/5 md:w-3/5 py-10">
        <div className="flex flex-col gap-y-5">
          <h1 className="font-bold w-full text-5xl text-custom-blue">
            {course?.title}
          </h1>
          <p className="w-full text-custom-blue text-lg">
            {course?.description}
          </p>
          <div className="flex flex-col gap-y-3">
            <div className="flex gap-x-3 items-center">
              <div
                className="border-2 border-custom-blue rounded-full p-px"
                onClick={() => setOpen(true)}
              >
                <PlayIcon className="w-20px text-custom-blue" />
              </div>
              <p className="leading-0 text-custom-blue text-12px">
                {`Saya ingin belajar ${course?.category?.name}`}
              </p>
            </div>
            <div className="w-180px">
              <ButtonArrow title="Hubungi Kami" />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className="h-280px bg-cover bg-no-repeat bg-color-gray-500"
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${
              s3_url + course?.header_image || image.defaultThumbnail
            })`,
          }}
          alt=""
        />
      </div>
    </div>
  );
};

export default FirstComponentMobile;
