import React from "react";
import Slider from "react-slick";

import classames from "classnames";

import ArrowButton from "../../components/ArrowButton";

const SecondComponent = () => {
  const data = [
    {
      title: "Mengisi Formulir",
      description:
        "Calon siswa wajib mengisi formulir aplikasi di website kami. Pastikan semua kolom diisi dengan benar dan lengkap.",
    },
    {
      title: "Wawancara",
      description:
        "Setelah melakukan pengecekan formulir, kami akan menghubungi calon siswa untuk melakukan sesi wawancara.",
    },
    {
      title: "Ikut Tes",
      description:
        "Proses selanjutnya calon siswa wajib mengikuti tes logika dan bahasa Inggris yang kami berikan.",
    },
    {
      title: "Hasil Seleksi",
      description:
        "Kami akan memberitahu hasil seleksi dalam waktu 24 jam setelah calon siswa mengikuti tes logika & bahasa Inggris.",
    },
  ];

  const customeSlider = React.useRef();

  const [currentSlide, setCurrentSlide] = React.useState(1);

  const gotoNext = () => {
    customeSlider.current.slickNext();
  };

  const gotoPrev = () => {
    customeSlider.current.slickPrev();
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
    beforeChange: (_, current) => setCurrentSlide((current += 1)),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const length = data?.length;

  return (
    <div style={{ backgroundColor: "#F8F8F8" }} className="pt-20">
      <div className="relative border-t flex justify-center w-full border-custom-blue">
        <div style={{ top: -45 }} className="absolute w-4/5 sm:w-4/5 2xl:w-3/5 py-10">
          <div className="hidden sm:grid sm:grid-cols-4 gap-5">
            {[1, 2, 3, 4].map((el) => (
              <div key={el} className="border-t border-b border-white">
                <div className="w-2 h-2 bg-custom-blue mx-auto rounded-full" />
              </div>
            ))}
          </div>
          <div className="sm:hidden">
            <div className="border-t border-b border-white">
              <div className="w-2 h-2 bg-custom-blue mx-auto rounded-full" />
            </div>
          </div>
        </div>
      </div>
      <div className="hidden sm:flex flex-col items-center mx-auto w-4/5 sm:w-4/5 2xl:w-3/5 py-10">
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-5">
          {data.map((el, i) => (
            <div key={i} className="p-4 bg-white rounded-lg shadow-md border">
              <p className="font-bold text-custom-blue h-64px">{el.title}</p>
              <p className="text-12px">{el.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="w-full sm:hidden flex items-center justify-center py-8">
        {currentSlide + 2 > 3 && (
          <div className="hidden sm:flex w-1/10 justify-end pr-5">
            <ArrowButton onClick={gotoPrev} type="prev" />
          </div>
        )}
        {currentSlide > 1 && (
          <div className="flex sm:hidden w-1/10 justify-end pr-5">
            <ArrowButton onClick={gotoPrev} type="prev" />
          </div>
        )}
        <div className="w-4/6 sm:w-4/5 2xl:w-3/5">
          <Slider ref={customeSlider} {...settings}>
            {data?.map((el, i) => (
              <div key={i}>
                <div className="ml-1 mr-6 py-3">
                  <div className="p-4 bg-white rounded-lg shadow-md border">
                    <p className="font-bold text-custom-blue h-64px">
                      {el.title}
                    </p>
                    <p className="text-12px">{el.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        {length !== currentSlide && (
          <>
            {length > 1 && (
              <div
                className={classames(
                  length > 3 && length - 2 !== currentSlide
                    ? "sm:flex"
                    : "sm:hidden",
                  "w-1/10 justify-start pl-5"
                )}
              >
                <ArrowButton onClick={gotoNext} type="next" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SecondComponent;
