import React from "react";
import { ArrowNarrowRightIcon } from "@heroicons/react/outline";
import classnames from "classnames";

const ButtonArrow = ({ title, color = "bg-custom-blue text-white" }) => {
  return (
    <button
      className={classnames(
        `${color} flex w-full items-center justify-between px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium`
      )}
    >
      <span>{title}</span>
      <ArrowNarrowRightIcon width={20} />
    </button>
  );
};

export default ButtonArrow;
