import React from "react";

import Button from "../../components/Button";

import image from "../../constants/image";

const SeventhComponent = ({ waLink }) => {
  return (
    <div className="flex flex-col bg-custom-blue items-center gap-y-10 py-30px sm:py-80px">
      <h1 className="font-bold w-5/6 sm:w-3/5 2xl:w-3/5 text-5xl text-white sm:text-center">
        “Belajar Tanpa Memikirkan Biaya”
      </h1>
      <div className="w-5/6 sm:w-3/5 2xl:w-3/5 flex flex-col sm:flex-row justify-center items-center sm:gap-x-16 gap-y-5">
        <div>
          <img
            className="rounded-2xl w-320px h-220px object-cover"
            src={image.isaBg2}
            alt="isa-2"
          />
        </div>
        <div className="w-full sm:w-1/2">
          <p className="text-14px leading-6 mt-5 text-white">
            ISA telah mewujudkan banyak impian calon developer kompeten yang
            terhalang biaya. Program ini membuka peluang bagi siapa pun baik
            yang memiliki latar belakang IT maupun non-IT untuk mewujudkan
            karirnya di bidang teknologi.
          </p>
          <div className="my-5 mx-auto">
            <a href={waLink} rel="noopener noreferrer" target="_blank">
              <Button
                className="rounded-full font-bold"
                title="Reservasi Konsultasi"
                textSize="text-12px"
                color="text-custom-blue bg-white"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeventhComponent;
