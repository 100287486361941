export const LOAD_COURSE_SCHEDULES_SUCCESS = "LOAD_COURSE_SCHEDULES_SUCCESS";
export const LOAD_SEARCH_COURSE_SCHEDULES_SUCCESS =
  "LOAD_SEARCH_COURSE_SCHEDULES_SUCCESS";
export const LOAD_ACTIVE_COURSE_SCHEDULES_SUCCESS =
  "LOAD_ACTIVE_COURSE_SCHEDULES_SUCCESS";
export const LOAD_ACTIVE_EVENTS_WORKSHOPS_SUCCESS =
  "LOAD_ACTIVE_EVENTS_WORKSHOPS_SUCCESS";
export const LOAD_COURSE_SCHEDULE_SUCCESS = "LOAD_COURSE_SCHEDULE_SUCCESS";
export const LOAD_COURSE_SCHEDULE_LOADING = "LOAD_COURSE_SCHEDULE_LOADING";
export const LOAD_COURSE_SCHEDULE_FAILED = "LOAD_COURSE_SCHEDULE_FAILED";
