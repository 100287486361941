const format = (number) => {
  if (typeof number === "string") {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else if (typeof number === "number") {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "";
  }
};

export default format;
