import React from "react";
import classNames from "classnames";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

const EightComponent = () => {
  const faqs = [
    {
      question: "Berapa lama jangka waktu metode ISA ini?",
      answer: "",
    },
    {
      question:
        "Bagaimana jika saya tidak kunjung bekerja atau telah bekerja namun kemudian terjadi PHK?",
      answer: "",
    },
    {
      question: "Berapa total biaya yang akan saya bayar?",
      answer: "",
    },
  ];

  return (
    <div className="flex flex-col bg-white items-center gap-y-10 py-30px sm:py-80px">
      <h1 className="font-bold w-full text-xl text-custom-blue text-center">
        FAQ
      </h1>
      <div className="w-5/6 sm:w-3/5 2xl:w-3/5 flex flex-col gap-y-5">
        {faqs.map((faq, i) => (
          <Disclosure as="div" key={i} className="pt-6">
            {({ open }) => (
              <>
                <dt>
                  <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                    <span className="font-bold text-sm text-gray-600">
                      {faq.question}
                    </span>
                    <span className="ml-6 flex items-center">
                      <ChevronDownIcon
                        className={classNames(
                          open ? "-rotate-180" : "rotate-0",
                          "h-5 w-5 transform"
                        )}
                        aria-hidden="true"
                      />
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="dd" className="mt-2 pr-12">
                  <p className="text-base text-gray-500">{faq.answer}</p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
};

export default EightComponent;
