import React from "react";

import icon from "../../constants/icon";
import image from "../../constants/image";

const SecondComponent = () => {
  const data = [
    {
      icon: icon.creditCard,
      title: "Financial Partner",
      description:
        "JakartaLabs bekerja sama dengan beberapa rekan finansial yang terdaftar dan diawasi oleh OJK.",
    },
    {
      icon: icon.stats,
      title: "Cicilan",
      description:
        "Siswa dapat melakukan cicilan 0% kepada financial partner untuk semua program di JakartaLabs.",
    },
    {
      icon: icon.key,
      title: "Rekomendasi Kerja",
      description:
        "Setelah lulus, siswa akan dibantu untuk dicarikan kerja di 250+ perusahaan hiring partner JakartaLabs.",
    },
    {
      icon: icon.listCheck,
      title: "Proses Mudah",
      description:
        "Proses pengajuan pinjaman kepada financial partner sangat cepat dan mudah, serta bisa dilakukan dari rumah.",
    },
  ];

  return (
    <div style={{ backgroundColor: "#F8F8F8" }}>
      <div className="sm:flex flex-col items-center mx-auto w-4/5 sm:w-4/5 2xl:w-3/5 py-20">
        <h1 className="text-custom-blue font-bold text-4xl mb-10">
          Keunggulan Program ISA
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-5 sm:divide-x sm:divide-custom-blue">
          {data.map((el, i) => (
            <div key={i} className="sm:p-4 flex sm:flex-col gap-x-3">
              <div className="flex mb-5">
                <div>
                  <div className="bg-white rounded-full p-2 shadow-md">
                    <img src={el.icon} className="w-12 sm:w-4 sm:h-4" alt="" />
                  </div>
                </div>
              </div>
              <div>
                <p className="font-bold text-custom-blue h-36px">{el.title}</p>
                <p className="text-12px">{el.description}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-10 flex flex-col justify-center items-center text-center">
          <div className="mb-5">
            <img src={image.isaBg3} alt="" className="w-180px rounded-2xl" />
          </div>
          <h1 className="text-custom-blue font-bold text-4xl mb-10">
            Cicilan 0%
          </h1>
          <p className="w-3/5 text-gray-500 text-xs">
            Cicilan 0% adalah fasilitas yang diberikan oleh Financial Partner
            kami untuk yang ingin belajar di JakartaLabs namun terkendala biaya.
            Porgram ini memungkinkan kamu mencicil pembayaran sampai dengan 18
            bulan.
          </p>
          <p className="text-xs font-bold mt-10">
            Cicilan pertama kamu bayarkan sebulan setelah dana dicairkan.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SecondComponent;
