import {
  LOAD_CORPORATE_NEEDS_SUCCESS,
  LOAD_CORPORATE_NEED_SUCCESS,
  LOAD_CORPORATE_NEED_LOADING,
  LOAD_CORPORATE_NEED_FAILED,
} from "./corporate.need.action.type";

const initialState = {
  corporateNeeds: [],
  corporateNeed: {},
  loading: false,
  error: {
    status: false,
    message: "Oops! Something Went Wrong.",
  },
};

function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case LOAD_CORPORATE_NEEDS_SUCCESS:
      return {
        ...state,
        corporateNeeds: action.payload,
        loading: false,
        error: {
          status: false,
        },
      };
    case LOAD_CORPORATE_NEED_SUCCESS:
      return {
        ...state,
        corporateNeed: action.payload,
        loading: false,
        error: {
          status: false,
        },
      };
    case LOAD_CORPORATE_NEED_LOADING:
      return {
        ...state,
        loading: true,
        error: {
          status: false,
        },
      };
    case LOAD_CORPORATE_NEED_FAILED:
      return {
        ...state,
        error: {
          status: true,
          message: state.error.message,
        },
        loading: false,
      };
    default:
      return state;
  }
}

export default reducer;
