import React from "react";
import classnames from "classnames";
import { ArrowNarrowRightIcon } from "@heroicons/react/outline";
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";

const ArrowButton = ({ type, onClick, color }) => {
  return (
    <button
      onClick={onClick}
      className={classnames(
        color ? color : "bg-custom-blue text-white",
        "flex w-7 sm:w-10 items-center justify-between px-2 py-2 border border-transparent rounded-full shadow-sm text-base font-medium"
      )}
    >
      {type === "prev" ? (
        <ArrowNarrowLeftIcon width={20} />
      ) : (
        <ArrowNarrowRightIcon width={20} />
      )}
    </button>
  );
};

export default ArrowButton;
