import React from "react";
import { BadgeCheckIcon } from "@heroicons/react/outline";

import axios from "../../plugins/api";
import { s3_url } from "../../plugins/storage-public-url";

import image from "../../constants/image";

const FirstComponent = () => {
  const [loading, setLoading] = React.useState(false);
  const [certificateId, setCertificateId] = React.useState("");
  const [certificate, setCertificate] = React.useState(null);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      if (certificateId) {
        const { data } = await axios.get(
          `/certificates/find-by-ref-id?ref_id=${certificateId}`
        );

        if (data.data) {
          setCertificate(data.data);
          setLoading(false);
        } else {
          setLoading(false);
          alert("Sertifikat tidak ditemukan");
        }
      } else {
        alert("Silahkan masukkan input ID Sertifikat");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="h-full flex bg-gray-50">
      <div className="flex flex-col-reverse sm:flex-row items-center justify-between gap-x-5 gap-y-5 py-10 sm:py-0 mx-auto sm:h-560px w-5/6 sm:w-4/5 2xl:w-3/5">
        <div className="w-full sm:w-1/2">
          <h1 className="text-custom-blue font-bold text-sm">
            Verifikasi Sertifikat
          </h1>
          <h2 className="font-bold text-2xl mb-3">
            Verifikasi Keaslian Sertifikat JakartaLabs
          </h2>
          <p className="leading-7 text-14px text-gray-600">
            Silahkan input ID sertifikat kelulusan yang anda terima.
          </p>
          <div>
            <form className="mt-5">
              <input
                id="certificateId"
                name="certificateId"
                type="search"
                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-custom-blue focus:border-custom-blue focus:z-10 sm:text-sm"
                placeholder="ID Sertifikat"
                onChange={(e) => {
                  setCertificateId(e.target.value);
                  if (!e.target.value) {
                    setCertificate(null);
                  }
                }}
                required
              />

              {!certificate && (
                <h1 className="text-custom-blue font-bold text-sm mt-5 text-center">
                  Dimana letak ID Sertifikat?
                </h1>
              )}

              {certificate && (
                <div className="relative mt-5 shadow-lg rounded-xl bg-white p-5">
                  <div className="absolute right-0 top-0 bg-gray-200 rounded-tr-xl rounded-bl-xl p-1">
                    <BadgeCheckIcon className="text-custom-blue" width={24} />
                  </div>
                  <div className="flex sm:flex-row flex-col gap-x-5">
                    <p className="sm:w-1/5 text-gray-500">Nama:</p>
                    <p className="font-bold text-custom-blue">
                      {certificate?.student?.full_name}
                    </p>
                  </div>
                  <div className="flex sm:flex-row flex-col gap-x-5">
                    <p className="sm:w-1/5 text-gray-500">Courses:</p>
                    <p className="font-bold text-custom-blue">
                      {certificate?.course_schedule?.course?.title}
                    </p>
                  </div>
                  <div className="flex sm:flex-row flex-col gap-x-5">
                    <p className="sm:w-1/5 text-gray-500">Sertifikat url:</p>
                    <a
                      className="font-bold text-custom-blue"
                      href={s3_url + certificate?.document}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Klik disini
                    </a>
                  </div>
                </div>
              )}

              <div className="mt-10 w-full">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-custom-blue hover:bg-custom-blue transition ease-in-out duration-150"
                  onClick={onSubmit}
                  disabled={loading ? true : false}
                >
                  {loading && (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  Periksa
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="w-full sm:w-1/2">
          <img
            className="rounded-xl"
            src={image.verifyCertificate}
            alt="verify-certificate"
          />
        </div>
      </div>
    </div>
  );
};

export default FirstComponent;
