/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState, useRef } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import {
  BookOpenIcon,
  XIcon,
  MenuAlt3Icon,
  InformationCircleIcon,
  DocumentSearchIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import jakartalabs from "../assets/logo/jakartalabs.png";

import { getCoursesByType } from "../store/courses/course.action";

const admissions = [
  {
    name: "Admission Info",
    to: "/admission-info",
    icon: InformationCircleIcon,
  },
  {
    name: "Income Share Agreement",
    to: "/income-share-agreement",
    icon: InformationCircleIcon,
  },
  {
    name: "Financial Partner",
    to: "/financial-partner",
    icon: InformationCircleIcon,
  },
];
const students = [
  {
    name: "Verify Certificate",
    to: "/verify-certificate",
    icon: DocumentSearchIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Headers() {
  const dispatch = useDispatch();

  const course = useSelector((state) => state.course);

  useEffect(() => {
    dispatch(getCoursesByType("limit=10&page=1&type=BOOTCAMP"));
  }, [dispatch]);

  const programs = course?.coursesByType?.rows?.map((el) => {
    const slug = el?.slug;

    return {
      name: el.title,
      to: `/bootcamps/${slug}`,
      icon: BookOpenIcon,
    };
  });

  const waLink = "https://wa.me/6288908892612?text=I%20want%20to%20talk%20to%20advisor%20of%20Jakartalabs"; // prettier-ignore

  let timeout;
  const timeoutDuration = 200;

  const buttonCourseRef = useRef(null);
  const buttonAdmissionRef = useRef(null);
  const buttonStudentRef = useRef(null);
  const [openCourseMenu, setOpenCourseMenu] = useState(false);
  const [openAdmissionMenu, setOpenAdmissionMenu] = useState(false);
  const [openStudentMenu, setOpenStudentMenu] = useState(false);
  const [openMobileMenu, setOpentMobileMenu] = useState(false);

  const toggleMenuCourse = (open) => {
    setOpenCourseMenu((openState) => !openState);
    buttonCourseRef?.current?.click();
  };

  const onHoverCourse = (open, action) => {
    if (
      (!open && !openCourseMenu && action === "onMouseEnter") ||
      (open && openCourseMenu && action === "onMouseLeave")
    ) {
      clearTimeout(timeout);
      timeout = setTimeout(() => toggleMenuCourse(open), timeoutDuration);
    }
  };

  const toggleMenuAdmission = (open) => {
    setOpenAdmissionMenu((openState) => !openState);
    buttonAdmissionRef?.current?.click();
  };

  const onHoverAdmission = (open, action) => {
    if (
      (!open && !openAdmissionMenu && action === "onMouseEnter") ||
      (open && openAdmissionMenu && action === "onMouseLeave")
    ) {
      clearTimeout(timeout);
      timeout = setTimeout(() => toggleMenuAdmission(open), timeoutDuration);
    }
  };

  const toggleMenuStudent = (open) => {
    setOpenStudentMenu((openState) => !openState);
    buttonStudentRef?.current?.click();
  };

  const onHoverStudent = (open, action) => {
    if (
      (!open && !openStudentMenu && action === "onMouseEnter") ||
      (open && openStudentMenu && action === "onMouseLeave")
    ) {
      clearTimeout(timeout);
      timeout = setTimeout(() => toggleMenuStudent(open), timeoutDuration);
    }
  };

  const handleClickOutside = (event) => {
    if (
      (buttonCourseRef.current &&
        !buttonCourseRef.current.contains(event.target)) ||
      (buttonAdmissionRef.current &&
        !buttonAdmissionRef.current.contains(event.target)) ||
      (buttonStudentRef.current &&
        !buttonStudentRef.current.contains(event.target))
    ) {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Popover className="relative bg-white border-b-2 border-gray-100">
      <div className="m-w-4/5 sm:w-4/5 2xl:w-3/5 mx-auto sm:px-0 px-4">
        <div className="flex justify-between items-center py-4 md:justify-between">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <NavLink to="/">
              <span className="sr-only">Workflow</span>
              <img
                className="h-4 w-auto sm:h-5"
                src={jakartalabs}
                alt="jakartalabs"
              />
            </NavLink>
          </div>
          <div className="flex justify-start md:hidden">
            <Popover.Button className="bg-white rounded-md inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-custom-blue">
              <MenuAlt3Icon
                onClick={() => setOpentMobileMenu(true)}
                className="h-6 w-6"
              />
            </Popover.Button>
          </div>
          <Popover.Group
            as="nav"
            className="hidden md:justify-center md:flex space-x-8"
          >
            <NavLink
              to="/"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Home
            </NavLink>
            <Popover className="relative">
              {({ open }) => (
                <div
                  onMouseEnter={() => onHoverCourse(open, "onMouseEnter")}
                  onMouseLeave={() => onHoverCourse(open, "onMouseLeave")}
                >
                  <Popover.Button
                    ref={buttonCourseRef}
                    className={classNames(
                      open ? "text-gray-900" : "text-gray-500",
                      "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900"
                    )}
                  >
                    <span>Courses</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-gray-400",
                        "ml-2 h-5 w-5 group-hover:text-gray-500"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 pt-7 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-b-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {programs?.map((item) => (
                            <NavLink
                              key={item.name}
                              to={item.to}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon
                                className="flex-shrink-0 h-6 w-6 text-custom-blue"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">
                                  {item.name}
                                </p>
                              </div>
                            </NavLink>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </div>
              )}
            </Popover>

            <NavLink
              to="/corporate-needs"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Coorporate Needs
            </NavLink>

            <Popover className="relative">
              {({ open }) => (
                <div
                  onMouseEnter={() => onHoverAdmission(open, "onMouseEnter")}
                  onMouseLeave={() => onHoverAdmission(open, "onMouseLeave")}
                >
                  <Popover.Button
                    ref={buttonAdmissionRef}
                    className={classNames(
                      open ? "text-gray-900" : "text-gray-500",
                      "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900"
                    )}
                  >
                    <span>Admission</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-gray-400",
                        "ml-2 h-5 w-5 group-hover:text-gray-500"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 pt-7 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-b-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {admissions?.map((item) => (
                            <NavLink
                              key={item.name}
                              to={item.to}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon
                                className="flex-shrink-0 h-6 w-6 text-custom-blue"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">
                                  {item.name}
                                </p>
                              </div>
                            </NavLink>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </div>
              )}
            </Popover>

            <Popover className="relative">
              {({ open }) => (
                <div
                  onMouseEnter={() => onHoverStudent(open, "onMouseEnter")}
                  onMouseLeave={() => onHoverStudent(open, "onMouseLeave")}
                >
                  <Popover.Button
                    ref={buttonStudentRef}
                    className={classNames(
                      open ? "text-gray-900" : "text-gray-500",
                      "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900"
                    )}
                  >
                    <span>Student</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-gray-400",
                        "ml-2 h-5 w-5 group-hover:text-gray-500"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 pt-7 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-b-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {students?.map((item) => (
                            <NavLink
                              key={item.name}
                              to={item.to}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon
                                className="flex-shrink-0 h-6 w-6 text-custom-blue"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">
                                  {item.name}
                                </p>
                              </div>
                            </NavLink>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </div>
              )}
            </Popover>
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <a
              href={waLink}
              rel="noopener noreferrer"
              target="_blank"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border-2 rounded-md shadow-sm text-base font-medium text-custom-blue hover:text-custom-blue border-custom-blue hover:border-custom-blue"
            >
              Talk with our Advisor
            </a>
          </div>
        </div>
      </div>

      <Transition
        appear
        show={openMobileMenu}
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Dialog
          as="div"
          className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          onClose={() => setOpentMobileMenu(false)}
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <NavLink to="/">
                  <div>
                    <img
                      className="h-4 w-auto"
                      src={jakartalabs}
                      alt="Workflow"
                    />
                  </div>
                </NavLink>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-custom-blue">
                    <XIcon
                      onClick={() => setOpentMobileMenu(false)}
                      className="h-6 w-6"
                    />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {programs?.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.to}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                      onClick={() => setOpentMobileMenu(false)}
                    >
                      <item.icon
                        className="flex-shrink-0 h-6 w-6 text-custom-blue"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-base font-medium text-gray-900">
                        {item.name}
                      </span>
                    </NavLink>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                {admissions.concat(students)?.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.to}
                    onClick={() => setOpentMobileMenu(false)}
                    className="text-sm font-medium text-gray-900 hover:text-gray-700"
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
              <div>
                <NavLink
                  to="/corporate-needs"
                  className="mb-2 w-full flex items-center justify-center px-4 py-2 border-2 rounded-md shadow-sm text-base font-medium text-custom-blue border-custom-blue hover:border-custom-blue"
                  onClick={() => setOpentMobileMenu(false)}
                >
                  Corporate Needs
                </NavLink>
                <a
                  href={waLink}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="w-full flex items-center justify-center px-4 py-2 border-2 rounded-md shadow-sm text-base font-medium text-custom-blue border-custom-blue hover:border-custom-blue"
                  onClick={() => setOpentMobileMenu(false)}
                >
                  Talk with our Advisor
                </a>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Popover>
  );
}
