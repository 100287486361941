import React from "react";
import Slider from "react-slick";
import classnames from "classnames";

import logo from "../constants/logo";

import ArrowButton from "./ArrowButton";

const HiringPartner = () => {
  const customeSlider = React.useRef();

  const [currentSlide, setCurrentSlide] = React.useState(1);

  const gotoNext = () => {
    customeSlider.current.slickNext();
  };

  const gotoPrev = () => {
    customeSlider.current.slickPrev();
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    beforeChange: (_, current) => setCurrentSlide((current += 1)),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const data = [
    {
      image: logo.partner1,
      width: 320,
    },
    {
      image: logo.partner2,
      width: 200,
    },
    {
      image: logo.partner3,
      width: 320,
    },
  ];

  const length = data?.length;

  return (
    <div
      style={{ paddingBottom: 60 }}
      className="flex flex-col items-center gap-y-10"
    >
      <h1 className="text-custom-blue font-bold text-2xl sm:text-4xl">
        Meet Our Hiring Partner
      </h1>
      <div className="w-full flex items-center justify-center">
        {currentSlide + 2 > 3 && (
          <div className="hidden sm:flex w-1/10 justify-end pr-5">
            <ArrowButton onClick={gotoPrev} type="prev" />
          </div>
        )}
        {currentSlide > 1 && (
          <div className="flex sm:hidden w-1/10 justify-end pr-5">
            <ArrowButton onClick={gotoPrev} type="prev" />
          </div>
        )}
        <div className="w-4/6 sm:w-4/5 2xl:w-3/5">
          <Slider ref={customeSlider} {...settings}>
            {data?.map((el, i) => (
              <div key={i}>
                <div className="ml-1 mr-6 py-3 flex justify-center items-center">
                  <img width={el.width} src={el.image} alt="" />
                </div>
              </div>
            ))}
          </Slider>
        </div>
        {length !== currentSlide && (
          <>
            {length > 1 && (
              <div
                className={classnames(
                  length > 3 && length - 2 !== currentSlide
                    ? "sm:flex"
                    : "sm:hidden",
                  "w-1/10 justify-start pl-5"
                )}
              >
                <ArrowButton onClick={gotoNext} type="next" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default HiringPartner;
