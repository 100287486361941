import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useSearchParams } from "react-router-dom";

import { s3_url } from "../../plugins/storage-public-url";

import image from "../../constants/image";

import { getCoursesByCategoryType } from "../../store/courses/course.action";
import classNames from "classnames";
import icon from "../../constants/icon";

const SecondComponent = () => {
  const dispatch = useDispatch();
  const [search] = useSearchParams();

  const programId = search.get("program");
  const courseId = search.get("course");
  const locationId = search.get("location");

  const course = useSelector((state) => state.course);

  const limit = 10;
  const [startNumber, setStartNumber] = React.useState(1);
  const [page, setPage] = React.useState(1);

  const onSearch = (programId, courseId, locationId, limit, page) => {
    const searchKey = ["program", "course", "location"];
    const searchResult = [programId, courseId, locationId];
    let query = "";

    searchResult.forEach((el, i) => {
      if (el) {
        query += `${searchKey[i]}=${el}`;
      }

      if (searchResult[i + 1]) {
        query += "&";
      }
    });

    if (query) {
      dispatch(
        getCoursesByCategoryType(`limit=${limit}&page=${page}&${query}`)
      );
    } else {
      dispatch(getCoursesByCategoryType(`limit=${limit}&page=${page}`));
    }
  };

  React.useEffect(() => {
    onSearch(programId, courseId, locationId, limit, page);
  }, [programId, courseId, locationId, limit, page]);

  const courses = course?.coursesByCategoryType;

  const onPrevious = () => {
    let currentPage = page;
    const currentStartNumber = startNumber - limit;
    setStartNumber(currentStartNumber);
    if (page > 1) {
      currentPage -= 1;
    }
    setPage(currentPage);
  };

  const onNext = () => {
    const currentPage = page + 1;
    const currentStartNumber = startNumber + limit;

    setStartNumber(currentStartNumber);

    setPage(currentPage);
  };

  const coursesWithLearningMethods = courses?.rows?.map((row) => {
    const learningMethods = [];
    const modes = row.course_schedules?.map((el) => el.mode);

    modes.forEach((mode) => {
      if (!learningMethods.includes(mode)) {
        learningMethods.push(mode);
      }
    });

    return {
      ...row,
      to: `/bootcamps/${row?.slug}`,
      learningMethods,
    };
  });

  if (courses?.count === 0) {
    return (
      <div className="flex justify-center">
        <div className="w-4/5 2xl:w-3/5 flex flex-col items-center justify-center my-10 py-10 border rounded-md text-center">
          <img
            className="mb-5"
            src={icon.searchNotFound}
            alt="search-not-found"
            width={64}
          />
          <p className="font-bold text-custom-blue text-xl">
            Sorry, We couldn't find any Programs for your search.
          </p>
          <p className="text-gray-600">Please select another search</p>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ paddingTop: 60, paddingBottom: 60 }}
      className="flex flex-col items-center gap-y-5 border-b"
    >
      <div className="w-4/5 2xl:w-3/5">
        <h1 className="self-start sm:text-center text-custom-blue font-bold text-4xl">
          JakartaLabs' Programs
        </h1>
      </div>
      <div className="flex flex-col gap-y-8 w-4/5 2xl:w-3/5">
        {coursesWithLearningMethods?.map((el) => (
          <NavLink key={el?.id} to={el?.to}>
            <div className="flex flex-col sm:flex-row justify-between p-5 border border-gray-300 shadow-lg rounded-xl">
              <img
                className="w-full sm:hidden rounded-2xl mb-10 border border-gray-200"
                src={s3_url + el?.thumbnail || image.defaultThumbnail}
                alt="thumbnail"
              />
              <div className="flex">
                <div
                  style={{
                    backgroundImage: `linear-gradient(0deg, rgba(60, 60, 60, 0.4), rgba(60, 60, 60, 0.4)), url(${
                      s3_url + el?.thumbnail || image.defaultThumbnail
                    })`,
                  }}
                  className="sm:flex w-240px h-140px hidden bg-cover bg-no-repeat bg-color-gray-500 rounded-xl"
                />
                <div className="sm:ml-5">
                  <p className="font-bold text-sm text-custom-blue">
                    {el?.category?.name}
                  </p>
                  <p className="font-bold">{el.title}</p>
                  <p className="text-12px mt-5 sm:mt-0">
                    {el.short_description}
                  </p>
                </div>
              </div>
              <hr className="sm:hidden mt-5 border-t border-custom-blue w-30px" />
              <div className="sm:justify-end mt-5 sm:mt-0">
                <p className="font-bold">Pilihan Metode Belajar:</p>
                {el?.learningMethods?.map((el, i) => (
                  <p className="text-sm" key={i}>
                    {el}
                  </p>
                ))}
              </div>
            </div>
          </NavLink>
        ))}
      </div>

      <nav
        className={classNames(
          courses?.rows?.length === courses?.count ? "hidden sm:flex" : "",
          "w-4/5 2xl:w-3/5 bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
        )}
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{startNumber}</span> to{" "}
            <span className="font-medium">
              {courses?.rows?.length <= courses?.count
                ? page * limit >= courses?.count
                  ? courses?.count
                  : page * limit
                : course?.count}
            </span>{" "}
            of <span className="font-medium">{courses?.count}</span> results
          </p>
        </div>
        <div className="flex-1 flex justify-between sm:justify-end">
          {page > 1 ? (
            <button
              onClick={onPrevious}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Previous
            </button>
          ) : (
            <div />
          )}
          {page * limit < courses?.count ? (
            <button
              onClick={onNext}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Next
            </button>
          ) : (
            <div />
          )}
        </div>
      </nav>
    </div>
  );
};

export default SecondComponent;
