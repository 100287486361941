import React from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import moment from "moment";

import axios from "../../../plugins/api";

import Modal from "../../../components/Modal";
import CourseScheduleFormEdit from "./CourseScheduleFormEdit";

const CourseSchedule = ({ course, token, setOpenViewSchedule }) => {
  const { course_schedules } = course;

  const [isEdit, setIsEdit] = React.useState(false);

  const [schedules, setSchedules] = React.useState([]);
  const [schedule, setSchedule] = React.useState(null);

  React.useEffect(() => {
    setSchedules(course_schedules);
  }, [course_schedules]);

  const onDeleteCourseSchedule = (id) => {
    axios
      .delete(`/course-schedules/${id}/delete`, {
        headers: {
          authorization: token,
        },
      })
      .then(() => {
        setSchedules(schedules?.filter((el) => el?.id !== id));
        alert("Berhasil menghapus detail");
      })
      .catch(() => {
        alert("Terjadi kesalahan");
      });
  };

  const onEditCourseSchedule = (schedule) => {
    setSchedule(schedule);
    setIsEdit(true);
  };

  return (
    <div>
      <Modal
        open={isEdit}
        setOpen={setIsEdit}
        component={
          <CourseScheduleFormEdit
            setOpen={setIsEdit}
            courseSchedule={schedule}
            setOpenViewSchedule={setOpenViewSchedule}
          />
        }
      />
      <div className="mt-5 md:mt-0 md:col-span-2">
        <div className="sm:rounded-md sm:overflow-hidden mt-10">
          <h1 className="text-lg font-bold text-custom-blue">
            Course Schedule
          </h1>
          <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
            {schedules?.map((el) => (
              <div
                key={el?.id}
                className="relative p-4 bg-white rounded-lg shadow-md border border-gray-100"
              >
                <div
                  onClick={() => onEditCourseSchedule(el)}
                  style={{ top: -16, right: 26 }}
                  className="absolute rounded-full bg-white p-1 shadow-md border border-gray-100 w-9 h-9 flex justify-center items-center leading-0 font-bold text-custom-blue cursor-pointer"
                >
                  <PencilIcon width={18} className="text-custom-blue" />
                </div>
                <div
                  onClick={() => onDeleteCourseSchedule(el?.id)}
                  style={{ top: -16, right: -16 }}
                  className="absolute rounded-full bg-white p-1 shadow-md border border-gray-100 w-9 h-9 flex justify-center items-center leading-0 font-bold text-custom-blue cursor-pointer"
                >
                  <TrashIcon width={18} className="text-red-500" />
                </div>
                <div className="flex">
                  <div style={{ width: "60%" }} className="border-gray-500 p-3">
                    <p className="text-custom-blue">
                      {course?.title}{" "}
                      <span className="font-bold">{el?.mode}</span>
                    </p>
                    <p className="text-12px">
                      {el?.instructor?.name}
                      {" - "}
                      <span>{el?.instructor?.current_job}</span>
                    </p>
                    <p className="text-12px pt-4">
                      Publish:{" "}
                      <span className="font-bold">
                        {moment(el?.publish_at).format("DD MMMM YYYY")}
                      </span>
                    </p>
                    <p className="text-12px">
                      Start:{" "}
                      <span className="font-bold">
                        {moment(el?.start_date).format("DD MMMM YYYY")}
                      </span>
                    </p>
                    <p className="text-12px">
                      End:{" "}
                      <span className="font-bold">
                        {moment(el?.end_date).format("DD MMMM YYYY")}
                      </span>
                    </p>
                  </div>
                  <hr className="border-r border-gray-400 h-30px self-center" />
                  <div
                    style={{ width: "20%" }}
                    className="text-center border-gray-500 p-3"
                  >
                    <p className="text-custom-blue">
                      Batch: <span className="font-bold">{el?.batch}</span>
                    </p>
                    <div className="pt-2">
                      <p className="text-12px">{`${el?.start_time} - ${el?.end_time}`}</p>
                    </div>
                    <div className="pt-2">
                      <p className="text-12px">{el?.days}</p>
                    </div>
                  </div>
                  <hr className="border-r border-gray-400 h-30px self-center" />
                  <div
                    style={{ width: "20%" }}
                    className="text-center border-gray-500 p-3"
                  >
                    <p className="text-custom-blue">
                      Participants:{" "}
                      <span className="font-bold">
                        {el?.participant_amount}
                      </span>
                    </p>
                    <div className="pt-2">
                      <p className="text-12px">{el?.instructor?.name}</p>
                      <p className="text-12px">{el?.instructor?.current_job}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSchedule;
