import React from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import api from "../../plugins/api";

import FirstComponent from "./FirstComponent";
import FirstComponentMobile from "./FirstComponentMobile";

const SuccessPayment = () => {
  const dispatch = useDispatch();

  const [search] = useSearchParams();
  const [isValid, setIsValid] = React.useState(true);

  const id = search.get("id");

  React.useEffect(() => {
    api
      .get(`/xendit/get-transaction/${id}`)
      .then(({ data: { data } }) => {
        if (data) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      })
      .catch(() => {
        setIsValid(false);
      });
  }, [id]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!id) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <FirstComponent id={id} isValid={isValid} />
      <FirstComponentMobile id={id} isValid={isValid} />
    </div>
  );
};

export default SuccessPayment;
