import React from "react";
import { useDispatch, useSelector } from "react-redux";

import axios from "../../../plugins/api";

import notification from "../../../helpers/notification";

import { getCourses } from "../../../store/courses/course.action";
import { getLocations } from "../../../store/location/location.action";

const modes = [
  {
    label: "Remote",
    value: "REMOTE",
  },
  {
    label: "Hybrid",
    value: "HYBRID",
  },
  {
    label: "Offline",
    value: "OFFLINE",
  },
];

const CourseScheduleForm = ({ setOpen, course, setCourse }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { locations } = useSelector((state) => state.location);

  const [code, setCode] = React.useState("");

  const [batch, setBatch] = React.useState("");

  const [instructors, setInstructors] = React.useState([]);
  const [instructor, setInstructor] = React.useState("");
  const [participantAmount, setParticipantAmount] = React.useState("");
  const [totalSession, setTotalSession] = React.useState("");
  const [totalHourPerSession, setTotalHourPerSession] = React.useState("");
  const [days, setDays] = React.useState("");

  const [location, setLocation] = React.useState(locations?.[0]?.id);
  const [publishAt, setPublishAt] = React.useState("");
  const [startAt, setStartAt] = React.useState("");
  const [endAt, setEndAt] = React.useState("");
  const [startTime, setStartTime] = React.useState("");
  const [endTime, setEndTime] = React.useState("");
  const [mode, setMode] = React.useState(modes[0].value);

  const [loading, setLoading] = React.useState(false);

  const onSave = (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      course_id: course.id,
      code,
      batch: Number(batch),
      participant_amount: participantAmount || null,
      total_session: totalSession || null,
      total_hour_per_session: totalHourPerSession || null,
      instructor_id: instructor || "",
      location_id: location || "",
      publish_at: new Date(publishAt) || null,
      start_date: new Date(startAt) || null,
      end_date: new Date(endAt) || null,
      start_time: startTime,
      end_time: endTime,
      mode,
      days,
    };

    axios
      .post("/course-schedules/create", payload, {
        headers: {
          authorization: user.user_token,
        },
      })
      .then(() => {
        dispatch(getCourses("limit=10&page=1"));
        setOpen(false);
        setCourse(null);
        setLoading(false);
      })
      .catch((error) => {
        notification({
          type: "error",
          message: error.message,
          command: "Fix the wrong input and re-submit!",
        });
        setLoading(false);
      });
  };

  const getInstructors = async () => {
    const {
      data: { data },
    } = await axios.get("/instructors");

    setInstructors(data);
  };

  React.useEffect(() => {
    dispatch(getLocations());
    getInstructors();
  }, [dispatch]);

  return (
    <div>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <form>
          <div className="sm:rounded-md sm:overflow-hidden">
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div>
                <label
                  htmlFor="code"
                  className="block text-sm font-medium text-gray-700"
                >
                  Kode
                </label>
                <div className="mt-1">
                  <input
                    name="code"
                    onChange={(e) => setCode(e.target.value)}
                    value={code}
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="mode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mode
                </label>
                <select
                  id="mode"
                  name="mode"
                  onChange={(e) => setMode(e.target.value)}
                  value={mode}
                  className="mt-1 block w-full p-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-custom-blue focus:border-custom-blue sm:text-sm"
                >
                  {modes?.map((el, i) => (
                    <option key={i} value={el?.value}>
                      {el?.label}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label
                  htmlFor="batch"
                  className="block text-sm font-medium text-gray-700"
                >
                  Batch
                </label>
                <div className="mt-1">
                  <input
                    name="batch"
                    type="number"
                    onChange={(e) => setBatch(e.target.value)}
                    value={batch}
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="totalSession"
                  className="block text-sm font-medium text-gray-700"
                >
                  Total Sesi
                </label>
                <div className="mt-1">
                  <input
                    name="totalSession"
                    type="number"
                    onChange={(e) => setTotalSession(e.target.value)}
                    value={totalSession}
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="totalHourPerSession"
                  className="block text-sm font-medium text-gray-700"
                >
                  Total Jam per Sesi
                </label>
                <div className="mt-1">
                  <input
                    name="totalHourPerSession"
                    type="number"
                    onChange={(e) => setTotalHourPerSession(e.target.value)}
                    value={totalHourPerSession}
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="days"
                  className="block text-sm font-medium text-gray-700"
                >
                  Jadwal hari
                </label>
                <div className="mt-1">
                  <input
                    name="days"
                    onChange={(e) => setDays(e.target.value)}
                    value={days}
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="instructor"
                  className="block text-sm font-medium text-gray-700"
                >
                  Instruktur
                </label>
                <select
                  id="instructor"
                  name="instructor"
                  onChange={(e) => setInstructor(e.target.value)}
                  value={instructor}
                  className="mt-1 block w-full p-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-custom-blue focus:border-custom-blue sm:text-sm"
                >
                  <option value="" disabled>
                    Pilih Instruktur
                  </option>
                  {instructors?.map((el, i) => (
                    <option key={i} value={el?.id}>
                      {`${el?.name} - ${el?.current_job}`}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label
                  htmlFor="participantAmount"
                  className="block text-sm font-medium text-gray-700"
                >
                  Banyak Peserta
                </label>
                <div className="mt-1">
                  <input
                    name="participantAmount"
                    type="number"
                    onChange={(e) => setParticipantAmount(e.target.value)}
                    value={participantAmount}
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="publishAt"
                  className="block text-sm font-medium text-gray-700"
                >
                  Di publikasi pada
                </label>
                <div className="mt-1">
                  <input
                    name="publishAt"
                    type="date"
                    onChange={(e) => setPublishAt(e.target.value)}
                    value={publishAt}
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="startAt"
                  className="block text-sm font-medium text-gray-700"
                >
                  Dimulai pada
                </label>
                <div className="mt-1">
                  <input
                    name="startAt"
                    type="date"
                    onChange={(e) => setStartAt(e.target.value)}
                    value={startAt}
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="endAt"
                  className="block text-sm font-medium text-gray-700"
                >
                  Berakhir pada
                </label>
                <div className="mt-1">
                  <input
                    name="endAt"
                    type="date"
                    onChange={(e) => setEndAt(e.target.value)}
                    value={endAt}
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="startTime"
                  className="block text-sm font-medium text-gray-700"
                >
                  Dimulai pada jam
                </label>
                <div className="mt-1">
                  <input
                    name="startTime"
                    type="time"
                    onChange={(e) => setStartTime(e.target.value)}
                    value={startTime}
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="endTime"
                  className="block text-sm font-medium text-gray-700"
                >
                  Berakhir pada jam
                </label>
                <div className="mt-1">
                  <input
                    name="endTime"
                    type="time"
                    onChange={(e) => setEndTime(e.target.value)}
                    value={endTime}
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="location"
                  className="block text-sm font-medium text-gray-700"
                >
                  Lokasi
                </label>
                <select
                  id="location"
                  name="location"
                  onChange={(e) => setLocation(e.target.value)}
                  value={location}
                  className="mt-1 block w-full p-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-custom-blue focus:border-custom-blue sm:text-sm"
                >
                  {locations?.map((el, i) => (
                    <option key={i} value={el?.id}>
                      {el?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="px-4 py-3 text-right sm:px-6">
              <button
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-blue"
                onClick={onSave}
                disabled={loading}
              >
                {loading ? "Please wait..." : "Save"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CourseScheduleForm;
