import React from "react";

import FirstComponent from "./FirstComponent";
import SecondComponent from "./SecondComponent";

const Search = () => {
  return (
    <div>
      <FirstComponent />
      <SecondComponent />
    </div>
  );
};

export default Search;
