import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import moment from "moment";
import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/outline";
import { useNavigate, useSearchParams } from "react-router-dom";

import format from "../../helpers/format.currency";
import notifications from "../../helpers/notification";

import countriesData from "../../data/countries.json";

import api from "../../plugins/api";

import { getCourses } from "../../store/courses/course.action";
import { getActiveCourseSchedules } from "../../store/course.schedule/course.schedule.action";

import rocketBlue from "../../assets/icons/rocket-blue.svg";
import rocketGray from "../../assets/icons/rocket-gray.svg";
import folderBlue from "../../assets/icons/folder-blue.svg";
import folderGray from "../../assets/icons/folder-gray.svg";

const programTypes = [
  {
    activeIcon: rocketBlue,
    inActiveIcon: rocketGray,
    title: "Bootcamp",
  },
  {
    activeIcon: folderBlue,
    inActiveIcon: folderGray,
    title: "Professional Development",
  },
];

const genders = [
  {
    label: "Laki-laki",
    value: "L",
  },
  {
    label: "Perempuan",
    value: "P",
  },
];

const RegistrationFormMobile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query] = useSearchParams();

  const id = query.get("id");

  const course = useSelector((state) => state.course);
  const schedule = useSelector((state) => state.schedule);

  const [batches, setBatches] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [currentDiscount, setCurrentDiscount] = React.useState("");
  const [priceAfterDiscount, setPriceAfterDiscount] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [loadingVoucher, setLoadingVoucher] = React.useState(false);
  const [validation, setValidation] = React.useState(false);

  const [name, setName] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [bankEmail, setBankEmail] = React.useState("");
  const [voucherCode, setVoucherCode] = React.useState("");
  const [voucherAmount, setVoucherAmount] = React.useState(0);

  const [type, setType] = React.useState(0);
  const [currentProgram, setCurrentProgram] = React.useState("");
  const [currentBatch, setCurrentBatch] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [birthDate, setBirthDate] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [confirmPhoneNumber, setConfirmPhoneNumber] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [motivation, setMotivation] = React.useState("");
  const [termsAndConditions, setTermsAndConditions] = React.useState(false);

  const programs = course?.courses?.rows;
  const schedules = schedule?.activeCourseSchedules?.rows;

  const getCountries = () => {
    setCountries(countriesData);
  };

  React.useEffect(() => {
    dispatch(getCourses(`limit=100&page=1`));
    dispatch(
      getActiveCourseSchedules("limit=1000&page=1&condition=all&type=BOOTCAMP")
    );
    getCountries();
  }, [dispatch]);

  React.useEffect(() => {
    if (id && schedules) {
      onProgramChange(id);
    }
  }, [id, schedules]);

  const onProgramChange = (id) => {
    setCurrentProgram(id);
    const data = programs?.find((el) => el.id === id);

    const discount = data?.discount?.percentage / 100 || 0 / 100;
    const discountAmount = data?.price * discount;
    const discountPrice = Number(data?.price) - discountAmount;

    setCurrentDiscount(discountAmount);
    setPriceAfterDiscount(discountPrice);
    setPrice(data?.price);

    const currentBatches = schedules?.filter((el) => el.course_id === id);

    setBatches(currentBatches);
  };

  const submitChecker = () => {
    if (
      name &&
      price &&
      bankEmail &&
      currentProgram &&
      currentBatch &&
      firstName &&
      lastName &&
      email &&
      gender &&
      birthDate &&
      phoneNumber &&
      confirmPhoneNumber &&
      country &&
      address &&
      city &&
      zipCode &&
      motivation
    ) {
      return true;
    } else {
      setValidation(true);
      return false;
    }
  };

  const onValidateVoucher = (e) => {
    e.preventDefault();
    setLoadingVoucher(true);
    api
      .post("/vouchers/validate", {
        course_id: currentProgram,
        code: voucherCode,
      })
      .then(({ data }) => {
        if (data.data) {
          if (currentProgram) {
            notifications({
              type: "success",
              title: "Success get Voucher",
              message: data.message,
            });
            const currentData = data.data;
            const program = programs?.find((el) => el.id === currentProgram);

            const voucher = currentData?.percentage / 100 || 0 / 100;
            const discountVoucherAmount = Number(program?.price) * voucher;
            const finalVoucherAmount =
              discountVoucherAmount >= Number(currentData?.max)
                ? Number(currentData?.max)
                : discountVoucherAmount;

            setVoucherAmount(finalVoucherAmount);
          } else {
            notifications({
              type: "warning",
              command: "Please select program",
            });
            setVoucherAmount(0);
          }

          setLoadingVoucher(false);
        } else {
          setVoucherAmount(0);
          notifications({
            type: "error",
            command: "Voucher is invalid",
            message: data.message,
          });
        }
      })
      .catch((error) => {
        setVoucherAmount(0);
        setLoadingVoucher(false);
        notifications({ type: "error" });
      });
  };

  const onSubmit = () => {
    const isValid = submitChecker();

    if (isValid) {
      if (termsAndConditions) {
        setLoading(true);

        const payload = {
          name,
          price: Number(priceAfterDiscount) - Number(voucherAmount),
          email: bankEmail,

          course_schedule_id: currentBatch,
          first_name: firstName,
          last_name: lastName,
          email_student: email,
          gender,
          birth_date: new Date(birthDate),
          phone_number: phoneNumber,
          country,
          address,
          city,
          zip_code: zipCode,
          motivation,
        };

        if (payload.price === 0) {
          api
            .post("/xendit/create-free-transaction", payload)
            .then(({ data: { data } }) => {
              setLoading(false);
              notifications({
                type: "success",
                title: "Berhasil melakukan order",
              });
              resetForm();
              navigate(`/success-payment?id=${data.transaction.id}`);
            })
            .catch(() => {
              setLoading(false);
              notifications({ type: "error" });
            });
        } else {
          api
            .post("/xendit/create-invoice", payload)
            .then(({ data: { data } }) => {
              setLoading(false);
              notifications({
                type: "success",
                title: "Berhasil melakukan order",
              });
              resetForm();
              window.open(data?.invoice?.invoice_url, "_self");
            })
            .catch(() => {
              setLoading(false);
              notifications({ type: "error" });
            });
        }
      }
    }
  };

  const resetForm = () => {
    setValidation(false);

    setName("");
    setPrice("");
    setBankEmail("");

    setType(0);
    setCurrentProgram("");
    setCurrentBatch("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setGender("");
    setBirthDate("");
    setPhoneNumber("");
    setConfirmPhoneNumber("");
    setCountry("");
    setAddress("");
    setCity("");
    setZipCode("");
    setMotivation("");
    setTermsAndConditions(false);
  };

  return (
    <div className="mt-60px w-5/6 shadow-lg p-6 rounded-3xl border border-gray-200 bg-white">
      <h2 className="font-bold">Pilihan Program dan Batch</h2>
      <label className="block text-sm font-medium text-gray-700 mt-3">
        Program yang ingin Diambil
      </label>
      <div className="grid grid-cols-2 gap-2 mt-3">
        {programTypes?.map((el, i) => (
          <div
            key={i}
            onClick={() => setType(i)}
            className={classnames(
              type === i ? "border-custom-blue" : "border-gray-300",
              "border-2 p-5 rounded-xl cursor-pointer"
            )}
          >
            <div className="flex flex-col gap-y-5">
              {type === i ? (
                <img src={el.activeIcon} width={42} />
              ) : (
                <img src={el.inActiveIcon} width={42} />
              )}
              <div>
                <p
                  className={classnames(
                    type === i ? "text-custom-blue" : "text-gray-300",
                    "font-bold text-sm h-42px"
                  )}
                >
                  {el.title}
                </p>
                <p
                  className={classnames(
                    type === i ? "text-gray-500" : "text-gray-300",
                    "text-12px pt-1"
                  )}
                >
                  Kuasai skill pemrograman dalam waktu singkat.
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-3">
        <label
          htmlFor="currentProgram"
          className="block text-sm font-medium text-gray-700"
        >
          Pilih Program
        </label>
        <select
          id="currentProgram"
          name="currentProgram"
          className="mt-3 block w-full px-5 py-2 border border-gray-300 bg-white rounded-full shadow-sm focus:outline-none focus:ring-custom-blue focus:border-custom-blue sm:text-sm"
          onChange={(e) => onProgramChange(e.target.value)}
          value={currentProgram}
        >
          <option value="" disabled>
            Pilih Program
          </option>
          {programs?.map((el, i) => (
            <option key={i} value={el.id}>
              {el.title}
            </option>
          ))}
        </select>
        {validation && !currentProgram && (
          <div className="text-red-500 text-10px mt-1 text-right">
            Wajib diisi
          </div>
        )}
      </div>

      <div className="mt-3">
        <label
          htmlFor="currentBatch"
          className="block text-sm font-medium text-gray-700"
        >
          Pilih Batch
        </label>
        <select
          id="currentBatch"
          name="currentBatch"
          className="mt-3 block w-full px-5 py-2 border border-gray-300 bg-white rounded-full shadow-sm focus:outline-none focus:ring-custom-blue focus:border-custom-blue sm:text-sm"
          onChange={(e) => setCurrentBatch(e.target.value)}
          value={currentBatch}
        >
          <option value="" disabled>
            Pilih Batch
          </option>
          {batches?.map((el, i) => (
            <option key={i} value={el.id}>
              {`Batch ${el.batch} - ${moment(el.start_date).format(
                "DD MMMM YYYY"
              )}`}
            </option>
          ))}
        </select>
        {validation && !currentBatch && (
          <div className="text-red-500 text-10px mt-1 text-right">
            Wajib diisi
          </div>
        )}
      </div>

      <h2 className="font-bold mt-10">Data Pembayaran</h2>
      <label className="block text-sm font-medium text-gray-700 mt-3">
        Invoice Pembayaran
      </label>

      <div className="mt-3">
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          Nama Lengkap
        </label>
        <div className="mt-3">
          <input
            name="name"
            placeholder="Nama Lengkap Pengirim"
            onChange={(e) => setName(e.target.value)}
            value={name}
            className="px-5 py-2 shadow-sm focus:ring-custom-blue focus:border-custom-blue block w-full sm:text-sm border border-gray-300 rounded-full placeholder-gray-300"
          />
        </div>
        {validation && !name && (
          <div className="text-red-500 text-10px mt-1 text-right">
            Wajib diisi
          </div>
        )}
      </div>

      <div className="mt-3">
        <label
          htmlFor="bankEmail"
          className="block text-sm font-medium text-gray-700"
        >
          Email
        </label>
        <div className="mt-3">
          <input
            name="bankEmail"
            placeholder="Email Pengirim"
            type="email"
            onChange={(e) => setBankEmail(e.target.value)}
            value={bankEmail}
            className="px-5 py-2 shadow-sm focus:ring-custom-blue focus:border-custom-blue block w-full sm:text-sm border border-gray-300 rounded-full placeholder-gray-300"
          />
        </div>
        {validation && !bankEmail && (
          <div className="text-red-500 text-10px mt-1 text-right">
            Wajib diisi
          </div>
        )}
      </div>

      {Number(price) !== 0 && (
        <div className="mt-3">
          <label
            htmlFor="voucherCode"
            className="block text-sm font-medium text-gray-700"
          >
            Kode Voucher
          </label>
          <div className="mt-3 flex flex-col gap-y-3">
            <input
              name="voucherCode"
              placeholder="Masukkan kode voucher di sini"
              type="text"
              onChange={(e) => {
                setVoucherCode(e.target.value);
                setVoucherAmount(0);
              }}
              value={voucherCode}
              className="px-5 py-2 shadow-sm focus:ring-custom-blue focus:border-custom-blue block w-full sm:text-sm border border-gray-300 rounded-full placeholder-gray-300"
            />
            <div>
              <button
                type="button"
                className="disabled:bg-gray-100 disabled:text-custom-blue rounded-full group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-custom-blue hover:bg-custom-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-blue"
                onClick={onValidateVoucher}
                disabled={loadingVoucher}
              >
                {loadingVoucher ? "Checking..." : "Check Voucher"}
              </button>
            </div>
          </div>
        </div>
      )}

      {Number(price) !== 0 ? (
        <>
          <div className="mt-5 flex gap-x-2 items-center">
            <p>Harga: </p>
            <p className="text-base font-bold text-gray-600">
              {`IDR ${format(price)}`}
            </p>
          </div>
          <div className="mt-1 flex gap-x-2 items-center">
            <p>Discount: </p>
            <p className="text-base font-bold text-gray-600">
              {`IDR ${format(currentDiscount)}`}
            </p>
          </div>
          <div className="mt-1 flex gap-x-2 items-center">
            <p>Voucher Discount: </p>
            <p className="text-base font-bold text-gray-600">
              {`IDR ${format(voucherAmount)}`}
            </p>
          </div>
          <div className="mt-1 flex gap-x-2 items-center">
            <p className="text-sm">Total Pembayaran: </p>
            <p className="text-lg font-bold text-custom-blue">{`IDR ${format(
              priceAfterDiscount - voucherAmount
            )}`}</p>
          </div>
        </>
      ) : (
        <div className="mt-5 flex gap-x-2 items-center">
          <p>Harga: </p>
          <p className="text-base font-bold text-gray-600">GRATIS</p>
        </div>
      )}

      <h2 className="font-bold mt-10">Tentang Calon Siswa</h2>
      <div className="mt-3">
        <label
          htmlFor="firstName"
          className="block text-sm font-medium text-gray-700"
        >
          Nama Depan
        </label>
        <div className="mt-3">
          <input
            name="firstName"
            placeholder="Name Depan"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            className="px-5 py-2 shadow-sm focus:ring-custom-blue focus:border-custom-blue block w-full sm:text-sm border border-gray-300 rounded-full placeholder-gray-300"
          />
        </div>
        {validation && !firstName && (
          <div className="text-red-500 text-10px mt-1 text-right">
            Wajib diisi
          </div>
        )}
      </div>

      <div className="mt-3">
        <label
          htmlFor="lastName"
          className="block text-sm font-medium text-gray-700"
        >
          Nama Belakang
        </label>
        <div className="mt-3">
          <input
            name="lastName"
            placeholder="Name Belakang"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            className="px-5 py-2 shadow-sm focus:ring-custom-blue focus:border-custom-blue block w-full sm:text-sm border border-gray-300 rounded-full placeholder-gray-300"
          />
        </div>
        {validation && !lastName && (
          <div className="text-red-500 text-10px mt-1 text-right">
            Wajib diisi
          </div>
        )}
      </div>

      <div className="mt-3">
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          Email
        </label>
        <div className="mt-3">
          <input
            name="email"
            type="email"
            placeholder="Alamat Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            className="px-5 py-2 shadow-sm focus:ring-custom-blue focus:border-custom-blue block w-full sm:text-sm border border-gray-300 rounded-full placeholder-gray-300"
          />
        </div>
        {validation && !email && (
          <div className="text-red-500 text-10px mt-1 text-right">
            Wajib diisi
          </div>
        )}
      </div>

      <div className="mt-3">
        <label
          htmlFor="gender"
          className="block text-sm font-medium text-gray-700"
        >
          Jenis Kelamin
        </label>
        <select
          id="gender"
          name="gender"
          className="mt-3 block w-full px-5 py-2 border border-gray-300 bg-white rounded-full shadow-sm focus:outline-none focus:ring-custom-blue focus:border-custom-blue sm:text-sm"
          onChange={(e) => setGender(e.target.value)}
          value={gender}
        >
          <option value="" disabled>
            Pilih Jenis Kelamin
          </option>
          {genders?.map((el, i) => (
            <option key={i} value={el.value}>
              {el.label}
            </option>
          ))}
        </select>
        {validation && !gender && (
          <div className="text-red-500 text-10px mt-1 text-right">
            Wajib diisi
          </div>
        )}
      </div>

      <div className="mt-3">
        <label
          htmlFor="birthDate"
          className="block text-sm font-medium text-gray-700"
        >
          Tanggal Lahir
        </label>
        <div className="mt-3">
          <input
            name="birthDate"
            type="date"
            onChange={(e) => setBirthDate(e.target.value)}
            value={birthDate}
            className="px-5 py-2 shadow-sm focus:ring-custom-blue focus:border-custom-blue block w-full sm:text-sm border border-gray-300 rounded-full placeholder-gray-300"
          />
        </div>
        {validation && !birthDate && (
          <div className="text-red-500 text-10px mt-1 text-right">
            Wajib diisi
          </div>
        )}
      </div>

      <div className="mt-3">
        <label
          htmlFor="phoneNumber"
          className="block text-sm font-medium text-gray-700"
        >
          No Handphone
        </label>
        <div className="mt-3">
          <input
            name="phoneNumber"
            placeholder="+62"
            onChange={(e) => setPhoneNumber(e.target.value)}
            value={phoneNumber}
            className="px-5 py-2 shadow-sm focus:ring-custom-blue focus:border-custom-blue block w-full sm:text-sm border border-gray-300 rounded-full placeholder-gray-300"
          />
        </div>
        {validation && !phoneNumber && (
          <div className="text-red-500 text-10px mt-1 text-right">
            Wajib diisi
          </div>
        )}
      </div>

      <div className="mt-3">
        <label
          htmlFor="confirmPhoneNumber"
          className="block text-sm font-medium text-gray-700"
        >
          Konfirmasi No Handphone
        </label>
        <div className="mt-3">
          <input
            name="confirmPhoneNumber"
            placeholder="+62"
            onChange={(e) => setConfirmPhoneNumber(e.target.value)}
            value={confirmPhoneNumber}
            className="px-5 py-2 shadow-sm focus:ring-custom-blue focus:border-custom-blue block w-full sm:text-sm border border-gray-300 rounded-full placeholder-gray-300"
          />
        </div>
        {phoneNumber !== confirmPhoneNumber &&
          confirmPhoneNumber.length > 2 && (
            <div className="text-red-500 text-10px mt-1 text-right">
              No Handphone Tidak Sama
            </div>
          )}
        {validation && !confirmPhoneNumber && (
          <div className="text-red-500 text-10px mt-1 text-right">
            Wajib diisi
          </div>
        )}
      </div>

      <div className="mt-3">
        <label
          htmlFor="country"
          className="block text-sm font-medium text-gray-700"
        >
          Pilih Negara
        </label>
        <select
          id="country"
          name="country"
          className="mt-3 block w-full px-5 py-2 border border-gray-300 bg-white rounded-full shadow-sm focus:outline-none focus:ring-custom-blue focus:border-custom-blue sm:text-sm"
          onChange={(e) => setCountry(e.target.value)}
          value={country}
        >
          <option value="" disabled>
            Pilih Negara
          </option>
          {countries?.map((el, i) => (
            <option key={i} value={el.name}>
              {el.name}
            </option>
          ))}
        </select>
        {validation && !country && (
          <div className="text-red-500 text-10px mt-1 text-right">
            Wajib diisi
          </div>
        )}
      </div>

      <div className="mt-3">
        <label
          htmlFor="address"
          className="block text-sm font-medium text-gray-700"
        >
          Alamat Lengkap
        </label>
        <div className="mt-1">
          <textarea
            name="address"
            placeholder="Alamat Lengkap Calon Siswa"
            onChange={(e) => setAddress(e.target.value)}
            value={address}
            rows={5}
            className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-3xl placeholder-gray-300"
          />
        </div>
        {validation && !address && (
          <div className="text-red-500 text-10px mt-1 text-right">
            Wajib diisi
          </div>
        )}
      </div>

      <div className="mt-3">
        <label
          htmlFor="city"
          className="block text-sm font-medium text-gray-700"
        >
          Kota
        </label>
        <div className="mt-3">
          <input
            name="city"
            placeholder="Kota Tempat Tinggal"
            onChange={(e) => setCity(e.target.value)}
            value={city}
            className="px-5 py-2 shadow-sm focus:ring-custom-blue focus:border-custom-blue block w-full sm:text-sm border border-gray-300 rounded-full placeholder-gray-300"
          />
        </div>
        {validation && !city && (
          <div className="text-red-500 text-10px mt-1 text-right">
            Wajib diisi
          </div>
        )}
      </div>

      <div className="mt-3">
        <label
          htmlFor="zipCode"
          className="block text-sm font-medium text-gray-700"
        >
          Kode Pos
        </label>
        <div className="mt-3">
          <input
            name="zipCode"
            placeholder="Kode Pos"
            onChange={(e) => setZipCode(e.target.value)}
            value={zipCode}
            className="px-5 py-2 shadow-sm focus:ring-custom-blue focus:border-custom-blue block w-full sm:text-sm border border-gray-300 rounded-full placeholder-gray-300"
          />
        </div>
        {validation && !zipCode && (
          <div className="text-red-500 text-10px mt-1 text-right">
            Wajib diisi
          </div>
        )}
      </div>

      <div className="mt-3">
        <label
          htmlFor="motivation"
          className="block text-sm font-medium text-gray-700"
        >
          Motivasi Mengikuti Program Ini
        </label>
        <div className="mt-3">
          <input
            name="motivation"
            placeholder="Motivasi Kamu"
            onChange={(e) => setMotivation(e.target.value)}
            value={motivation}
            className="px-5 py-2 shadow-sm focus:ring-custom-blue focus:border-custom-blue block w-full sm:text-sm border border-gray-300 rounded-full placeholder-gray-300"
          />
        </div>
        {validation && !motivation && (
          <div className="text-red-500 text-10px mt-1 text-right">
            Wajib diisi
          </div>
        )}
      </div>

      <div className="mt-10">
        <div className="flex items-center">
          <input
            id="remember-me"
            name="remember-me"
            type="checkbox"
            checked={termsAndConditions}
            onChange={() => setTermsAndConditions(!termsAndConditions)}
            className="h-4 w-4 text-custom-blue focus:ring-custom-blue border-gray-300 rounded"
          />
          <label
            htmlFor="remember-me"
            className="ml-2 block text-12px text-gray-900"
          >
            Dengan mengisi formulir ini saya menyetujui{" "}
            <a
              href="#"
              rel="noopener noreferrer"
              className="underline italic font-bold"
            >
              Syarat dan Ketentuan
            </a>{" "}
            untuk mengikuti program yang saya daftarkan.
          </label>
        </div>
        {validation && !termsAndConditions && (
          <div className="text-red-500 text-10px mt-1">Wajib diceklis</div>
        )}
      </div>

      <div className="w-full mt-5">
        <button
          type="submit"
          className="disabled:bg-gray-100 disabled:text-custom-blue rounded-full group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-custom-blue hover:bg-custom-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-blue"
          onClick={onSubmit}
          disabled={loading}
        >
          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
            {loading ? (
              <LockOpenIcon
                className={classnames(
                  loading ? "animate-ping" : "",
                  "h-5 w-5 text-custom-blue group-hover:text-custom-blue"
                )}
                aria-hidden="true"
              />
            ) : (
              <LockClosedIcon
                className="h-5 w-5 text-custom-blue group-hover:text-custom-blue"
                aria-hidden="true"
              />
            )}
          </span>
          Kirim Formulir
        </button>
      </div>
    </div>
  );
};

export default RegistrationFormMobile;
