import React from "react";

import image from "../../constants/image";

const FirstComponentMobile = () => {
  return (
    <div className="sm:hidden">
      <div className="flex flex-col items-center mx-auto w-4/5 md:w-3/5 py-10">
        <div className="flex flex-col gap-y-5">
          <h1 className="font-bold w-full text-5xl text-custom-blue">
            Grow your career with our top-rated programs.
          </h1>
          <p className="w-full text-custom-blue text-lg">
            Learn digital & tech skills with our comprehensive curriculum and
            our expert trainers.
          </p>
        </div>
      </div>
      <div>
        <div
          className="h-280px bg-cover bg-no-repeat bg-color-gray-500"
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(60, 60, 60, 0.4), rgba(60, 60, 60, 0.4)), url(${image.background})`,
          }}
          alt=""
        />
      </div>
    </div>
  );
};

export default FirstComponentMobile;
