import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import Sidebar from "../../../components/Sidebar";
import Button from "../../../components/Button";

import { getCorporateNeeds } from "../../../store/corporate.need/corporate.need.action";

import CorporateNeedTable from "./CorporateNeedTable";

const CorporateNeed = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { corporateNeeds } = useSelector((state) => state.corporate);
  const [limit, setLimit] = React.useState(5);
  const count = corporateNeeds?.count;

  React.useEffect(() => {
    dispatch(getCorporateNeeds(`limit=${limit}&page=1`));
  }, [dispatch]);

  const loadMore = () => {
    dispatch(getCorporateNeeds(`limit=${limit + 5}&page=1`));
    setLimit(limit + 5);
  };

  if (!user.user_token) {
    return <Navigate to="/" />;
  }
  return (
    <div className="relative min-h-screen md:flex">
      <Sidebar />
      <div className="flex-1 p-10 text-2xl font-bold">
        <CorporateNeedTable corporateNeeds={corporateNeeds} />
        {count > limit && (
          <div className="flex justify-center pt-5">
            <div className="w-120px">
              <Button
                onClick={loadMore}
                className=""
                title="Load more"
                className="rounded-md"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CorporateNeed;
