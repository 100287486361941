import React from "react";
import Slider from "react-slick";
import classames from "classnames";

import review from "../constants/review";

import ArrowButton from "./ArrowButton";
import ReviewCard from "./ReviewCard";

const Review = () => {
  const customeSlider = React.useRef();

  const [currentSlide, setCurrentSlide] = React.useState(1);

  const gotoNext = () => {
    customeSlider.current.slickNext();
  };

  const gotoPrev = () => {
    customeSlider.current.slickPrev();
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
    beforeChange: (_, current) => setCurrentSlide((current += 1)),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const data = [
    {
      photo: review.review1,
      name: "Achmad Syah Hidayat",
      job: "Digital QA Specialist",
      review:
        "Menambah knowledge dalam hal testing Instruktur menjelaskan dengan detail materinya Jika masih terkendala, dibantu sampai remote sampai diterangkan dengan detail.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
    },
    {
      photo: review.review2,
      name: "Prasta Muhtadin",
      job: "Software Quality Assurance",
      review:
        "Materi yang diajarkan sangat lengkap dan praktikal, penjelasan yang diajarkan di kelas juga cukup mudah untuk dipahami, trainernya juga sangat baik.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut ",
    },
    {
      photo: review.review3,
      name: "Nanda Manarfa Az Zahra",
      job: "Staf Dokumentasi dan Informasi Hukum",
      review:
        "Kesannya sangat bagus, seru dan menantang untuk mempelajari QA Automation ini, pematerinya selalu sabar mengajarkan terkait dengan QA Automation ini.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      photo: review.review4,
      name: "Marco Ichwan Bosch",
      job: "Senior Software Quality Analyst",
      review:
        "Materinya berbobot, semua materi yg diajarkan sangat dibutuhkan untuk jadi Full Stack QA Automation. Kualitas Instrukturnya sangat bagus dan menguasai QA",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      photo: "",
      name: "Muhammad Thoriq Hafiz",
      job: "IT Security Server and Network",
      review:
        "Pelajarannya sesuai yang diharapkan karena mengenai fundamentalnya terlebih dahulu, sangat membantu memahami dunia cyber security untuk pemula.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      photo: "",
      name: "Amanda Cahya Ristia Putri",
      job: "Quality Assurance Engineer",
      review:
        "Workshop nya so far bagus. Worth it, sesuai dengan harga yang dibayarkan. Ketika beberapa kali tertinggal, Instruktur tetap membimbing saya.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      photo: review.review7,
      name: "Fahri Ahmad Fadil",
      job: "Fullstack Developer",
      review:
        "Jadi lebih mengerti bagaimana membuat aplikasi web lebih efisien dan terstruktur. Pengajarnya juga baik dan enjoy dalam mengajar ilmunya jadi mudah dipahami.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      photo: "",
      name: "Sopan Mukti",
      job: "IT Support",
      review:
        "Untuk kursus ini, Saya sangat senang dan merasakan bertambah ilmu saya dan pengetahuan saya terutama di React Js nya. Terima Kasih JakartaLabs❤️",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];

  const length = data?.length;

  return (
    <div
      style={{
        paddingTop: 60,
        paddingBottom: 60,
        borderTopRightRadius: 120,
        backgroundColor: "#F8F8F8",
      }}
      className="flex flex-col items-center gap-y-10"
    >
      <h1 className="w-4/6 sm:w-4/5 2xl:w-3/5 text-center text-custom-blue font-bold text-2xl sm:text-4xl">
        What did they say about our programs?
      </h1>
      <div className="w-full flex items-center justify-center">
        {currentSlide + 2 > 3 && (
          <div className="hidden sm:flex w-1/10 justify-end pr-5">
            <ArrowButton onClick={gotoPrev} type="prev" />
          </div>
        )}
        {currentSlide > 1 && (
          <div className="flex sm:hidden w-1/10 justify-end pr-5">
            <ArrowButton onClick={gotoPrev} type="prev" />
          </div>
        )}
        <div className="w-4/6 sm:w-4/5 2xl:w-3/5">
          <Slider ref={customeSlider} {...settings}>
            {data?.map((el, i) => (
              <div key={i}>
                <div className="ml-1 mr-6 py-3">
                  <ReviewCard value={el} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
        {length !== currentSlide && (
          <>
            {length > 1 && (
              <div
                className={classames(
                  length > 3 && length - 2 !== currentSlide
                    ? "sm:flex"
                    : "sm:hidden",
                  "w-1/10 justify-start pl-5"
                )}
              >
                <ArrowButton onClick={gotoNext} type="next" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Review;
