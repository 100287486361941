import React from "react";
import { NavLink } from "react-router-dom";

import cash from "../../assets/icons/cash.svg";
import calculator from "../../assets/icons/calculator.svg";
import chart from "../../assets/icons/chart.svg";

import Button from "../../components/Button";

const EighthComponent = () => {
  const waLink = "https://wa.me/6288908892612?text=I%20want%20to%20talk%20to%20advisor%20of%20Jakartalabs%20about%20Financial%20Partners"; // prettier-ignore

  const data = [
    {
      title: "Upfront",
      buttonTitle: "Daftar sekarang",
      url: "/registration",
      icon: <img src={cash} width={32} />,
      description:
        "Pembayaran FULL dilakukan di depan, maksimal 1 minggu sebelum kelas dimulai.",
    },
    {
      title: "Cicilan 0%",
      buttonTitle: "Daftar sekarang",
      url: "/registration",
      icon: <img src={calculator} width={32} />,
      description: "Pembayaran melalui cicilan maksimal dalam 3 tahapan.",
    },
    {
      title: "Financial Partners",
      buttonTitle: "Info lanjut",
      url: "/financial-partner",
      icon: <img src={chart} width={32} />,
      description:
        "Pembayaran dengan partners financial JakartaLabs yang sudah terdaftar dan diawasi oleh OJK.",
    },
  ];

  return (
    <div className="flex flex-col bg-custom-blue items-center gap-y-10 pb-60px pt-60px">
      <h1 className="text-white font-bold text-2xl sm:text-4xl text-center w-4/6 sm:w-4/5">
        Metode Pembayaran
      </h1>
      <div className="w-full flex items-center justify-center flex-col gap-y-10">
        <div className="w-4/6 sm:w-4/5 2xl:w-3/5 grid sm:grid-cols-3 grid-cols-1 gap-10">
          {data?.map((el, i) => (
            <div key={i} className="p-6 bg-white rounded-lg shadow-md">
              {el.icon}
              <p className="font-bold pb-5 pt-1">{el.title}</p>
              <p className="text-12px sm:h-64px">{el.description}</p>
              <div className="sm:w-200px pt-5">
                <NavLink to={el.url}>
                  <Button
                    title={el.buttonTitle.toUpperCase()}
                    className="rounded-full font-bold"
                    textSize="text-12px"
                  />
                </NavLink>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EighthComponent;
