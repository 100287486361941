import React from "react";
import { NavLink } from "react-router-dom";

import ButtonArrow from "../../components/ButtonArrow";

const TenthComponent = () => {
  return (
    <div className="flex flex-col bg-white items-center gap-y-10 sm:pb-60px sm:pt-30px">
      <div className="w-5/6 sm:w-4/5 2xl:w-3/5 flex flex-col justify-between items-center gap-y-5 bg-custom-blue py-60px rounded-lg shadow-lg">
        <h1 className="text-white font-bold text-2xl sm:text-4xl text-center">
          Jumpstart Karir Kamu di Industri High-Tech
        </h1>
        <div className="flex flex-col gap-y-8 sm:w-420px w-5/6">
          <p className="text-white text-12px text-center">
            Waktunya meraih mimpi bekerja di bidang teknologi, dengan mulai
            belajar dan mengasah skill bersama JakartaLabs.
          </p>
          <div className="w-180px self-center">
            <NavLink to="/registration">
              <ButtonArrow
                title="Daftar sekarang"
                color="bg-white text-custom-blue"
              />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenthComponent;
