import React from "react";

import FloatingWhatsappButton from "../../components/FloatingWhatsappButton";
import EightComponent from "./EightComponent";
import FifthComponent from "./FifthComponent";

import FirstComponent from "./FirstComponent";
import FirstComponentMobile from "./FirstComponentMobile";
import ForthComponent from "./ForthComponent";
import SecondComponent from "./SecondComponent";
import SeventhComponent from "./SeventhComponent";
import SixthComponent from "./SixthComponent";
import ThirdComponent from "./ThirdComponent";

const IncomeShareAgreement = () => {
  const waLink = "https://wa.me/6288908892612?text=I%20want%20to%20talk%20to%20advisor%20of%20Jakartalabs"; // prettier-ignore

  return (
    <div>
      <FloatingWhatsappButton />
      <FirstComponent waLink={waLink} />
      <FirstComponentMobile waLink={waLink} />
      <SecondComponent />
      <ThirdComponent waLink={waLink} />
      <ForthComponent />
      <FifthComponent />
      <SixthComponent waLink={waLink} />
      <SeventhComponent waLink={waLink} />
      <EightComponent />
    </div>
  );
};

export default IncomeShareAgreement;
