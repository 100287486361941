import React from "react";
import icon from "../../constants/icon";

const ForthComponent = () => {
  const benefits = [
    {
      title: "Fokus Belajar bukan Pada Biaya",
      description:
        "Siswa dapat mengikuti proses belajar hanya dengan membayar fase preparation. Pelunasan program setelah siswa lulus dan bekerja.",
      icon: icon.calculatorRound,
    },
    {
      title: "Fasilitas Penempatan Kerja",
      description:
        "Setelah lulus, kami akan memberikan fasilitas penempatan dengan menyalurkan kamu ke 250+ Hiring Partner kami.",
      icon: icon.building,
    },
    {
      title: "Sistem Pembayaran Fleksibel",
      description:
        "Sistem pembayaran fleksibel. Kami tidak akan melakukan penagihan bila kamu belum memiliki pendapatan.",
      icon: icon.infinity,
    },
    {
      title: "Batas Maksimal Pembayaran",
      description:
        "Total maksimal pembayaran yang siswa bagikan kepada JakartaLabs tidak lebih dari 1.5x harga normal program reguler.",
      icon: icon.arrowFromBottom,
    },
  ];

  return (
    <div
      style={{ backgroundColor: "#F8F8F8" }}
      className="flex flex-col items-center gap-y-10 py-30px sm:py-80px"
    >
      <div className="w-5/6 sm:w-3/5 2xl:w-3/5 flex flex-col">
        <h1 className="text-custom-blue text-5xl font-bold sm:text-center">
          Keunggulan Program ISA
        </h1>
        <div className="sm:w-3/5 flex flex-col gap-y-10 self-center mt-10">
          {benefits.map((el, i) => (
            <div key={i} className="flex gap-x-5 items-center">
              <div>
                <div className="bg-white px-1 py-2 shadow-lg rounded-lg">
                  <img src={el.icon} alt="" width={56} />
                </div>
              </div>
              <div>
                <p className="text-custom-blue font-bold text-md leading-10">
                  {el.title}
                </p>
                <p className="text-gray-500 text-xs leading-6">
                  {el.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ForthComponent;
