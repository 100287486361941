import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import classnames from "classnames";

import { getActiveEventsAndSchedules } from "../store/course.schedule/course.schedule.action";

import ArrowButton from "./ArrowButton";
import EventCard from "./EventCard";

const Event = () => {
  const dispatch = useDispatch();
  const customeSlider = React.useRef();

  const [currentSlide, setCurrentSlide] = React.useState(1);

  const gotoNext = () => {
    customeSlider.current.slickNext();
  };

  const gotoPrev = () => {
    customeSlider.current.slickPrev();
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
    beforeChange: (_, current) => setCurrentSlide((current += 1)),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const schedule = useSelector((state) => state.schedule);

  React.useEffect(() => {
    dispatch(
      getActiveEventsAndSchedules(
        "limit=100&page=1&condition=all"
      )
    );
  }, [dispatch]);

  const data = schedule?.activeEventsAndWorkshops?.rows;
  const length = schedule?.activeEventsAndWorkshops?.count;

  if (length === 0) {
    return <div />;
  }

  return (
    <div
      style={{ paddingTop: 60, paddingBottom: 60}}
      className="flex flex-col items-center gap-y-10"
    >
      <h1 className="text-custom-blue font-bold text-2xl sm:text-4xl pr-4 pl-4">
        Upcoming Workshops & Events
      </h1>
      <div className="w-full flex items-center justify-center">
        {currentSlide + 2 > 3 && (
          <div className="hidden sm:flex w-1/10 justify-end pr-5">
            <ArrowButton onClick={gotoPrev} type="prev" />
          </div>
        )}
        {currentSlide > 1 && (
          <div className="flex sm:hidden w-1/10 justify-end pr-5">
            <ArrowButton onClick={gotoPrev} type="prev" />
          </div>
        )}
        <div className="w-4/6 sm:w-4/5 2xl:w-3/5">
          <Slider ref={customeSlider} {...settings}>
            {data?.map((el, i) => (
              <div key={i}>
                <div className="ml-1 mr-6 pb-10">
                  <EventCard value={el} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
        {length !== currentSlide && (
          <>
            {length > 1 && (
              <div
                className={classnames(
                  length > 3 && length - 2 !== currentSlide
                    ? "sm:flex"
                    : "sm:hidden",
                  "w-1/10 justify-start pl-5"
                )}
              >
                <ArrowButton onClick={gotoNext} type="next" />
              </div>
            )}
          </>
        )}
      </div>
      {/* <div>
        <ButtonOutline
          title="Explore All Events and Workshops"
          className="rounded-md border-black font-bold text-sm sm:text-base"
        />
      </div> */}
    </div>
  );
};

export default Event;
