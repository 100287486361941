import React from "react";

import review from "../constants/review";

const ReviewCard = ({ value }) => {
  return (
    <div>
      <div className="shadow-md border rounded-xl mt-1 bg-white">
        <div>
          <p className="font-bold sm:h-240px px-5 pt-5 pb-3 text-16px sm:text-18px text-custom-blue">
            {value.review}
          </p>
        </div>
        {/* <div>
          <p className="px-5 h-100px sm:h-105px pb-5 text-12px sm:text-14px text-gray-600">
            {value.description}
          </p>
        </div> */}
      </div>
      <div className="py-2">
        <div className="py-2 flex items-center gap-x-3">
          <div
            style={{
              backgroundImage: `linear-gradient(0deg, rgba(60, 60, 60, 0.4), rgba(60, 60, 60, 0.4)), url(${
                value.photo || review.reviewDefault
              })`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundColor: "grey",
              backgroundPosition: "center",
              height: 46,
              width: 46,
            }}
            className="flex w-1/6 items-end justify-start rounded-full shadow-md border"
          />
          <div className="flex flex-col gap-y-1">
            <p
              style={{ fontSize: 14 }}
              className="leading-none font-bold text-custom-blue"
            >
              {value.name}
            </p>
            <p style={{ fontSize: 12 }} className="leading-none text-gray-600">
              {value.job}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
