import React from "react";

import image from "../../constants/image";

const SecondComponent = () => {
  return (
    <div className="flex flex-col bg-white items-center gap-y-10 py-30px sm:py-80px">
      <div className="w-5/6 sm:w-3/5 2xl:w-3/5 flex flex-col sm:flex-row justify-center items-center sm:gap-x-16 gap-y-5">
        <div>
          <img className="rounded-2xl w-320px" src={image.isaBg2} alt="isa-2" />
        </div>
        <div className="w-full sm:w-1/2">
          <h1 className="font-bold w-full text-5xl text-custom-blue">
            Income Share Agreement
          </h1>
          <p className="text-14px leading-6 mt-5 text-gray-500">
            ISA (Income Share Aggrement) adalah perjanjian bagi hasil yang
            memungkinkan siswa belajar haya dengan membayar Refundable Deposit 5
            Juta untuk Full Stack JavaScript Immersive, dan deposit 5 Juta untuk
            Bootcamp Data Science.
          </p>
          <ul className="list-disc list-inside mt-5 text-custom-blue font-bold text-lg">
            <li>Hanya Bayar Refundable Deposit.</li>
            <li>Tanpa Denda Atau Bunga.</li>
            <li>Fasilitas Penempatan Kerja.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SecondComponent;
