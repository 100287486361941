import React from "react";
import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/solid";
import classnames from "classnames";

import axios from "../../plugins/api";

import notifications from "../../helpers/notification";

const FirstComponentMobileForm = ({ programs }) => {
  const [loading, setLoading] = React.useState(false);
  const [validation, setValidation] = React.useState(false);

  const [companyName, setCompanyName] = React.useState("");
  const [picName, setPicName] = React.useState("");
  const [picEmail, setPicEmail] = React.useState("");
  const [picWhatsapp, setPicWhatsapp] = React.useState("");
  const [program, setProgram] = React.useState("");
  const [participantAmount, setParticipantAmount] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [termsAndConditions, setTermsAndConditions] = React.useState(false);

  const submitChecker = () => {
    if (
      companyName &&
      picName &&
      picEmail &&
      participantAmount
    ) {
      return true;
    } else {
      setValidation(true);
      return false;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const isValid = submitChecker();

    if (isValid) {
      if (termsAndConditions) {
        setLoading(true);
        const payload = {
          company_name: companyName,
          pic_name: picName,
          pic_email: picEmail,
          pic_whatsapp: picWhatsapp,
          course_id: program,
          participant_amount: Number(participantAmount),
          notes,
        };

        axios
          .post("/corporate-needs/create", payload)
          .then(() => {
            resetFields();
            notifications({
              type: "success",
              title: "Data Sent!",
              message: "Thank You, We will reach You soon!",
            });
          })
          .catch(() => {
            setLoading(false);
            notifications({ type: "error" });
          });
      }
    }
  };

  const resetFields = () => {
    setLoading(false);
    setCompanyName("");
    setPicName("");
    setPicEmail("");
    setPicWhatsapp("");
    setProgram("");
    setParticipantAmount("");
    setNotes("");
    setTermsAndConditions(false);
  };

  return (
    <div style={{ height: 960 }} className="relative flex justify-center">
      <div
        style={{ top: -80 }}
        className="absolute w-4/5 bg-white rounded-md shadow-lg border border-gray-200"
      >
        <form>
          <div className="px-4 py-5 space-y-6 sm:p-6">
            <div>
              <label
                htmlFor="companyName"
                className="block text-sm font-medium text-gray-700"
              >
                Nama Perusahaan
              </label>
              <div className="mt-3">
                <input
                  name="companyName"
                  placeholder="Nama Perusahaan"
                  onChange={(e) => setCompanyName(e.target.value)}
                  value={companyName}
                  className="placeholder-gray-300 p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                />
              </div>
              {validation && !companyName && (
                <div className="text-red-500 text-10px mt-1 text-right">
                  Wajib diisi
                </div>
              )}
            </div>

            <div>
              <label
                htmlFor="picName"
                className="block text-sm font-medium text-gray-700"
              >
                Nama PIC
              </label>
              <div className="mt-3">
                <input
                  name="picName"
                  placeholder="Nama PIC"
                  onChange={(e) => setPicName(e.target.value)}
                  value={picName}
                  className="placeholder-gray-300 p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                />
              </div>
              {validation && !picName && (
                <div className="text-red-500 text-10px mt-1 text-right">
                  Wajib diisi
                </div>
              )}
            </div>

            <div>
              <label
                htmlFor="picEmail"
                className="block text-sm font-medium text-gray-700"
              >
                Email PIC
              </label>
              <div className="mt-3">
                <input
                  name="picEmail"
                  type="email"
                  placeholder="Email PIC"
                  onChange={(e) => setPicEmail(e.target.value)}
                  value={picEmail}
                  className="placeholder-gray-300 p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                />
              </div>
              {validation && !picEmail && (
                <div className="text-red-500 text-10px mt-1 text-right">
                  Wajib diisi
                </div>
              )}
            </div>
            <div>
              <label
                htmlFor="picWhatsapp"
                className="block text-sm font-medium text-gray-700"
              >
                No. WhatsApp PIC
              </label>
              <div className="mt-3">
                <input
                  name="picWhatsapp"
                  placeholder="No. Whatsapp PIC"
                  onChange={(e) => setPicWhatsapp(e.target.value)}
                  value={picWhatsapp}
                  className="placeholder-gray-300 p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="program"
                className="block text-sm font-medium text-gray-700"
              >
                Program Pilihan Anda
              </label>
              <select
                id="program"
                name="program"
                className="mt-1 block w-full p-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-custom-blue focus:border-custom-blue sm:text-sm"
                onChange={(e) => setProgram(e.target.value)}
                value={program}
              >
                <option className="text-gray-300" value="">
                  Pilih Program
                </option>
                {programs?.map((el, i) => (
                  <option key={i} value={el.id}>
                    {el.title}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label
                htmlFor="participantAmount"
                className="block text-sm font-medium text-gray-700"
              >
                Jumlah Peserta Pelatihan
              </label>
              <div className="mt-3">
                <input
                  name="participantAmount"
                  placeholder="Jumlah Peserta Pelatihan"
                  type="number"
                  onChange={(e) => setParticipantAmount(e.target.value)}
                  value={participantAmount}
                  className="placeholder-gray-300 p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                />
              </div>
              {validation && !participantAmount && (
                <div className="text-red-500 text-10px mt-1 text-right">
                  Wajib diisi
                </div>
              )}
            </div>

            <div>
              <label
                htmlFor="notes"
                className="block text-sm font-medium text-gray-700"
              >
                Notes
              </label>
              <div className="mt-3">
                <textarea
                  name="notes"
                  placeholder="Notes"
                  onChange={(e) => setNotes(e.target.value)}
                  value={notes}
                  rows={6}
                  className="placeholder-gray-300 p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="flex flex-col justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  checked={termsAndConditions}
                  onChange={() => setTermsAndConditions(!termsAndConditions)}
                  className="h-4 w-4 text-custom-blue focus:ring-custom-blue border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-10px text-gray-900"
                >
                  Dengan mengisi formulir ini saya menyetujui
                  <br />
                  <a
                    href="#"
                    rel="noopener noreferrer"
                    className="underline italic font-bold"
                  >
                    Syarat dan Ketentuan
                  </a>{" "}
                  untuk mengikuti program yang
                  <br />
                  saya daftarkan.
                </label>
              </div>
              {validation && !termsAndConditions && (
                <div className="text-red-500 text-10px mt-1">
                  Wajib diceklis
                </div>
              )}

              <div className="w-full mt-5">
                <button
                  type="submit"
                  className="disabled:bg-gray-100 disabled:text-custom-blue font-bold group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm rounded-md text-white bg-custom-blue hover:bg-custom-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-blue"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    {loading ? (
                      <LockOpenIcon
                        className={classnames(
                          loading ? "animate-ping" : "",
                          "h-5 w-5 text-custom-blue group-hover:text-custom-blue"
                        )}
                        aria-hidden="true"
                      />
                    ) : (
                      <LockClosedIcon
                        className="h-5 w-5 text-custom-blue group-hover:text-custom-blue"
                        aria-hidden="true"
                      />
                    )}
                  </span>
                  Kirim
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FirstComponentMobileForm;
