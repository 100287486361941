import {
  SET_STUDENT,
  SIGN_OUT_STUDENT,
  GET_STUDENT,
} from "./student.action.type";

export const signData = ({ token, full_name, image_profile }) => {
  return {
    type: SET_STUDENT,
    token,
    full_name,
    image_profile,
  };
};

export const signOut = () => ({
  type: SIGN_OUT_STUDENT,
});

export const getStudent = () => ({
  type: GET_STUDENT,
});
