import {
  LOAD_LOCATIONS_SUCCESS,
  LOAD_LOCATION_SUCCESS,
  LOAD_LOCATION_LOADING,
  LOAD_LOCATION_FAILED,
} from "./location.action.type";

const initialState = {
  locations: [],
  location: {},
  loading: false,
  error: {
    status: false,
    message: "Oops! Something Went Wrong.",
  },
};

function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case LOAD_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.payload,
        loading: false,
        error: {
          status: false,
        },
      };
    case LOAD_LOCATION_SUCCESS:
      return {
        ...state,
        location: action.payload,
        loading: false,
        error: {
          status: false,
        },
      };
    case LOAD_LOCATION_LOADING:
      return {
        ...state,
        loading: true,
        error: {
          status: false,
        },
      };
    case LOAD_LOCATION_FAILED:
      return {
        ...state,
        error: {
          status: true,
          message: state.error.message,
        },
        loading: false,
      };
    default:
      return state;
  }
}

export default reducer;
