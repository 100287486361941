import React from "react";
import { PlayIcon } from "@heroicons/react/solid";

import Button from "../../components/Button";

import image from "../../constants/image";

const FirstComponent = ({ waLink }) => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0.5)), url(${image.isaBg1})`,
      }}
      className="sm:flex h-full hidden bg-cover bg-no-repeat bg-color-gray-500"
    >
      <div className="flex flex-col md:flex-row items-center justify-center sm:justify-start mx-auto h-600px w-4/6 sm:w-4/5 2xl:w-3/5">
        <div className="flex flex-col gap-y-1 sm:gap-y-5">
          <h1 className="w-full sm:w-820px font-bold text-3xl sm:text-7xl text-white">
            Belajar Dulu,
            <br />
            Bayar Setelah Bekerja
          </h1>
          <p className="w-full sm:w-1/2 text-white text-sm">
            Ikut program Full Stack JavaScript Fase Immersive /<br />
            Data Science hanya dengan membayar fase persiapan.
          </p>
          <div className="w-220px">
            <a href={waLink} rel="noopener noreferrer" target="_blank">
              <Button title="Daftar Sekarang" className="rounded-md" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstComponent;
