import classNames from "classnames";
import React, { Fragment } from "react";
import { CheckIcon } from "@heroicons/react/solid";

import Button from "../../components/Button";

const SixthComponent = ({ waLink }) => {
  const contents = [
    [
      "Tidak memiliki aktivitas lain (seperti kuliah/sekolah/kerja)",
      true,
      true,
    ],
    [
      "Dapat sambil melakukan aktivitas lain seperti kuliah/kerja",
      false,
      false,
    ],
    [
      "Max. berumur 30 tahun untuk non IT, dengan minimum pendidikan SMA",
      true,
      true,
    ],
    [
      "Max. berumur 35 tahun dengan minimum pendidikan S1 IT / Berpengalaman di bidang IT (coding) & pernah menjadi Lead",
      true,
      true,
    ],
    ["Berdomisili di Indonesia dan warga negara Indonesia", true, true],
    ["Tidak memiliki rekam kejahatan finansial", true, true],
    ["Memiliki akun bank yang berlaku di Indonesia", true, true],
    ["Lulus dalam tes masuk program", true, true],
  ];

  return (
    <div
      style={{ backgroundColor: "#F8F8F8" }}
      className="flex flex-col items-center gap-y-20 py-30px sm:py-80px"
    >
      <div className="w-5/6 sm:w-3/5 2xl:w-3/5 flex flex-col sm:flex-row justify-center items-center sm:gap-x-16 gap-y-20">
        <div className="w-full">
          <div className="hidden shadow-lg w-full sm:grid grid-cols-2 sm:grid-cols-3 rounded-xl">
            {/* header */}
            <div className="bg-white border-gray-200 rounded-tl-xl border-r border-b p-5 flex items-center">
              <p className="text-gray-700 text-xl font-bold">Syarat</p>
            </div>
            <div className="bg-table-green border-gray-200 border-r border-b text-center p-5">
              <p className="text-gray-700 text-xl font-bold">
                Bootcamp Immersive
              </p>
              <p className="text-gray-700 ">4 Bulan</p>
            </div>
            <div className="bg-table-violet border-gray-200 rounded-tr-xl text-center p-5 border-b">
              <p className="text-gray-700 text-xl font-bold">
                Bootcamp Data Science
              </p>
              <p className="text-gray-700">3 Bulan</p>
            </div>
            {/* content */}
            {contents.map((content, i) => (
              <Fragment key={i}>
                <div
                  className={classNames(
                    i !== contents.length - 1
                      ? "border-b border-gray-200"
                      : "rounded-bl-xl",
                    "border-r border-gray-200 flex items-center px-5 py-3 bg-white"
                  )}
                >
                  <p className="text-gray-600 text-10px">{content[0]}</p>
                </div>
                <div
                  className={classNames(
                    i !== contents.length - 1 ? "border-b border-gray-200" : "",
                    "flex justify-center border-r border-gray-200 text-center px-5 py-3 bg-row-green"
                  )}
                >
                  {content[1] && (
                    <div className="flex items-center">
                      <div className="bg-check-green rounded-full p-1">
                        <CheckIcon className="text-custom-green" width={14} />
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={classNames(
                    i !== contents.length - 1
                      ? "border-b border-gray-200"
                      : "rounded-br-xl",
                    "flex justify-center text-center border-gray-200 px-5 py-3 bg-row-violet"
                  )}
                >
                  {content[2] && (
                    <div className="flex items-center">
                      <div className="bg-check-violet rounded-full p-1">
                        <CheckIcon className="text-custom-violet" width={14} />
                      </div>
                    </div>
                  )}
                </div>
              </Fragment>
            ))}
          </div>

          <div className="shadow-lg w-full sm:hidden grid grid-cols-1 rounded-xl">
            {/* header */}
            <div className="bg-table-green border-gray-200 border-r border-b text-center p-5 rounded-t-xl">
              <p className="text-gray-700 text-xl font-bold">
                Bootcamp Immersive
              </p>
              <p className="text-gray-700 ">4 Bulan</p>
            </div>
            {/* content */}
            {contents
              .filter((el) => el[1] === true)
              .map((content, i) => (
                <Fragment key={i}>
                  <div
                    className={classNames(
                      i !== contents.filter((el) => el[1] === true).length - 1
                        ? "border-b border-gray-200"
                        : "rounded-b-xl",
                      "border-r bg-row-green border-gray-200 flex items-center px-5 py-3 bg-white"
                    )}
                  >
                    <p className="text-gray-600 text-10px">{content[0]}</p>
                  </div>
                </Fragment>
              ))}
          </div>

          <div className="shadow-lg w-full sm:hidden grid grid-cols-1 rounded-xl mt-8">
            {/* header */}
            <div className="bg-table-violet border-gray-200 border-r border-b text-center p-5 rounded-t-xl">
              <p className="text-gray-700 text-xl font-bold">
                Bootcamp Data Science
              </p>
              <p className="text-gray-700 ">3 Bulan</p>
            </div>
            {/* content */}
            {contents
              .filter((el) => el[2] === true)
              .map((content, i) => (
                <Fragment key={i}>
                  <div
                    className={classNames(
                      i !== contents.filter((el) => el[1] === true).length - 1
                        ? "border-b border-gray-200"
                        : "rounded-b-xl",
                      "border-r bg-row-violet border-gray-200 flex items-center px-5 py-3 bg-white"
                    )}
                  >
                    <p className="text-gray-600 text-10px">{content[0]}</p>
                  </div>
                </Fragment>
              ))}
          </div>
        </div>
      </div>
      <div>
        <p className="text-custom-blue font-bold text-xl">
          Daftarkan diri kamu sekarang juga
        </p>
        <div className="w-220px my-5 mx-auto">
          <a href={waLink} rel="noopener noreferrer" target="_blank">
            <Button
              className="rounded-full font-bold"
              title="Daftar Sekarang"
              textSize="text-12px"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SixthComponent;
