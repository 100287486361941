import React from "react";
import moment from "moment";
import { NavLink } from "react-router-dom";

import { s3_url } from "../plugins/storage-public-url";

import ButtonSmall from "../components/ButtonSmall";

import image from "../constants/image";

const ProgramCard = ({ value }) => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(60, 60, 60, 0.4), rgba(60, 60, 60, 0.4)), url(${
            s3_url + value?.course?.thumbnail || image.defaultThumbnail
          })`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundColor: "grey",
          backgroundPosition: "center",
          height: 160,
        }}
        className="flex items-end rounded-t-lg shadow-md"
      >
        <div className="w-full px-2 py-1 text-center b-0 bg-custom-blue text-white font-bold">
          {`${value?.course?.title} [BATCH ${value?.batch}]`}
        </div>
      </div>
      <div className="shadow-md rounded-b-lg border h-180px relative">
        <div className="flex px-2 pt-2 pb-1 items-center justify-center gap-x-2">
          <p style={{ fontSize: 13 }} className="w-1/2 text-right">
            {value?.days}
          </p>
          <hr className="border-l h-16px border-custom-blue" />
          <p style={{ fontSize: 13 }} className="w-1/2 text-left">
            {`${value.start_time} - ${value.end_time}`}
          </p>
        </div>
        <div className="px-3 mb-2 text-center">
          <p style={{ fontSize: 12 }}>
            {`Starts ${moment(value?.start_date).format("DD MMMM YYYY")}, ${
              value?.participant_amount
            } Students`}
          </p>
        </div>
        <div className="px-3">
          <p style={{ fontSize: 12 }}>{value?.course?.short_description}</p>
        </div>
        <div style={{ bottom: 3 }} className="px-14 py-4 absolute w-full">
          <NavLink to={`/registration?id=${value?.course_id}`}>
            <ButtonSmall title="Get started" />
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ProgramCard;
