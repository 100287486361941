import React from "react";
import classnames from "classnames";

const Button = ({
  title,
  onClick,
  className = "",
  color = "text-white bg-custom-blue",
  textSize,
}) => {
  return (
    <button
      onClick={onClick}
      className={classnames(
        `${className} ${color} ${
          textSize ? textSize : "text-base"
        } flex w-full items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium`
      )}
    >
      {title}
    </button>
  );
};

export default Button;
