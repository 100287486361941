import {
  LOAD_CATEGORIES_SUCCESS,
  LOAD_CATEGORY_SUCCESS,
  LOAD_CATEGORY_LOADING,
  LOAD_CATEGORY_FAILED,
} from "./category.action.type";

import axios from "../../plugins/api";

export const getCategories = () => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .get(`/categories`)
      .then(({ data: { data } }) => {
        dispatch(getDataSuccess(data));
      })
      .catch(() => {
        dispatch(getDataFailed());
      });
  };
};

export const getOneCategory = (id) => {
  return (dispatch) => {
    dispatch(getDataLoading());
    axios
      .get(`/categories/${id}`)
      .then(({ data: { data } }) => {
        dispatch(getOneDataSuccess(data));
      })
      .catch(() => {
        dispatch(getDataFailed());
      });
  };
};

const getDataSuccess = (payload) => ({
  type: LOAD_CATEGORIES_SUCCESS,
  payload,
});

const getOneDataSuccess = (payload) => ({
  type: LOAD_CATEGORY_SUCCESS,
  payload,
});

const getDataLoading = () => ({
  type: LOAD_CATEGORY_LOADING,
});

const getDataFailed = () => ({
  type: LOAD_CATEGORY_FAILED,
});
