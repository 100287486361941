import React from "react";
import { useDispatch, useSelector } from "react-redux";

import axios from "../../../plugins/api";

import { getCourses } from "../../../store/courses/course.action";

import icon from "../../../constants/icon";

const icons = [
  {
    name: "1",
    icon: <img src={icon.profession1} height={32} />,
  },
  {
    name: "2",
    icon: <img src={icon.profession2} height={32} />,
  },
  {
    name: "3",
    icon: <img src={icon.profession3} height={32} />,
  },
  {
    name: "4",
    icon: <img src={icon.profession4} height={32} />,
  },
  {
    name: "5",
    icon: <img src={icon.profession5} height={32} />,
  },
];

const types = [
  {
    label: "Syllabus",
    value: "SYLLABUS",
  },
  {
    label: "Description",
    value: "DESCRIPTION",
  },
  {
    label: "Advantage",
    value: "ADVANTAGE",
  },
];

const CourseDetailForm = ({ setOpen, course, setCourse }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const [order, setOrder] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [type, setType] = React.useState(types[0].value);
  const [description, setDescription] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const onSave = (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      course_id: course.id,
      order: Number(order),
      title,
      type,
      description,
    };

    axios
      .post("/course-details/create", payload, {
        headers: {
          authorization: user.user_token,
        },
      })
      .then(() => {
        dispatch(getCourses("limit=10&page=1"));
        setOpen(false);
        setCourse(null);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <form>
          <div className="sm:rounded-md sm:overflow-hidden">
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div>
                <label
                  htmlFor="order"
                  className="block text-sm font-medium text-gray-700"
                >
                  Urutan
                </label>
                <div className="mt-1">
                  <input
                    name="order"
                    type="number"
                    onChange={(e) => setOrder(e.target.value)}
                    value={order}
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Title
                </label>
                <div className="mt-1">
                  <input
                    name="title"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-700"
                >
                  Type
                </label>
                <select
                  id="type"
                  name="type"
                  onChange={(e) => {
                    setType(e.target.value);
                    if (e.target.value === "ADVANTAGE") {
                      setDescription("1");
                    }
                  }}
                  value={type}
                  className="mt-1 block w-full p-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-custom-blue focus:border-custom-blue sm:text-sm"
                >
                  {types?.map((el, i) => (
                    <option key={i} value={el?.value}>
                      {el?.label}
                    </option>
                  ))}
                </select>
              </div>

              {type !== "ADVANTAGE" && (
                <div>
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {`Description (${125 - description.length})`}
                  </label>
                  <div className="mt-1">
                    <textarea
                      name="description"
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      rows={3}
                      className="p-3 shadow-sm focus:ring-custom-blue focus:border-custom-blue mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              )}
              {type === "ADVANTAGE" && (
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Icon
                  </label>
                  <div className="flex gap-x-2">
                    {icons?.map((el, i) => (
                      <div key={i} className="bg-custom-blue p-1 rounded-md">
                        {el?.icon}
                        <div className="text-center text-12px text-white">
                          {el?.name}
                        </div>
                      </div>
                    ))}
                  </div>
                  <select
                    id="description"
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    className="mt-1 block w-full p-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-custom-blue focus:border-custom-blue sm:text-sm"
                  >
                    {icons?.map((el, i) => (
                      <option key={i} value={el?.name}>
                        {el?.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <div className="px-4 py-3 text-right sm:px-6">
              <button
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-blue"
                onClick={onSave}
                disabled={loading}
              >
                {loading ? "Please wait..." : "Save"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CourseDetailForm;
