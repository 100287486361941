import React from "react";

const LeftButtonIcon = ({ icon, title }) => {
  return (
    <button className="flex w-full items-center justify-center gap-x-2 px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-custom-blue hover:bg-custom-blue">
      {icon}
      <span>{title}</span>
    </button>
  );
};

export default LeftButtonIcon;
