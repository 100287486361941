const icon = {
  bootcamp1: require("../assets/icons/bootcamp-1.svg").default,
  bootcamp2: require("../assets/icons/bootcamp-2.svg").default,
  bootcamp3: require("../assets/icons/bootcamp-3.svg").default,
  bootcamp4: require("../assets/icons/bootcamp-4.svg").default,
  profession1: require("../assets/icons/profession-1.svg").default,
  profession2: require("../assets/icons/profession-2.svg").default,
  profession3: require("../assets/icons/profession-3.svg").default,
  profession4: require("../assets/icons/profession-4.svg").default,
  profession5: require("../assets/icons/profession-5.svg").default,
  wave1: require("../assets/icons/wave-1.png").default,
  wave2: require("../assets/icons/wave-2.png").default,
  wave3: require("../assets/icons/wave-3.png").default,
  wave4: require("../assets/icons/wave-4.png").default,
  wave5: require("../assets/icons/wave-5.png").default,
  wave6: require("../assets/icons/wave-6.png").default,
  circleSquareShape: require("../assets/icons/circle-square-shape.png").default,
  searchNotFound: require("../assets/icons/search-not-found.png").default,
  arrowFromBottom: require("../assets/icons/arrow-from-bottom.svg").default,
  calculatorRound: require("../assets/icons/calculator-round.svg").default,
  infinity: require("../assets/icons/infinity.svg").default,
  building: require("../assets/icons/building.svg").default,
  check: require("../assets/icons/check.svg").default,
  creditCard: require("../assets/icons/credit-card.svg").default,
  stats: require("../assets/icons/stats.svg").default,
  key: require("../assets/icons/key.svg").default,
  listCheck: require("../assets/icons/list-check.svg").default,
  successPayment: require("../assets/icons/success-payment.svg").default,
};

export default icon;
